import { defaultIconProps, IconProps } from './IconProps';

export const IconProcessor: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M10.4999 18.1006C10.4999 14.518 10.4999 12.7267 11.6129 11.6138C12.7258 10.5008 14.5171 10.5008 18.0997 10.5008H21.8997C25.4823 10.5008 27.2736 10.5008 28.3865 11.6138C29.4995 12.7267 29.4995 14.518 29.4995 18.1006V21.9006C29.4995 25.4832 29.4995 27.2745 28.3865 28.3875C27.2736 29.5004 25.4823 29.5004 21.8997 29.5004H18.0997C14.5171 29.5004 12.7258 29.5004 11.6129 28.3875C10.4999 27.2745 10.4999 25.4832 10.4999 21.9006V18.1006Z"
      stroke={color}
      strokeWidth="2.27996"
    />
    <rect x="16.1998" y="16.2007" width="7.59985" height="7.59986" rx="1.13998" fill={color} />
    <path d="M16.1998 10.5008V4.8009" stroke={color} strokeWidth="2.27996" strokeLinecap="round" />
    <path d="M23.7992 10.5001V4.80023" stroke={color} strokeWidth="2.27996" strokeLinecap="round" />
    <path d="M29.5001 16.2007L35.2 16.2007" stroke={color} strokeWidth="2.27996" strokeLinecap="round" />
    <path d="M29.5001 23.8018L35.2 23.8018" stroke={color} strokeWidth="2.27996" strokeLinecap="round" />
    <path d="M16.1996 35.1983V29.4984" stroke={color} strokeWidth="2.27996" strokeLinecap="round" />
    <path d="M23.7992 35.1983V29.4984" stroke={color} strokeWidth="2.27996" strokeLinecap="round" />
    <path d="M4.79962 16.2007L10.4995 16.2007" stroke={color} strokeWidth="2.27996" strokeLinecap="round" />
    <path d="M4.79986 23.8018L10.4998 23.8018" stroke={color} strokeWidth="2.27996" strokeLinecap="round" />
  </svg>
);

IconProcessor.defaultProps = defaultIconProps;
