import { defaultIconProps, IconProps } from './IconProps';

export const IconCheckV3: import('react').FC<IconProps> = ({ color, ...rest }) => (
  <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5964 0.390159L4.43638 7.30016L2.53638 5.27016C2.18638 4.94016 1.63638 4.92016 1.23638 5.20016C0.846381 5.49016 0.736381 6.00016 0.976381 6.41016L3.22638 10.0702C3.44638 10.4102 3.82638 10.6202 4.25638 10.6202C4.66638 10.6202 5.05638 10.4102 5.27638 10.0702C5.63638 9.60016 12.5064 1.41016 12.5064 1.41016C13.4064 0.490159 12.3164 -0.319841 11.5964 0.38016V0.390159Z"
      fill={color}
    />
  </svg>
);

IconCheckV3.defaultProps = defaultIconProps;
