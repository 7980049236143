import React from 'react';
import { IconProps, defaultIconProps } from './IconProps';

export const IconBarChartOutline: React.FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M2.5 9.16667L2.5 17.5M12.5 9.16667L12.5 17.5M7.5 2.5L7.5 17.5M17.5 2.5V17.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconBarChartOutline.defaultProps = defaultIconProps;
