import { defaultIconProps, IconProps } from './IconProps';

export const IconSmartSearch: React.FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M12.33 0.393412C12.33 0.176137 12.1592 0 11.9485 0C11.7379 0 11.5671 0.176137 11.5671 0.393412V1.18024H10.8042C10.5936 1.18024 10.4228 1.35637 10.4228 1.57365C10.4228 1.79093 10.5936 1.96706 10.8042 1.96706H11.5671V2.75389C11.5671 2.97116 11.7379 3.1473 11.9485 3.1473C12.1592 3.1473 12.33 2.97116 12.33 2.75389V1.96706H13.0928C13.3035 1.96706 13.4743 1.79093 13.4743 1.57365C13.4743 1.35637 13.3035 1.18024 13.0928 1.18024H12.33V0.393412Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.89708 1.57365C9.04156 1.57365 9.17363 1.65784 9.23825 1.79112L9.7037 2.75126C9.8677 3.08958 9.92867 3.21156 10.0061 3.31523C10.0795 3.41339 10.1648 3.50139 10.26 3.57706C10.3605 3.65696 10.4788 3.71984 10.8068 3.889L11.7377 4.36907C11.8669 4.43571 11.9485 4.57193 11.9485 4.72095C11.9485 4.86996 11.8669 5.00619 11.7377 5.07283L10.8068 5.55289C10.4788 5.72205 10.3605 5.78493 10.26 5.86484C10.1648 5.9405 10.0795 6.02851 10.0061 6.12667C9.92867 6.23033 9.8677 6.35232 9.7037 6.69064L9.23825 7.65077C9.17363 7.78405 9.04156 7.86825 8.89708 7.86825C8.75261 7.86825 8.62053 7.78406 8.55592 7.65077L8.09047 6.69064C7.92646 6.35232 7.8655 6.23033 7.78802 6.12667C7.71466 6.02851 7.62934 5.9405 7.53417 5.86484C7.43366 5.78493 7.31539 5.72205 6.98737 5.5529L6.05647 5.07283C5.92725 5.00619 5.84562 4.86996 5.84562 4.72095C5.84562 4.57193 5.92725 4.43571 6.05647 4.36907L6.98737 3.889C7.31539 3.71984 7.43366 3.65696 7.53417 3.57706C7.62934 3.50139 7.71466 3.41339 7.78802 3.31523C7.8655 3.21156 7.92646 3.08958 8.09047 2.75126L8.55592 1.79112C8.62053 1.65784 8.75261 1.57365 8.89708 1.57365ZM8.89708 2.84676L8.75637 3.13703C8.61461 3.42958 8.52031 3.6242 8.39222 3.79559C8.27885 3.94729 8.14699 4.08329 7.9999 4.20023C7.83374 4.33234 7.64504 4.42961 7.3614 4.57582L7.07996 4.72095L7.3614 4.86608C7.64504 5.01229 7.83374 5.10956 7.9999 5.24166C8.14699 5.3586 8.27885 5.4946 8.39222 5.64631C8.52031 5.81769 8.61461 6.01231 8.75637 6.30485L8.89708 6.59514L9.03779 6.30487C9.17955 6.01231 9.27386 5.81769 9.40194 5.64631C9.51532 5.4946 9.64718 5.3586 9.79426 5.24166C9.96043 5.10956 10.1491 5.01229 10.4328 4.86608L10.7142 4.72095L10.4328 4.57582C10.1491 4.42961 9.96043 4.33234 9.79426 4.20023C9.64718 4.08329 9.51532 3.94729 9.40194 3.79559C9.27386 3.6242 9.17955 3.42958 9.03779 3.13703L8.89708 2.84676Z"
      fill={color}
    />
    <path
      d="M9.82287 11.828C10.6214 10.8364 11.1016 9.56223 11.1016 8.17242C11.1016 7.57267 11.0121 6.99446 10.8463 6.4512C10.821 6.46646 10.8086 6.4756 10.7974 6.48445C10.7499 6.52223 10.7073 6.56617 10.6707 6.61519C10.645 6.64956 10.6168 6.69629 10.4343 7.07279L9.95249 8.06672C9.91697 8.14001 9.87464 8.20835 9.82652 8.2711C9.77565 10.6617 7.88081 12.5835 5.55078 12.5835C3.18877 12.5835 1.27398 10.6086 1.27398 8.17242C1.27398 5.76921 3.13727 3.81486 5.45509 3.76238C5.51593 3.71276 5.58219 3.6691 5.65325 3.63245L6.61692 3.13548C6.98195 2.94724 7.02726 2.91821 7.06058 2.89172C7.10811 2.85394 7.15071 2.81 7.18734 2.76098C7.19593 2.7495 7.20478 2.73664 7.21958 2.71055C6.69286 2.53952 6.13226 2.4473 5.55078 2.4473C2.48517 2.4473 0 5.01052 0 8.17242C0 11.3343 2.48517 13.8975 5.55078 13.8975C6.80978 13.8975 7.97088 13.4652 8.90215 12.7367L11.8795 15.8076C12.1283 16.0641 12.5316 16.0641 12.7804 15.8076C13.0292 15.551 13.0292 15.135 12.7804 14.8784L9.82287 11.828Z"
      fill={color}
    />
    <path
      d="M14.4743 5.50777C14.6849 5.50777 14.8557 5.68391 14.8557 5.90118V6.68801H15.6186C15.8292 6.68801 16 6.86415 16 7.08142C16 7.2987 15.8292 7.47483 15.6186 7.47483H14.8557V8.26166C14.8557 8.47893 14.6849 8.65507 14.4743 8.65507C14.2636 8.65507 14.0928 8.47893 14.0928 8.26166V7.47483H13.33C13.1193 7.47483 12.9485 7.2987 12.9485 7.08142C12.9485 6.86415 13.1193 6.68801 13.33 6.68801H14.0928V5.90118C14.0928 5.68391 14.2636 5.50777 14.4743 5.50777Z"
      fill={color}
    />
  </svg>
);

IconSmartSearch.defaultProps = defaultIconProps;
