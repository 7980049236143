import { defaultIconProps, IconProps } from './IconProps';

export const IconThumbsUp: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M5.83329 18.3333V9.16663M1.66663 10.8333V16.6666C1.66663 17.5871 2.41282 18.3333 3.33329 18.3333H14.5218C15.7558 18.3333 16.8051 17.433 16.9928 16.2134L17.8902 10.3801C18.1232 8.86571 16.9515 7.49996 15.4193 7.49996H12.5C12.0397 7.49996 11.6666 7.12686 11.6666 6.66663V3.72149C11.6666 2.58662 10.7466 1.66663 9.61176 1.66663C9.34107 1.66663 9.09578 1.82604 8.98584 2.0734L6.05324 8.67174C5.91949 8.97268 5.62106 9.16663 5.29173 9.16663H3.33329C2.41282 9.16663 1.66663 9.91282 1.66663 10.8333Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconThumbsUp.defaultProps = defaultIconProps;
