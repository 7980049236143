import { defaultIconProps, IconProps } from './IconProps';

export const IconSlashSmall: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" {...rest}>
    <circle cx="10" cy="10" r="7.75" fill="none" stroke={color} strokeMiterlimit="10" strokeWidth="2" />
    <line x1="14.5" y1="5.5" x2="5.5" y2="14.5" fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
  </svg>
);

IconSlashSmall.defaultProps = defaultIconProps;
