import { defaultIconProps, IconProps } from './IconProps';

export interface IconStopFillProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  size?: number;
  color?: string;
}

export const IconStopFill: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <rect x="6" y="5" width="4" height="14" rx="1" fill={color} />
    <rect x="14" y="5" width="4" height="14" rx="1" fill={color} />
  </svg>
);

IconStopFill.defaultProps = defaultIconProps;
