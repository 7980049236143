import { defaultIconProps, IconProps } from './IconProps';

export const IconCloseSquare: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path fillRule="evenodd" clipRule="evenodd" d="M19.4142 6.00003L6.00003 19.4142L4.58582 18L18 4.58582L19.4142 6.00003Z" fill={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.58576 6.00003L18 19.4142L19.4142 18L5.99997 4.58582L4.58576 6.00003Z" fill={color} />
  </svg>
);

IconCloseSquare.defaultProps = defaultIconProps;
