import { defaultIconProps, IconProps } from './IconProps';

export const IconSignInCircle: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2442 3.17294C11.99 2.82567 13.7996 3.0039 15.4442 3.68509C17.0887 4.36628 18.4943 5.51983 19.4832 6.99987C20.4722 8.47992 21 10.22 21 12C21 13.78 20.4722 15.5201 19.4832 17.0001C18.4943 18.4802 17.0887 19.6337 15.4442 20.3149C13.7996 20.9961 11.99 21.1743 10.2442 20.8271C8.49836 20.4798 6.89471 19.6226 5.63604 18.364L4.22182 19.7782C5.7602 21.3166 7.72022 22.3642 9.85401 22.7886C11.9878 23.2131 14.1995 22.9952 16.2095 22.1627C18.2195 21.3301 19.9375 19.9202 21.1462 18.1113C22.3549 16.3023 23 14.1756 23 12C23 9.82441 22.3549 7.69767 21.1462 5.88873C19.9375 4.07979 18.2195 2.66989 16.2095 1.83733C14.1995 1.00477 11.9878 0.786929 9.85401 1.21137C7.72022 1.6358 5.7602 2.68345 4.22183 4.22183L5.63604 5.63604C6.89471 4.37737 8.49836 3.5202 10.2442 3.17294Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7809 6.37524L10.2191 7.62463L12.9194 10.9999H3C2.44771 10.9999 2 11.4477 2 11.9999C2 12.5522 2.44771 12.9999 3 12.9999H12.9194L10.2191 16.3752L11.7809 17.6246L16.2806 11.9999L11.7809 6.37524Z"
      fill={color}
    />
  </svg>
);

IconSignInCircle.defaultProps = defaultIconProps;
