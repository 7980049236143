import React from 'react';
import { Container, BlobWrapper, Blob } from './Spinner.styles';
import { SpinnerProps } from './types';

export const Spinner = ({ size = 1.5, className, blobWrapperClassName, blobClassName, ...props }: SpinnerProps): JSX.Element => (
  <Container className={className} data-testid="default-loading-ui" {...props}>
    <BlobWrapper className={blobWrapperClassName}>
      <Blob className={blobClassName} size={size} />
      <Blob className={blobClassName} size={size} />
    </BlobWrapper>
  </Container>
);
