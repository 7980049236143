import { defaultIconProps, IconProps } from './IconProps';

export const IconStarFill: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M10.3073 7.21986C10.9494 5.61918 11.2704 4.81883 11.792 4.70791C11.9291 4.67874 12.0709 4.67874 12.208 4.70791C12.7296 4.81883 13.0506 5.61918 13.6927 7.21986C14.0578 8.13014 14.2404 8.58528 14.582 8.89485C14.6778 8.98168 14.7819 9.05901 14.8926 9.12576C15.2874 9.36374 15.7803 9.40788 16.7661 9.49617C18.4349 9.64562 19.2693 9.72034 19.5241 10.1961C19.5768 10.2946 19.6127 10.4013 19.6302 10.5117C19.7147 11.0447 19.1013 11.6028 17.8745 12.7189L17.5338 13.0288C16.9603 13.5506 16.6735 13.8115 16.5076 14.1372C16.4081 14.3325 16.3414 14.5428 16.3102 14.7598C16.258 15.1215 16.342 15.5 16.51 16.2569L16.57 16.5274C16.8712 17.8849 17.0218 18.5637 16.8338 18.8973C16.6649 19.197 16.3539 19.3889 16.0103 19.4053C15.6277 19.4236 15.0888 18.9844 14.0108 18.106C13.3006 17.5273 12.9455 17.2379 12.5513 17.1249C12.191 17.0216 11.809 17.0216 11.4487 17.1249C11.0545 17.2379 10.6994 17.5273 9.98922 18.106C8.91124 18.9844 8.37225 19.4236 7.98973 19.4053C7.64613 19.3889 7.33509 19.197 7.16622 18.8973C6.97823 18.5637 7.12883 17.8849 7.43002 16.5274L7.49003 16.2569C7.65798 15.5 7.74196 15.1215 7.68985 14.7598C7.65859 14.5428 7.59187 14.3325 7.49237 14.1372C7.3265 13.8115 7.03972 13.5506 6.46618 13.0288L6.12551 12.7189C4.89872 11.6028 4.28532 11.0447 4.3698 10.5117C4.38729 10.4013 4.42317 10.2946 4.47594 10.1961C4.73074 9.72034 5.56512 9.64562 7.23388 9.49617C8.21967 9.40788 8.71256 9.36374 9.1074 9.12576C9.21815 9.05901 9.32216 8.98168 9.41798 8.89485C9.75959 8.58528 9.94216 8.13014 10.3073 7.21986Z"
      fill={color}
      stroke={color}
      strokeWidth="2"
    />
  </svg>
);

IconStarFill.defaultProps = defaultIconProps;
