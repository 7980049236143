import { defaultIconProps, IconProps } from './IconProps';

export const IconPlusFillRounded: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM13 13V17H11V13H7V11H11V7H13V11H17V13H13Z"
      fill={color}
    />
  </svg>
);

IconPlusFillRounded.defaultProps = defaultIconProps;
