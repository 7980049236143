import { defaultIconProps, IconProps } from './IconProps';

export const IconModelType: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fill={color}
      d="M12.3913 11.087C12.3913 11.807 11.807 12.3913 11.087 12.3913H3.91304C3.19304 12.3913 2.6087 11.807 2.6087 11.087V3.91304C2.6087 3.19304 3.19304 2.6087 3.91304 2.6087H11.087C11.807 2.6087 12.3913 3.19304 12.3913 3.91304V11.087ZM5.21739 13.0435V15H4.56522V13.0435H5.21739ZM7.82609 13.0435V15H7.17391V13.0435H7.82609ZM9.13043 13.0435V15H8.47826V13.0435H9.13043ZM6.52174 13.0435V15H5.86957V13.0435H6.52174ZM10.4348 13.0435V15H9.78261V13.0435H10.4348ZM5.21739 0V1.95652H4.56522V0H5.21739ZM7.82609 0V1.95652H7.17391V0H7.82609ZM9.13043 0V1.95652H8.47826V0H9.13043ZM6.52174 0V1.95652H5.86957V0H6.52174ZM10.4348 0V1.95652H9.78261V0H10.4348ZM13.0435 9.78261H15V10.4348H13.0435V9.78261ZM13.0435 7.17391H15V7.82609H13.0435V7.17391ZM13.0435 5.86957H15V6.52174H13.0435V5.86957ZM13.0435 8.47826H15V9.13043H13.0435V8.47826ZM13.0435 4.56522H15V5.21739H13.0435V4.56522ZM0 9.78261H1.95652V10.4348H0V9.78261ZM0 7.17391H1.95652V7.82609H0V7.17391ZM0 5.86957H1.95652V6.52174H0V5.86957ZM0 8.47826H1.95652V9.13043H0V8.47826ZM0 4.56522H1.95652V5.21739H0V4.56522Z"
    />
  </svg>
);

IconModelType.defaultProps = defaultIconProps;
