import { defaultIconProps, IconProps } from './IconProps';

export const IconTableOfContents: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M26.1667 15.3333H17.8333M26.1667 22H17.8333M26.1667 8.66667H17.8333M12 2L12 32M10 2H24C26.8003 2 28.2004 2 29.27 2.54497C30.2108 3.02433 30.9757 3.78924 31.455 4.73005C32 5.79961 32 7.19974 32 10V24C32 26.8003 32 28.2004 31.455 29.27C30.9757 30.2108 30.2108 30.9757 29.27 31.455C28.2004 32 26.8003 32 24 32H10C7.19974 32 5.79961 32 4.73005 31.455C3.78924 30.9757 3.02433 30.2108 2.54497 29.27C2 28.2004 2 26.8003 2 24V10C2 7.19974 2 5.79961 2.54497 4.73005C3.02433 3.78924 3.78924 3.02433 4.73005 2.54497C5.79961 2 7.19974 2 10 2Z"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconTableOfContents.defaultProps = defaultIconProps;
