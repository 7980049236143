import { defaultIconProps, IconProps } from './IconProps';

export const IconUniversalSmartSearch: React.FC<IconProps> = ({ size, color, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 28" fill="none" {...rest}>
    <path
      d="M17.5513 9.27567V5.96542C17.5513 4.22737 17.5513 3.35834 17.213 2.6945C16.9155 2.11056 16.4407 1.63581 15.8568 1.33828C15.193 1.00003 14.3239 1.00003 12.5859 1.00003H5.96538C4.22734 1.00003 3.35831 1.00003 2.69447 1.33828C2.11053 1.63581 1.63578 2.11056 1.33825 2.6945C1 3.35834 1 4.22737 1 5.96542V16.7237C1 18.4618 1 19.3308 1.33825 19.9947C1.63578 20.5786 2.11053 21.0534 2.69447 21.3509C3.35831 21.6891 4.22734 21.6891 5.96538 21.6891H9.27564M9.79287 10.3101H5.13782M6.17227 14.4479H5.13782M13.4135 6.17231H5.13782"
      stroke={color}
      strokeWidth="1.88083"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12.4823 23.9649H16.827" stroke={color} strokeWidth="1.88083" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.2065 26.8614H16.103" stroke={color} strokeWidth="1.88083" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M16.8923 21.0684C17.0226 20.3588 17.363 19.8085 17.9133 19.2582C18.263 18.9363 18.5405 18.544 18.7277 18.1072C18.9149 17.6704 19.0075 17.1989 18.9995 16.7237C18.9995 15.5715 18.5417 14.4664 17.7269 13.6516C16.9122 12.8368 15.8071 12.379 14.6548 12.379C13.5025 12.379 12.3974 12.8368 11.5826 13.6516C10.7678 14.4664 10.3101 15.5715 10.3101 16.7237C10.3101 17.4479 10.4766 18.3385 11.3962 19.2582C11.9205 19.7376 12.2782 20.3717 12.4172 21.0684"
      stroke={color}
      strokeWidth="1.88083"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconUniversalSmartSearch.defaultProps = defaultIconProps;
