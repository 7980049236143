import { defaultIconProps, IconProps } from './IconProps';

export const IconQuestionSmall: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" {...rest}>
    <circle cx="10" cy="15" r="1.25" fill={color} stroke="none" />
    <path
      d="M8,7.56H8a2,2,0,0,1,2-2h1a2,2,0,0,1,2,2v.17a2,2,0,0,1-.59,1.41L10,11.56V12"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
  </svg>
);

IconQuestionSmall.defaultProps = defaultIconProps;
