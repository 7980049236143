import { defaultIconProps, IconProps } from './IconProps';

export const IconKeyboard02: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M5.83341 12.0833H14.1667M5.00008 8.33333H5.00842M8.33342 8.33333H8.34175M11.6667 8.33333H11.6751M15.0001 8.33333H15.0084M4.33342 15H15.6667C16.6002 15 17.0669 15 17.4234 14.8183C17.737 14.6586 17.992 14.4036 18.1518 14.09C18.3334 13.7335 18.3334 13.2668 18.3334 12.3333V7.66667C18.3334 6.73325 18.3334 6.26654 18.1518 5.91002C17.992 5.59641 17.737 5.34144 17.4234 5.18166C17.0669 5 16.6002 5 15.6667 5H4.33341C3.39999 5 2.93328 5 2.57676 5.18166C2.26316 5.34144 2.00819 5.59641 1.8484 5.91002C1.66675 6.26654 1.66675 6.73325 1.66675 7.66667V12.3333C1.66675 13.2668 1.66675 13.7335 1.8484 14.09C2.00819 14.4036 2.26316 14.6586 2.57676 14.8183C2.93328 15 3.39999 15 4.33342 15Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconKeyboard02.defaultProps = defaultIconProps;
