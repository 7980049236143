import { defaultIconProps, IconProps } from './IconProps';

export const IconObjectMode = ({ size = defaultIconProps.size, color = defaultIconProps.color, ...rest }: IconProps) => (
  <svg {...rest} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 3H6.2C5.0799 3 4.51984 3 4.09202 3.21799C3.71569 3.40973 3.40973 3.71569 3.21799 4.09202C3 4.51984 3 5.0799 3 6.2V8M8 21H6.2C5.0799 21 4.51984 21 4.09202 20.782C3.71569 20.5903 3.40973 20.2843 3.21799 19.908C3 19.4802 3 18.9201 3 17.8V16M21 8V6.2C21 5.0799 21 4.51984 20.782 4.09202C20.5903 3.71569 20.2843 3.40973 19.908 3.21799C19.4802 3 18.9201 3 17.8 3H16M21 16V17.8C21 18.9201 21 19.4802 20.782 19.908C20.5903 20.2843 20.2843 20.5903 19.908 20.782C19.4802 21 18.9201 21 17.8 21H16"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g clipPath="url(#clip0_24_17488)">
      <path
        d="M16.5 14V9.99995C16.4998 9.82459 16.4535 9.65236 16.3658 9.50053C16.278 9.34871 16.1519 9.22263 16 9.13495L12.5 7.13495C12.348 7.04718 12.1755 7.00098 12 7.00098C11.8245 7.00098 11.652 7.04718 11.5 7.13495L8 9.13495C7.84813 9.22263 7.72199 9.34871 7.63423 9.50053C7.54647 9.65236 7.50018 9.82459 7.5 9.99995V14C7.50018 14.1753 7.54647 14.3475 7.63423 14.4994C7.72199 14.6512 7.84813 14.7773 8 14.865L11.5 16.865C11.652 16.9527 11.8245 16.9989 12 16.9989C12.1755 16.9989 12.348 16.9527 12.5 16.865L16 14.865C16.1519 14.7773 16.278 14.6512 16.3658 14.4994C16.4535 14.3475 16.4998 14.1753 16.5 14Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.63501 9.47998L12 12.005L16.365 9.47998" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 17.04V12" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_24_17488">
        <rect width="12" height="12" fill="white" transform="translate(6 6)" />
      </clipPath>
    </defs>
  </svg>
);
