import NextLink from 'next/link';
import type { LinkProps } from 'next/link';

export interface ExtendedLinkProps extends React.PropsWithChildren<LinkProps> {
  className?: string;
  'data-testid'?: string;
  onClick?: React.MouseEventHandler;
  target?: '_self' | '_blank' | '_parent' | '_top';
  rel?: 'nofollow' | 'noreferrer' | 'noopener' | string;
}
export const Link = ({
  children,
  className,
  target = '_self',
  onClick,
  rel,
  ...props
}: ExtendedLinkProps): // eslint-disable-next-line jsx-a11y/anchor-is-valid, react/jsx-props-no-spreading
import('react').ReactElement => (
  <NextLink {...props}>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
    <a data-testid={props['data-testid'] || ''} className={className} target={target} onClick={onClick} rel={rel}>
      {children}
    </a>
  </NextLink>
);

Link.defaultProps = {
  className: '',
};
