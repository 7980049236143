import type { LDSingleKindContext } from 'launchdarkly-node-server-sdk';

export function createLDContext(authData?: CF.LocalUserWithOrgUserAndPlan): LDSingleKindContext {
  const ctx = authData ? createUserContext(authData) : createAnonymousContext();
  return ctx;
}

export function createAnonymousContext(): LDSingleKindContext {
  return {
    kind: 'user',
    anonymous: true,
    key: `anonymous-${Date.now()}`,
  };
}

export function createUserContext(user: CF.LocalUserWithOrgUserAndPlan): LDSingleKindContext {
  const key = user.user_id;
  const name = `${user.first_name} ${user.last_name}`;
  const email = user.email;
  const subscription = user.plan?.id;

  const context = {
    kind: 'user',
    key,
    name,
    email,
    subscription,
    organizations: (user.organizations || []).map((org) => org.organization.id),
  };
  return context;
}
