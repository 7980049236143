import { defaultIconProps, IconProps } from './IconProps';

export const IconCustomModel: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M34.3334 35.6666C34.8001 35.6666 35.0334 35.6666 35.2117 35.5758C35.3685 35.4959 35.496 35.3684 35.5759 35.2116C35.6667 35.0333 35.6667 34.8 35.6667 34.3333V16.9999C35.6667 16.5332 35.6667 16.2999 35.5759 16.1216C35.496 15.9648 35.3685 15.8373 35.2117 15.7574C35.0334 15.6666 34.8001 15.6666 34.3334 15.6666L30.3334 15.6666C29.8667 15.6666 29.6333 15.6666 29.455 15.7574C29.2982 15.8373 29.1708 15.9648 29.0909 16.1216C29 16.2999 29 16.5332 29 16.9999V20.9999C29 21.4666 29 21.7 28.9092 21.8782C28.8293 22.035 28.7018 22.1625 28.545 22.2424C28.3668 22.3333 28.1334 22.3333 27.6667 22.3333H23.6667C23.2 22.3333 22.9666 22.3333 22.7884 22.4241C22.6316 22.504 22.5041 22.6315 22.4242 22.7883C22.3334 22.9665 22.3334 23.1999 22.3334 23.6666V27.6666C22.3334 28.1333 22.3334 28.3667 22.2425 28.5449C22.1627 28.7017 22.0352 28.8292 21.8784 28.9091C21.7001 28.9999 21.4668 28.9999 21 28.9999H17C16.5333 28.9999 16.3 28.9999 16.1217 29.0907C15.9649 29.1706 15.8374 29.2981 15.7575 29.4549C15.6667 29.6332 15.6667 29.8665 15.6667 30.3333V34.3333C15.6667 34.8 15.6667 35.0333 15.7575 35.2116C15.8374 35.3684 15.9649 35.4959 16.1217 35.5758C16.3 35.6666 16.5333 35.6666 17 35.6666L34.3334 35.6666Z"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.6667 10.3333C15.6667 9.86654 15.6667 9.63319 15.7575 9.45493C15.8374 9.29812 15.9649 9.17064 16.1217 9.09075C16.3 8.99992 16.5333 8.99992 17 8.99992H21C21.4668 8.99992 21.7001 8.99992 21.8784 9.09075C22.0352 9.17064 22.1627 9.29812 22.2425 9.45493C22.3334 9.63319 22.3334 9.86654 22.3334 10.3333V14.3333C22.3334 14.8 22.3334 15.0333 22.2425 15.2116C22.1627 15.3684 22.0352 15.4959 21.8784 15.5758C21.7001 15.6666 21.4668 15.6666 21 15.6666H17C16.5333 15.6666 16.3 15.6666 16.1217 15.5758C15.9649 15.4959 15.8374 15.3684 15.7575 15.2116C15.6667 15.0333 15.6667 14.8 15.6667 14.3333V10.3333Z"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.00004 20.3333C4.00004 19.8665 4.00004 19.6332 4.09087 19.4549C4.17076 19.2981 4.29825 19.1706 4.45505 19.0907C4.63331 18.9999 4.86666 18.9999 5.33337 18.9999H9.33337C9.80008 18.9999 10.0334 18.9999 10.2117 19.0907C10.3685 19.1706 10.496 19.2981 10.5759 19.4549C10.6667 19.6332 10.6667 19.8665 10.6667 20.3333V24.3333C10.6667 24.8 10.6667 25.0333 10.5759 25.2116C10.496 25.3684 10.3685 25.4959 10.2117 25.5758C10.0334 25.6666 9.80008 25.6666 9.33337 25.6666H5.33337C4.86666 25.6666 4.63331 25.6666 4.45505 25.5758C4.29825 25.4959 4.17076 25.3684 4.09087 25.2116C4.00004 25.0333 4.00004 24.8 4.00004 24.3333V20.3333Z"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.33337 3.66659C2.33337 3.19987 2.33337 2.96652 2.4242 2.78826C2.5041 2.63146 2.63158 2.50397 2.78838 2.42408C2.96664 2.33325 3.2 2.33325 3.66671 2.33325H7.66671C8.13342 2.33325 8.36677 2.33325 8.54503 2.42408C8.70183 2.50397 8.82932 2.63146 8.90921 2.78826C9.00004 2.96652 9.00004 3.19987 9.00004 3.66659V7.66659C9.00004 8.1333 9.00004 8.36665 8.90921 8.54491C8.82932 8.70171 8.70183 8.8292 8.54503 8.90909C8.36677 8.99992 8.13342 8.99992 7.66671 8.99992H3.66671C3.2 8.99992 2.96664 8.99992 2.78838 8.90909C2.63158 8.8292 2.5041 8.70171 2.4242 8.54491C2.33337 8.36665 2.33337 8.1333 2.33337 7.66659V3.66659Z"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconCustomModel.defaultProps = defaultIconProps;
