import { cx } from '@linaria/core';
import { flex } from 'styles/utils';
import { Link } from 'components/Link';
import { Button } from 'components/Button';
import { bigTitle, regularText } from 'styles/typography';
import { container, pageTitle, subtitle, btn, img } from 'modules/PageNotFound/PageNotFound.styles';
import { useState } from 'react';

const descriptionThreshold = 200;

type TextExpansion = {
  text: 'show more' | 'show less';
  show: boolean;
  wordLimit: number;
};

export const PageNotFound: React.FC<{
  title?: string;
  description?: string;
  showCTA?: boolean;
}> = ({ title = 'Oops! Page Not Found.', description = "...even AI can't find what you are looking for", showCTA = true }) => {
  const [textExpansionState, setTextExpansionState] = useState<TextExpansion>({
    text: 'show more',
    show: false,
    wordLimit: descriptionThreshold,
  });
  const isDescriptionLong = description?.length > descriptionThreshold;
  const dots = isDescriptionLong && !textExpansionState.show ? '...' : ' ';

  const textExpansionHandler = () => {
    setTextExpansionState({
      text: textExpansionState.show ? 'show more' : 'show less',
      show: !textExpansionState.show,
      wordLimit: textExpansionState.show ? 200 : description.length,
    });
  };
  return (
    <div className={cx(flex.init, flex.direction.column, flex.justifyContent.center, flex.alignItems.center, container)}>
      <h1 className={cx(bigTitle, pageTitle)}>{title}</h1>
      <h2 className={cx(regularText, subtitle)}>
        {`${description?.slice(0, textExpansionState.wordLimit)}${dots}`}
        {isDescriptionLong && (
          <button type="button" className="link" onClick={() => textExpansionHandler()}>
            {textExpansionState.text}
          </button>
        )}
      </h2>
      {showCTA && (
        <Link href="/explore" className={btn}>
          <Button size="md" variant="primary">
            Back to Community
          </Button>
        </Link>
      )}
      <img src="/character-robot.svg" alt="" height={266} width={294} className={img} />
    </div>
  );
};
