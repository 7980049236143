import { defaultIconProps, IconProps } from './IconProps';

export const IconDeletePlain: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M2 4H3.33333H14" stroke={color} strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M5.33301 4.00001V2.66668C5.33301 2.31305 5.47348 1.97392 5.72353 1.72387C5.97358 1.47382 6.31272 1.33334 6.66634 1.33334H9.33301C9.68663 1.33334 10.0258 1.47382 10.2758 1.72387C10.5259 1.97392 10.6663 2.31305 10.6663 2.66668V4.00001M12.6663 4.00001V13.3333C12.6663 13.687 12.5259 14.0261 12.2758 14.2762C12.0258 14.5262 11.6866 14.6667 11.333 14.6667H4.66634C4.31272 14.6667 3.97358 14.5262 3.72353 14.2762C3.47348 14.0261 3.33301 13.687 3.33301 13.3333V4.00001H12.6663Z"
      stroke="white"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconDeletePlain.defaultProps = defaultIconProps;
