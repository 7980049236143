import React from 'react';
import { defaultIconProps, IconProps } from './IconProps';

export const IconLightning: React.FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M8.83354 1.66666L1.41142 10.5732C1.12075 10.922 0.975413 11.0964 0.973192 11.2437C0.971261 11.3718 1.02832 11.4936 1.12792 11.5741C1.2425 11.6667 1.46952 11.6667 1.92357 11.6667H8.00021L7.16688 18.3333L14.589 9.42679C14.8797 9.07798 15.025 8.90357 15.0272 8.75628C15.0292 8.62823 14.9721 8.50641 14.8725 8.42592C14.7579 8.33333 14.5309 8.33333 14.0768 8.33333H8.00021L8.83354 1.66666Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconLightning.defaultProps = defaultIconProps;
