import { defaultIconProps, IconProps } from './IconProps';

export const IconNoTemplate: React.FC<IconProps> = ({ size, ...rest }) => (
  <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <circle cx="84" cy="84" r="80" fill="#F7FAFE" />
    <g filter="url(#filter0_d_526_12170)">
      <circle cx="84" cy="84" r="60" fill="#F2F6FE" />
    </g>
    <g filter="url(#filter1_d_526_12170)">
      <circle cx="84" cy="84" r="40" fill="#E6EDFD" />
    </g>
    <ellipse cx="83.5059" cy="125" rx="14.5" ry="4" fill="#CCDCFB" />
    <g filter="url(#filter2_ddi_526_12170)">
      <path
        d="M60.8895 61.5126C57.5436 61.9747 55.2058 65.0618 55.6679 68.4078L60.519 103.532C60.9811 106.878 64.0682 109.216 67.4141 108.754L102.539 103.903C105.885 103.441 108.222 100.354 107.76 97.0077L102.909 61.8832C102.447 58.5372 99.36 56.1994 96.0141 56.6615L60.8895 61.5126Z"
        fill="url(#paint0_radial_526_12170)"
        stroke="#6695F3"
        strokeWidth="1.32168"
      />
    </g>
    <g filter="url(#filter3_d_526_12170)">
      <path
        d="M71.1277 78.0969C70.8081 76.0212 70.6484 74.9833 70.9303 74.1283C71.1783 73.3762 71.658 72.7219 72.3007 72.2592C73.0314 71.7332 74.0693 71.5735 76.145 71.2539L84.0519 70.0369C86.1276 69.7173 87.1655 69.5576 88.0205 69.8395C88.7726 70.0875 89.4269 70.5672 89.8896 71.2099C90.4156 71.9406 90.5753 72.9785 90.8948 75.0542L92.8726 87.9029C93.1921 89.9786 93.3519 91.0165 93.0699 91.8715C92.822 92.6236 92.3422 93.2779 91.6995 93.7406C90.9689 94.2666 89.931 94.4263 87.8553 94.7458L79.9484 95.9629C77.8726 96.2824 76.8348 96.4422 75.9797 96.1603C75.2277 95.9123 74.5734 95.4326 74.1107 94.7899C73.5847 94.0592 73.4249 93.0213 73.1054 90.9456L71.1277 78.0969Z"
        fill="white"
      />
      <path
        d="M84.2808 81.384L76.8681 82.5251M80.0997 87.0865L77.6288 87.4668M85.991 76.0619L76.1074 77.5833"
        stroke="#0046D4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter id="filter0_d_526_12170" x="10" y="10" width="148" height="148" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="7" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.6 0 0 0 0 0.721569 0 0 0 0 0.968627 0 0 0 0.39 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_526_12170" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_526_12170" result="shape" />
      </filter>
      <filter id="filter1_d_526_12170" x="0" y="0" width="168" height="168" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="22" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0980392 0 0 0 0 0.352941 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_526_12170" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_526_12170" result="shape" />
      </filter>
      <filter
        id="filter2_ddi_526_12170"
        x="35.8565"
        y="39.5772"
        width="91.7148"
        height="91.7162"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2.72753" />
        <feGaussianBlur stdDeviation="9.54637" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.188235 0 0 0 0 0.415686 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_526_12170" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2.64336" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.243137 0 0 0 0 0.737255 0 0 0 1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_526_12170" result="effect2_dropShadow_526_12170" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_526_12170" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="3.92539" dy="5.53784" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect3_innerShadow_526_12170" />
      </filter>
      <filter
        id="filter3_d_526_12170"
        x="59.2455"
        y="60.2455"
        width="45.509"
        height="45.509"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="2.82353" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.6 0 0 0 0 0.721569 0 0 0 0 0.968627 0 0 0 0.9 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_526_12170" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_526_12170" result="shape" />
      </filter>
      <radialGradient
        id="paint0_radial_526_12170"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(71.0613 68.7684) rotate(43.3623) scale(51.7062 66.9022)"
      >
        <stop offset="0.221581" stopColor="#306AFF" />
        <stop offset="0.729167" stopColor="#003EBC" />
      </radialGradient>
    </defs>
  </svg>
);

IconNoTemplate.defaultProps = defaultIconProps;
