import { AnalyticsInstance, AnalyticsPlugin } from 'analytics';
import ReactGA from 'react-ga4';

// Custom plugin for ga4. Waiting for https://github.com/DavidWells/analytics/pull/125

type PluginProps = {
  instance: AnalyticsInstance;
  config: PluginConfig;
};

type PluginConfig = {
  trackingId: string;
};

type TrackPayload = {
  anonymousId: string;
  event: string;
  userId?: string;
  properties: Record<string, string>;
};

const defaultConfig = {
  enabled: true,
};

export const ga4Plugin = (pluginConfig: PluginConfig): AnalyticsPlugin => ({
  name: 'ga4-analytics',
  config: {
    ...defaultConfig,
    ...pluginConfig,
  },

  initialize: (plugin: PluginProps) => {
    const { config } = plugin;
    const { trackingId } = config;

    ReactGA.initialize(trackingId);
  },

  loaded: () => {
    return true;
  },

  page: () => {
    ReactGA.send('pageview');
  },

  identify: ({ payload }: { payload: { userId: string } }) => {
    ReactGA.set({ userId: payload.userId });
  },

  track: ({ payload }: { payload: TrackPayload }) => {
    const { properties, event } = payload;
    ReactGA.event(event, properties);
  },
});
