import { defaultIconProps, IconProps } from './IconProps';

export const IconLoadCircleFill: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.08642 13.0691L5.384 11.7072C5.13517 12.4254 5 13.1967 5 13.9996C5 17.8656 8.13401 20.9996 12 20.9996C15.866 20.9996 19 17.8656 19 13.9996C19 13.1967 18.8648 12.4254 18.616 11.7072L16.9136 13.0691C16.9703 13.3706 17 13.6816 17 13.9996C17 16.761 14.7614 18.9996 12 18.9996C9.23858 18.9996 7 16.761 7 13.9996C7 13.6816 7.02968 13.3706 7.08642 13.0691Z"
      fill={color}
    />
    <path
      d="M12 13L11.3753 13.7809L12 14.2806L12.6247 13.7809L12 13ZM13 4C13 3.44772 12.5523 3 12 3C11.4477 3 11 3.44772 11 4L13 4ZM6.37531 9.78087L11.3753 13.7809L12.6247 12.2191L7.6247 8.21913L6.37531 9.78087ZM12.6247 13.7809L17.6247 9.78087L16.3753 8.21913L11.3753 12.2191L12.6247 13.7809ZM13 13L13 4L11 4L11 13L13 13Z"
      fill={color}
    />
  </svg>
);

IconLoadCircleFill.defaultProps = defaultIconProps;
