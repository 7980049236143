import { defaultIconProps, IconProps } from './IconProps';

export const IconFoldedMap: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M10.9998 23L1.6665 28.3333V6.99996L10.9998 1.66663M10.9998 23L20.3332 28.3333M10.9998 23V1.66663M20.3332 28.3333L28.3332 23V1.66663L20.3332 6.99996M20.3332 28.3333V6.99996M20.3332 6.99996L10.9998 1.66663"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconFoldedMap.defaultProps = defaultIconProps;
