import { defaultIconProps, IconProps } from './IconProps';

export const IconRightStopLight: import('react').FC<IconProps> = ({ size = 10, color = '#808080', ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M13 12L13.3536 11.6464L13.7071 12L13.3536 12.3536L13 12ZM7.35355 5.64645L13.3536 11.6464L12.6464 12.3536L6.64645 6.35355L7.35355 5.64645ZM13.3536 12.3536L7.35355 18.3536L6.64645 17.6464L12.6464 11.6464L13.3536 12.3536Z"
      fill={color}
    />
    <path d="M18 7V17" stroke={color} />
  </svg>
);

IconRightStopLight.defaultProps = defaultIconProps;
