import { defaultIconProps, IconProps } from './IconProps';

export const IconPrint: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 512 512" fill={color} xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M112,512c-8.83,0-16-7.17-16-16V384H48c-26.48,0-48-21.52-48-48V176c0-26.48,21.52-48,48-48h48V48c0-26.48,21.52-48,48-48
		h224c26.48,0,48,21.52,48,48v80h48c26.48,0,48,21.52,48,48v160c0,26.48-21.52,48-48,48h-48v112c0,8.83-7.17,16-16,16H112z M384,480
		V288H128v192H384z M464,352c8.83,0,16-7.17,16-16V176c0-8.83-7.17-16-16-16H48c-8.83,0-16,7.17-16,16v160c0,8.83,7.17,16,16,16h48
		v-80c0-8.83,7.17-16,16-16h288c8.83,0,16,7.17,16,16v80H464z M384,128V48c0-8.83-7.17-16-16-16H144c-8.83,0-16,7.17-16,16v80H384z"
    />
    <path d="M80,224c-8.83,0-16-7.17-16-16s7.17-16,16-16h32c8.83,0,16,7.17,16,16s-7.17,16-16,16H80z" />
    <path d="M176,352c-8.83,0-16-7.17-16-16s7.17-16,16-16h160c8.83,0,16,7.17,16,16s-7.17,16-16,16H176z" />
    <path d="M176,416c-8.83,0-16-7.17-16-16s7.17-16,16-16h112c8.83,0,16,7.17,16,16s-7.17,16-16,16H176z" />
  </svg>
);

IconPrint.defaultProps = defaultIconProps;
