import { defaultIconProps, IconProps } from './IconProps';

export const IconArrowRight: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7071 5.29297L13.2929 6.70718L17.5858 11.0001H5C4.44772 11.0001 4 11.4478 4 12.0001C4 12.5524 4.44772 13.0001 5 13.0001H17.5858L13.2929 17.293L14.7071 18.7072L21.4142 12.0001L14.7071 5.29297Z"
      fill={color}
    />
  </svg>
);

IconArrowRight.defaultProps = defaultIconProps;
