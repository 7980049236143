import { defaultIconProps, IconProps } from './IconProps';

export const IconEdit: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M11.3334 1.99998C11.5085 1.82488 11.7163 1.68599 11.9451 1.59123C12.1739 1.49647 12.4191 1.44769 12.6667 1.44769C12.9143 1.44769 13.1595 1.49647 13.3883 1.59123C13.6171 1.68599 13.8249 1.82488 14 1.99998C14.1751 2.17507 14.314 2.38294 14.4088 2.61172C14.5036 2.84049 14.5523 3.08569 14.5523 3.33331C14.5523 3.58093 14.5036 3.82613 14.4088 4.05491C14.314 4.28368 14.1751 4.49155 14 4.66664L5.00004 13.6666L1.33337 14.6666L2.33337 11L11.3334 1.99998Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconEdit.defaultProps = defaultIconProps;
