import { defaultIconProps, IconProps } from './IconProps';

export const IconZoomIn: React.FC<IconProps> = ({ size, color, ...rest }) => (
  <>
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <path d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z" />
      <path d="M21 20.9999L16.65 16.6499" />
      <path d="M11 8V14" />
      <path d="M8 11H14" />
    </svg>
  </>
);

IconZoomIn.defaultProps = defaultIconProps;
