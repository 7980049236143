import { defaultIconProps, IconProps } from './IconProps';

interface BaseIconProps extends IconProps {
  upArrowColor?: string;
  downArrowColor?: string;
}

export const IconColumnSortDesc: React.FC<IconProps> = ({ size, color, ...rest }) => (
  <svg
    data-testid="desc-sort-icon"
    width={size}
    height={size ? size + 2 : size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M12 20L11.2929 20.7071L12 21.4142L12.7071 20.7071L12 20ZM13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5L13 5ZM5.29289 14.7071L11.2929 20.7071L12.7071 19.2929L6.70711 13.2929L5.29289 14.7071ZM12.7071 20.7071L18.7071 14.7071L17.2929 13.2929L11.2929 19.2929L12.7071 20.7071ZM13 20L13 5L11 5L11 20L13 20Z"
      fill={color}
    />
  </svg>
);

export const IconColumnSortAsc: React.FC<IconProps> = ({ size, color, ...rest }) => (
  <svg data-testid="asc-sort-icon" width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M12 4L11.2929 3.29289L12 2.58579L12.7071 3.29289L12 4ZM13 19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19L13 19ZM5.29289 9.29289L11.2929 3.29289L12.7071 4.70711L6.70711 10.7071L5.29289 9.29289ZM12.7071 3.29289L18.7071 9.29289L17.2929 10.7071L11.2929 4.70711L12.7071 3.29289ZM13 4L13 19L11 19L11 4L13 4Z"
      fill={color}
    />
  </svg>
);

const BaseIcon: React.FC<BaseIconProps> = ({ size, upArrowColor, downArrowColor, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M10.8333 14.167L14.1667 17.5003L17.5 14.167" stroke={downArrowColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.167 17.5L14.167 7.5" stroke={downArrowColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.50033 5.83333L5.83366 2.5L9.16699 5.83333" stroke={upArrowColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.83301 12.5L5.83301 2.5" stroke={upArrowColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const IconColumnSortAscV1: React.FC<IconProps> = ({ color, ...restProps }) => (
  <BaseIcon upArrowColor={color} downArrowColor="currentColor" {...restProps} />
);

export const IconColumnSortDescV1: React.FC<IconProps> = ({ color, ...restProps }) => (
  <BaseIcon upArrowColor="currentColor" downArrowColor={color} {...{ color, ...restProps }} />
);

export const IconColumnUnsortedV1: React.FC<IconProps> = (props) => <BaseIcon upArrowColor="currentColor" downArrowColor="currentColor" {...props} />;

IconColumnSortAsc.defaultProps = defaultIconProps;
IconColumnSortDesc.defaultProps = defaultIconProps;
IconColumnSortAscV1.defaultProps = defaultIconProps;
IconColumnSortDescV1.defaultProps = defaultIconProps;
IconColumnUnsortedV1.defaultProps = defaultIconProps;
