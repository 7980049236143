import { defaultIconProps, IconProps } from './IconProps';

export const IconCamera: import('react').FC<IconProps> = ({ size, color, strokeWidth, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M14.5 4.00006H9.5L7 7.00006H4C3.46957 7.00006 2.96086 7.21077 2.58579 7.58585C2.21071 7.96092 2 8.46963 2 9.00006V18.0001C2 18.5305 2.21071 19.0392 2.58579 19.4143C2.96086 19.7893 3.46957 20.0001 4 20.0001H20C20.5304 20.0001 21.0391 19.7893 21.4142 19.4143C21.7893 19.0392 22 18.5305 22 18.0001V9.00006C22 8.46963 21.7893 7.96092 21.4142 7.58585C21.0391 7.21077 20.5304 7.00006 20 7.00006H17L14.5 4.00006Z"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 15.9999C13.6569 15.9999 15 14.6568 15 12.9999C15 11.3431 13.6569 9.99994 12 9.99994C10.3431 9.99994 9 11.3431 9 12.9999C9 14.6568 10.3431 15.9999 12 15.9999Z"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconCamera.defaultProps = defaultIconProps;
