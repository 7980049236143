import { useEffect, useRef } from 'react';
import { Portal } from 'reakit/Portal';
import { cx } from '@linaria/core';
import { TipPopoverProps, TipOrientation, TipPopoverContextState } from './types';
import { container, StyledPopover } from './TipPopover.styles';

export const TipPopover = ({
  id,
  show,
  className,
  tipClassName,
  content,
  position = { x: 0, y: 0 },
  orientation = TipOrientation.TopLeft,
  delayToClose,
  hideTip,
}: TipPopoverProps & Pick<TipPopoverContextState, 'hideTip'>) => {
  const timerId = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (delayToClose) {
      if (timerId.current && !show) {
        clearInterval(timerId.current);
      } else if (show) {
        timerId.current = setTimeout(() => {
          hideTip(id);
        }, delayToClose);
      }
    }
  }, [show, delayToClose, id]);

  return (
    <Portal>
      <div className={cx(container, className, !show && 'hidden')} style={{ top: position.y, left: position.x }}>
        <StyledPopover className={tipClassName} orientation={orientation}>
          {content}
        </StyledPopover>
      </div>
    </Portal>
  );
};
