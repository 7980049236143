import { defaultIconProps, IconProps } from './IconProps';

export const IconDatasets: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.26184 6.5347C6.03173 6.76787 6 6.92395 6 7C6 7.07605 6.03173 7.23213 6.26184 7.4653C6.4962 7.70277 6.8849 7.96249 7.44417 8.20218C8.55915 8.68002 10.1692 9 12 9C13.8308 9 15.4409 8.68002 16.5558 8.20218C17.1151 7.96249 17.5038 7.70277 17.7382 7.4653C17.9683 7.23213 18 7.07605 18 7C18 6.92395 17.9683 6.76787 17.7382 6.5347C17.5038 6.29723 17.1151 6.03751 16.5558 5.79782C15.4409 5.31998 13.8308 5 12 5C10.1692 5 8.55915 5.31998 7.44417 5.79782C6.8849 6.03751 6.4962 6.29723 6.26184 6.5347ZM6.65633 3.95953C8.07486 3.3516 9.96482 3 12 3C14.0352 3 15.9251 3.3516 17.3437 3.95953C18.0512 4.26274 18.6876 4.64947 19.1617 5.12983C19.64 5.61449 20 6.24762 20 7C20 7.75238 19.64 8.38551 19.1617 8.87017C18.6876 9.35053 18.0512 9.73726 17.3437 10.0405C15.9251 10.6484 14.0352 11 12 11C9.96482 11 8.07486 10.6484 6.65633 10.0405C5.94885 9.73726 5.31243 9.35053 4.83835 8.87017C4.36003 8.38551 4 7.75238 4 7C4 6.24762 4.36003 5.61449 4.83835 5.12983C5.31243 4.64947 5.94885 4.26274 6.65633 3.95953Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 12V13H5H4V12H6ZM19 13L20 13V17C20 17.7524 19.64 18.3855 19.1617 18.8702C18.6876 19.3505 18.0512 19.7373 17.3437 20.0405C15.9251 20.6484 14.0352 21 12 21C9.96482 21 8.07486 20.6484 6.65633 20.0405C5.94885 19.7373 5.31243 19.3505 4.83835 18.8702C4.36003 18.3855 4 17.7524 4 17V13H5H6V17C6 17.076 6.03173 17.2321 6.26184 17.4653C6.4962 17.7028 6.8849 17.9625 7.44417 18.2022C8.55915 18.68 10.1692 19 12 19C13.8308 19 15.4409 18.68 16.5558 18.2022C17.1151 17.9625 17.5038 17.7028 17.7382 17.4653C17.9683 17.2321 18 17.076 18 17V13.0001L19 13ZM19 13L20 13V12H18V13.0001L19 13Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 12C6 12.076 6.03173 12.2321 6.26184 12.4653C6.4962 12.7027 6.8849 12.9625 7.44417 13.2021C8.55915 13.68 10.1692 14 12 14C13.8308 14 15.4409 13.68 16.5558 13.2021C17.1151 12.9625 17.5038 12.7027 17.7382 12.4653C17.9683 12.2321 18 12.076 18 12V7.00001H20V12C20 12.7523 19.64 13.3855 19.1617 13.8701C18.6876 14.3505 18.0512 14.7372 17.3437 15.0404C15.9251 15.6484 14.0352 16 12 16C9.96482 16 8.07486 15.6484 6.65633 15.0404C5.94885 14.7372 5.31243 14.3505 4.83835 13.8701C4.36003 13.3855 4 12.7523 4 12V7.00057L6 7V12Z"
      fill={color}
    />
  </svg>
);

IconDatasets.defaultProps = defaultIconProps;
