import { defaultIconProps, IconProps } from './IconProps';

interface IconArrowRightLongProps extends IconProps {
  strokeWidth?: number;
}

export const IconArrowRightLong: import('react').FC<IconArrowRightLongProps> = ({ size = 20, color, strokeWidth, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M15 6L19 10L15 14" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1 10L19 10" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

IconArrowRightLong.defaultProps = {
  ...defaultIconProps,
  strokeWidth: 1.5,
};
