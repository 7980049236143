import { defaultIconProps, IconProps } from './IconProps';

export const IconAsterisk: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M3.11222 6.54545L3.20668 4.44744L1.43679 5.58594L0.894886 4.64134L2.77415 3.68182L0.894886 2.7223L1.43679 1.7777L3.20668 2.91619L3.11222 0.818182H4.19105L4.09659 2.91619L5.86648 1.7777L6.40838 2.7223L4.52912 3.68182L6.40838 4.64134L5.86648 5.58594L4.09659 4.44744L4.19105 6.54545H3.11222Z"
      fill={color}
    />
  </svg>
);

IconAsterisk.defaultProps = defaultIconProps;
