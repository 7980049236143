import { defaultIconProps, IconProps } from './IconProps';

export const IconSignOutCircle: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5C13.2288 5 14.4359 5.32344 15.5 5.93782C16.5641 6.5522 17.4478 7.43587 18.0622 8.5C18.6766 9.56413 19 10.7712 19 12C19 13.2288 18.6766 14.4359 18.0622 15.5C17.4478 16.5641 16.5641 17.4478 15.5 18.0622C14.4359 18.6766 13.2288 19 12 19C10.7712 19 9.56413 18.6766 8.5 18.0622L7.5 19.7942C8.86817 20.5841 10.4202 21 12 21C13.5798 21 15.1318 20.5841 16.5 19.7942C17.8682 19.0043 19.0043 17.8682 19.7942 16.5C20.5841 15.1318 21 13.5798 21 12C21 10.4202 20.5841 8.86817 19.7942 7.5C19.0043 6.13183 17.8682 4.99569 16.5 4.20577C15.1318 3.41586 13.5798 3 12 3C10.4202 3 8.86817 3.41586 7.5 4.20577L8.5 5.93782C9.56413 5.32344 10.7712 5 12 5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.21913 6.37524L6.78087 7.62463L4.08063 10.9999H11C11.5523 10.9999 12 11.4477 12 11.9999C12 12.5522 11.5523 12.9999 11 12.9999H4.08063L6.78087 16.3752L5.21913 17.6246L0.719376 11.9999L5.21913 6.37524Z"
      fill={color}
    />
  </svg>
);

IconSignOutCircle.defaultProps = defaultIconProps;
