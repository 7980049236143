import { cookieClientStorage } from 'utils/cookieStorage';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export const HUBSPOT_FORM_IDS = {
  signUpForm: '45aa29dd-e4e0-474a-b474-162409ae7962',
};

export const HUBSPOT_CUSTOM_EVENTS_NAME = {
  signupFormSubmitted: 'pe4505120_signup_form_submitted',
  lastVisit: 'pe4505120_last_visited',
  firstVisit: 'pe4505120_first_visited',
  signupCompletedDate: 'pe4505120_signup_completed_date',
  firstAppCreated: 'pe4505120_first_app_created',
};

export const submitFormApi = ({ formId, fields }: { formId: string; fields: { name: string; value: string }[] }): void => {
  if (!publicRuntimeConfig.HUBSPOT_PORTAL_ID) {
    return;
  }

  const portalId = publicRuntimeConfig.HUBSPOT_PORTAL_ID;
  const formUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;
  const hutk = cookieClientStorage.get('hubspotutk');
  const pageName = document.title;
  const pageUri = document.location.href;

  const context = {
    ...(hutk ? { hutk } : {}),
    pageUri,
    pageName,
  };
  const body = JSON.stringify({
    fields,
    context,
  });

  fetch(formUrl, {
    method: 'POST',
    body,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const sendEvent = ({ email, eventName, properties }: { email?: string; eventName: string; properties: Record<string, string> }): void => {
  const url = '/api/hubspot/send';
  const body = {
    email,
    eventName,
    properties,
  };

  fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
