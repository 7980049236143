import { defaultIconProps, IconProps } from './IconProps';

export const IconCpuChip: import('react').FC<IconProps> = ({ size, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g clipPath="url(#clip0_3270_31543)">
      <path
        d="M7.50521 1.66675V4.16675M12.5052 1.66675V4.16675M7.50521 15.8334V18.3334M12.5052 15.8334V18.3334M15.8385 7.50008H18.3385M15.8385 11.6667H18.3385M1.67188 7.50008H4.17188M1.67188 11.6667H4.17188M8.17188 15.8334H11.8385C13.2387 15.8334 13.9387 15.8334 14.4735 15.5609C14.9439 15.3212 15.3264 14.9388 15.5661 14.4684C15.8385 13.9336 15.8385 13.2335 15.8385 11.8334V8.16675C15.8385 6.76662 15.8385 6.06655 15.5661 5.53177C15.3264 5.06137 14.9439 4.67892 14.4735 4.43923C13.9387 4.16675 13.2387 4.16675 11.8385 4.16675H8.17188C6.77174 4.16675 6.07168 4.16675 5.5369 4.43923C5.06649 4.67892 4.68404 5.06137 4.44436 5.53177C4.17188 6.06655 4.17188 6.76662 4.17188 8.16675V11.8334C4.17188 13.2335 4.17188 13.9336 4.44436 14.4684C4.68404 14.9388 5.06649 15.3212 5.5369 15.5609C6.07168 15.8334 6.77174 15.8334 8.17188 15.8334Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3270_31543">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

IconCpuChip.defaultProps = defaultIconProps;
