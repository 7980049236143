import { defaultIconProps, IconProps } from './IconProps';

export const IconStar06: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g clipPath="url(#clip0_10989_29403)">
      <path
        d="M3.75033 18.3334V14.1667M3.75033 5.83341V1.66675M1.66699 3.75008H5.83366M1.66699 16.2501H5.83366M10.8337 2.50008L9.38851 6.25747C9.1535 6.86849 9.036 7.174 8.85327 7.43099C8.69132 7.65875 8.49232 7.85774 8.26456 8.01969C8.00758 8.20242 7.70207 8.31992 7.09104 8.55493L3.33366 10.0001L7.09104 11.4452C7.70207 11.6802 8.00758 11.7977 8.26456 11.9805C8.49232 12.1424 8.69132 12.3414 8.85327 12.5692C9.036 12.8262 9.1535 13.1317 9.38851 13.7427L10.8337 17.5001L12.2788 13.7427C12.5138 13.1317 12.6313 12.8262 12.8141 12.5692C12.976 12.3414 13.175 12.1424 13.4028 11.9805C13.6597 11.7977 13.9652 11.6802 14.5763 11.4452L18.3337 10.0001L14.5763 8.55493C13.9652 8.31992 13.6597 8.20242 13.4028 8.01969C13.175 7.85774 12.976 7.65875 12.8141 7.43099C12.6313 7.174 12.5138 6.86849 12.2788 6.25747L10.8337 2.50008Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

IconStar06.defaultProps = defaultIconProps;
