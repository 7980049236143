import { defaultIconProps, IconProps } from './IconProps';

export const IconOverview: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.93417 3C8.95604 3 8.97799 3 9 3L15.0658 3C15.9523 2.99995 16.7161 2.99991 17.3278 3.08215C17.9833 3.17028 18.6117 3.36902 19.1213 3.87868C19.631 4.38835 19.8297 5.0167 19.9179 5.67221C20.0001 6.28387 20.0001 7.04769 20 7.93417V17.0658C20.0001 17.9523 20.0001 18.7161 19.9179 19.3278C19.8297 19.9833 19.631 20.6117 19.1213 21.1213C18.6117 21.631 17.9833 21.8297 17.3278 21.9179C16.7161 22.0001 15.9523 22.0001 15.0658 22H8.9342C8.0477 22.0001 7.28388 22.0001 6.67221 21.9179C6.0167 21.8297 5.38835 21.631 4.87868 21.1213C4.36902 20.6117 4.17028 19.9833 4.08215 19.3278C3.99991 18.7161 3.99995 17.9523 4 17.0658L4 8C4 7.97799 4 7.95604 4 7.93417C3.99995 7.04769 3.99991 6.28387 4.08215 5.67221C4.17028 5.0167 4.36902 4.38835 4.87868 3.87868C5.38835 3.36902 6.0167 3.17028 6.67221 3.08215C7.28387 2.99991 8.04769 2.99995 8.93417 3ZM6.93871 5.06431C6.50497 5.12263 6.36902 5.21677 6.2929 5.2929C6.21677 5.36902 6.12263 5.50497 6.06431 5.93871C6.00213 6.40121 6 7.02893 6 8V17C6 17.9711 6.00213 18.5988 6.06431 19.0613C6.12263 19.495 6.21677 19.631 6.2929 19.7071C6.36902 19.7832 6.50497 19.8774 6.93871 19.9357C7.40121 19.9979 8.02893 20 9 20H15C15.9711 20 16.5988 19.9979 17.0613 19.9357C17.495 19.8774 17.631 19.7832 17.7071 19.7071C17.7832 19.631 17.8774 19.495 17.9357 19.0613C17.9979 18.5988 18 17.9711 18 17V8C18 7.02893 17.9979 6.40122 17.9357 5.93871C17.8774 5.50497 17.7832 5.36902 17.7071 5.2929C17.631 5.21677 17.495 5.12263 17.0613 5.06431C16.5988 5.00213 15.9711 5 15 5H9C8.02893 5 7.40122 5.00213 6.93871 5.06431Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 9C8 8.44772 8.44772 8 9 8H15C15.5523 8 16 8.44772 16 9C16 9.55228 15.5523 10 15 10H9C8.44772 10 8 9.55228 8 9Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 13C8 12.4477 8.44772 12 9 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H9C8.44772 14 8 13.5523 8 13Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 17C8 16.4477 8.44772 16 9 16H13C13.5523 16 14 16.4477 14 17C14 17.5523 13.5523 18 13 18H9C8.44772 18 8 17.5523 8 17Z"
      fill={color}
    />
  </svg>
);

IconOverview.defaultProps = defaultIconProps;
