import { defaultIconProps, IconProps } from './IconProps';

export const IconPlanet: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M4.12668 11.4713L11.2734 16.576C11.6453 16.8416 11.8312 16.9744 12.0258 17.005C12.1971 17.032 12.3724 17.0048 12.5275 16.9274C12.7038 16.8394 12.8409 16.6566 13.1151 16.291L14.6252 14.2774C14.7028 14.1741 14.7415 14.1224 14.7871 14.0778C14.8276 14.0382 14.872 14.0028 14.9196 13.9722C14.9732 13.9378 15.0323 13.9115 15.1504 13.859L21.598 10.9934C21.8653 10.8746 21.999 10.8152 22.0997 10.7225C22.1889 10.6404 22.259 10.5399 22.3053 10.428C22.3577 10.3014 22.3674 10.1555 22.3869 9.86368L22.859 2.78226M21.5 21.4999L25.8601 23.3685C26.3659 23.5853 26.6189 23.6937 26.7528 23.8738C26.8704 24.0317 26.9282 24.2262 26.916 24.4227C26.9022 24.6467 26.7495 24.8757 26.4442 25.3336L24.3959 28.4062C24.2511 28.6233 24.1788 28.7318 24.0832 28.8104C23.9986 28.88 23.901 28.9322 23.7962 28.964C23.6778 29 23.5474 29 23.2865 29H19.9611C19.6155 29 19.4427 29 19.2957 28.9421C19.1658 28.8911 19.0509 28.8082 18.9614 28.7012C18.8601 28.58 18.8054 28.4161 18.6961 28.0883L17.508 24.5239C17.4436 24.3305 17.4113 24.2338 17.4029 24.1356C17.3953 24.0484 17.4016 23.9607 17.4213 23.8755C17.4436 23.7794 17.4892 23.6883 17.5804 23.5059L18.4829 21.7009C18.6663 21.3342 18.7579 21.1508 18.8992 21.0369C19.0236 20.9366 19.1743 20.8742 19.3332 20.8571C19.5136 20.8378 19.7081 20.9026 20.0971 21.0323L21.5 21.4999ZM35.6667 18.9999C35.6667 28.2047 28.2048 35.6666 19 35.6666C9.7953 35.6666 2.33337 28.2047 2.33337 18.9999C2.33337 9.79517 9.7953 2.33325 19 2.33325C28.2048 2.33325 35.6667 9.79517 35.6667 18.9999Z"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconPlanet.defaultProps = defaultIconProps;
