import { defaultIconProps, IconProps } from './IconProps';

export const IconTransfer: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 17.9426V16H13V18H10V19.5885C10 20.5899 8.84597 21.1508 8.05861 20.5321L8.67644 19.7458L8.69613 19.7207M8 17.9426L3.61859 14.5L8 11.0575V13H15V11H10V9.41152C10 8.41021 8.84597 7.84931 8.05861 8.46794L0.38141 14.5L8.05861 20.5321L8.67644 19.7458"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 6.05743V7.99997H11V5.99997H14V4.41146C14 3.41014 15.154 2.84924 15.9414 3.46788L15.3236 4.2542L15.3039 4.27927M16 6.05743L20.3814 9.49997L16 12.9425V11H9V13H14V14.5885C14 15.5898 15.154 16.1507 15.9414 15.5321L23.6186 9.49997L15.9414 3.46788L15.3236 4.2542"
      fill={color}
    />
  </svg>
);

IconTransfer.defaultProps = defaultIconProps;
