import { isString } from 'utils/fp';
import { Subject } from 'rxjs';
import { getActiveSpan } from 'utils/server/tracing';
import { Attributes, Span } from '@opentelemetry/api';
import responseCodes from '../../server/responseCodes';
import { CLARIFAI_REQ_ID_PREFIX_HEADER_NAME, DEFAULT_PUBLIC_ERROR } from '../../server/constants';

const { E_UNKNOWN } = responseCodes;

export const createApiErrorSubject = (): Subject<{ status?: CF.API.Status }> => new Subject<{ status?: CF.API.Status }>();

type Headers = {
  'Content-Type': string;
  Authorization?: string;
  'X-Clarifai-Session-Token'?: string;
  'X-Clarifai-REST-API-Key'?: string;
  'X-Clarifai-Application-Id'?: string;
  [CLARIFAI_REQ_ID_PREFIX_HEADER_NAME]?: string;
};

declare global {
  interface Window {
    __CF__API__ERROR__SUBJECT?: Subject<{ status?: CF.API.Status } & unknown>;
  }
}

export type ClarifaiFetchParams = {
  method: 'POST' | 'PATCH' | 'PUT' | 'DELETE' | 'GET';
  apiHost?: string;
  path: string;
  sessionToken?: string;
  apiKey?: string;
  body?: Record<string, unknown>;
  signal?: AbortSignal;
  useRequestPrefixHeader?: boolean;
  withCredentials?: boolean;
};

export async function clarifaiFetchWithAuth(params: ClarifaiFetchParams): Promise<Response> {
  const { method, apiHost, path, sessionToken, apiKey, body, signal = null, useRequestPrefixHeader = true } = params;
  const headers: Headers = {
    'Content-Type': 'application/json',
  };

  if (useRequestPrefixHeader) {
    headers[CLARIFAI_REQ_ID_PREFIX_HEADER_NAME] = 'webportal';
  }

  if (apiKey) {
    headers.Authorization = `Key ${apiKey}`;
  } else if (sessionToken && apiHost !== 'https://api.hubapi.com') {
    headers['X-Clarifai-Session-Token'] = sessionToken;
  }

  const config: RequestInit = {
    credentials: 'include',
    method: method.toUpperCase(),
    headers,
    body: JSON.stringify(body),
    signal,
  };

  const url = `${isString(apiHost) ? apiHost : ''}${path}`;

  const response = await fetch(url, config);

  return response;
}

// A simple wrapper around `fetch` that passes in headers specific to the Clarifai API.
// (Also, removes the sometimes tedious step of checking the `ok` property of fetch
// response objects).
export async function clarifaiFetch<T>(params: ClarifaiFetchParams): Promise<T> {
  const response = await clarifaiFetchWithAuth(params);

  let json: { status?: CF.API.Status } & unknown;
  try {
    json = await response.json();
  } catch (err) {
    json = err;
    if (!json.status) {
      const error = err as Error;
      json.status = {
        code: E_UNKNOWN.code,
        description: (error.message || DEFAULT_PUBLIC_ERROR) + (error.stack ? `\n${error.stack}` : ''),
      };
    }
  }

  const span = getActiveSpan();
  recordFetchEventInSpan(span, params.path, json);

  if (typeof json.status === 'object' && !Array.isArray(json.status) && json.status !== null) {
    json.status.httpStatus = response.status;
  }

  if (!response.ok) {
    // TODO: pass the subject instantiated in _app.tsx Context to API client; and use it here to replace global var
    if (typeof window !== 'undefined' && window.__CF__API__ERROR__SUBJECT && window.__CF__API__ERROR__SUBJECT instanceof Subject) {
      window.__CF__API__ERROR__SUBJECT.next(json);
    }

    /// TODO(alexandros): We should not be throwing an object here, but wrapping in an Error, instead.
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw json;
  }

  return json as T;
}

function recordFetchEventInSpan(span: Span | undefined, url: string, response: { status?: CF.API.Status }) {
  if (!span) {
    return;
  }

  const eventName = `Fetch::${url}`;
  const attribs = {} as Attributes;
  if (response.status?.code) {
    attribs.statusCode = response.status.code;
  }
  if (response.status?.description) {
    attribs.description = response.status.description;
  }
  if (response.status?.req_id) {
    attribs.requestId = response.status.req_id;
  }

  span.addEvent(eventName, attribs);
}
