import { defaultIconProps, IconProps } from './IconProps';

export const IconBuilding: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M6.2513 9.16667H3.83464C3.36792 9.16667 3.13457 9.16667 2.95631 9.25749C2.79951 9.33739 2.67202 9.46487 2.59213 9.62167C2.5013 9.79993 2.5013 10.0333 2.5013 10.5V17.5M13.7513 9.16667H16.168C16.6347 9.16667 16.868 9.16667 17.0463 9.25749C17.2031 9.33739 17.3306 9.46487 17.4105 9.62167C17.5013 9.79993 17.5013 10.0333 17.5013 10.5V17.5M13.7513 17.5V5.16667C13.7513 4.23325 13.7513 3.76654 13.5696 3.41002C13.4099 3.09641 13.1549 2.84144 12.8413 2.68166C12.4848 2.5 12.0181 2.5 11.0846 2.5H8.91797C7.98455 2.5 7.51784 2.5 7.16132 2.68166C6.84771 2.84144 6.59275 3.09641 6.43296 3.41002C6.2513 3.76654 6.2513 4.23325 6.2513 5.16667V17.5M18.3346 17.5H1.66797M9.16797 5.83333H10.8346M9.16797 9.16667H10.8346M9.16797 12.5H10.8346"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconBuilding.defaultProps = defaultIconProps;
