import { defaultIconProps, IconProps } from './IconProps';

export const IconDuplicate: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.94561 4.00001C5.96372 4.00001 5.98186 4.00001 6.00001 4.00001H18C18.0182 4.00001 18.0363 4.00001 18.0544 4.00001C18.4785 3.99992 18.8906 3.99984 19.2305 4.04554C19.6137 4.09706 20.051 4.22259 20.4142 4.5858C20.7774 4.94902 20.903 5.38629 20.9545 5.76949C21.0002 6.10941 21.0001 6.52155 21 6.94562C21 6.96373 21 6.98187 21 7.00001V8.00001C21 8.01816 21 8.03629 21 8.0544C21.0001 8.47848 21.0002 8.89061 20.9545 9.23053C20.903 9.61374 20.7774 10.051 20.4142 10.4142C20.051 10.7774 19.6137 10.903 19.2305 10.9545C18.8906 11.0002 18.4785 11.0001 18.0544 11C18.0363 11 18.0182 11 18 11H6.00001C5.98186 11 5.96372 11 5.9456 11C5.52153 11.0001 5.10941 11.0002 4.76949 10.9545C4.38629 10.903 3.94902 10.7774 3.5858 10.4142C3.22259 10.051 3.09706 9.61374 3.04554 9.23053C2.99984 8.89062 2.99992 8.47849 3.00001 8.05442C3.00001 8.03631 3.00001 8.01817 3.00001 8.00001V7.00001C3.00001 6.98186 3.00001 6.96372 3.00001 6.94561C2.99992 6.52154 2.99984 6.10941 3.04554 5.76949C3.09706 5.38629 3.22259 4.94902 3.5858 4.5858C3.94902 4.22259 4.38629 4.09706 4.76949 4.04554C5.10941 3.99984 5.52154 3.99992 5.94561 4.00001ZM5.02871 6.02871C5.02838 6.03108 5.02804 6.03351 5.02771 6.03599C5.00214 6.22618 5.00001 6.50034 5.00001 7.00001V8.00001C5.00001 8.49969 5.00214 8.77385 5.02771 8.96404C5.02804 8.96652 5.02837 8.96894 5.02871 8.97132C5.03108 8.97165 5.03351 8.97199 5.03599 8.97232C5.22618 8.99789 5.50034 9.00001 6.00001 9.00001H18C18.4997 9.00001 18.7738 8.99789 18.964 8.97232C18.9665 8.97199 18.9689 8.97165 18.9713 8.97132C18.9717 8.96894 18.972 8.96652 18.9723 8.96404C18.9979 8.77385 19 8.49969 19 8.00001V7.00001C19 6.50034 18.9979 6.22618 18.9723 6.03599C18.972 6.03351 18.9717 6.03108 18.9713 6.02871C18.9689 6.02837 18.9665 6.02804 18.964 6.02771C18.7738 6.00214 18.4997 6.00001 18 6.00001H6.00001C5.50034 6.00001 5.22618 6.00214 5.03599 6.02771C5.03351 6.02804 5.03108 6.02837 5.02871 6.02871Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.94561 13C5.96372 13 5.98186 13 6.00001 13H18C18.0182 13 18.0363 13 18.0544 13C18.4785 12.9999 18.8906 12.9998 19.2305 13.0455C19.6137 13.0971 20.051 13.2226 20.4142 13.5858C20.7774 13.949 20.903 14.3863 20.9545 14.7695C21.0002 15.1094 21.0001 15.5215 21 15.9456C21 15.9637 21 15.9819 21 16V17C21 17.0182 21 17.0363 21 17.0544C21.0001 17.4785 21.0002 17.8906 20.9545 18.2305C20.903 18.6137 20.7774 19.051 20.4142 19.4142C20.051 19.7774 19.6137 19.903 19.2305 19.9545C18.8906 20.0002 18.4785 20.0001 18.0544 20C18.0363 20 18.0182 20 18 20H6.00001C5.98186 20 5.96372 20 5.9456 20C5.52153 20.0001 5.10941 20.0002 4.76949 19.9545C4.38629 19.903 3.94902 19.7774 3.5858 19.4142C3.22259 19.051 3.09706 18.6137 3.04554 18.2305C2.99984 17.8906 2.99992 17.4785 3.00001 17.0544C3.00001 17.0363 3.00001 17.0182 3.00001 17V16C3.00001 15.9819 3.00001 15.9637 3.00001 15.9456C2.99992 15.5215 2.99984 15.1094 3.04554 14.7695C3.09706 14.3863 3.22259 13.949 3.5858 13.5858C3.94902 13.2226 4.38629 13.0971 4.76949 13.0455C5.10941 12.9998 5.52154 12.9999 5.94561 13ZM5.02871 15.0287C5.02838 15.0311 5.02804 15.0335 5.02771 15.036C5.00214 15.2262 5.00001 15.5003 5.00001 16V17C5.00001 17.4997 5.00214 17.7738 5.02771 17.964C5.02804 17.9665 5.02837 17.9689 5.02871 17.9713C5.03108 17.9717 5.03351 17.972 5.03599 17.9723C5.22618 17.9979 5.50034 18 6.00001 18H18C18.4997 18 18.7738 17.9979 18.964 17.9723C18.9665 17.972 18.9689 17.9717 18.9713 17.9713C18.9717 17.9689 18.972 17.9665 18.9723 17.964C18.9979 17.7738 19 17.4997 19 17V16C19 15.5003 18.9979 15.2262 18.9723 15.036C18.972 15.0335 18.9717 15.0311 18.9713 15.0287C18.9689 15.0284 18.9665 15.028 18.964 15.0277C18.7738 15.0021 18.4997 15 18 15H6.00001C5.50034 15 5.22618 15.0021 5.03599 15.0277C5.03351 15.028 5.03108 15.0284 5.02871 15.0287Z"
      fill={color}
    />
  </svg>
);

IconDuplicate.defaultProps = defaultIconProps;
