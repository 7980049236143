/* eslint-disable unicorn/consistent-destructuring */
import { css } from '@linaria/core';
import { theming as T, baseTheme as bT, flex as fL } from './core';
import { responsiveTools, breakpointConfig as c } from './core/responsiveTools';
const baseUnit = '2.8rem';
const halfBaseUnit = `calc( ${baseUnit} / 2 )`;
const quarterBaseUnit = `calc( ${baseUnit} / 4 )`;
const doubleBaseUnit = `calc( ${baseUnit} * 2 )`;
const basePadding = halfBaseUnit;
export const baseUnits = {
  unit: baseUnit,
  halfUnit: halfBaseUnit,
  quarterUnit: quarterBaseUnit,
  doubleUnit: doubleBaseUnit,
  padding: basePadding
};

// peculiar export method due to weird linaria bug
export const theming = T;
export const baseTheme = bT;
export const flex = fL;
export const {
  breakpoints,
  responsive,
  fluidStyle
} = responsiveTools;
export const breakpointConfig = c;
export const borderRounded = "tyles__utils__index_ts-borderRounded-bktxx56";
export const Layers = {
  overlays: 900
};

require("./index.linaria.module.css!=!../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./index.ts");