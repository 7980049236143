interface IconFilterTwoProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  size?: number;
  color?: string;
}

export const IconFilterTwo: import('react').FC<IconFilterTwoProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M18.3337 2.5H1.66699L8.33366 10.3833V15.8333L11.667 17.5V10.3833L18.3337 2.5Z"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconFilterTwo.defaultProps = {
  size: 20,
  color: '#667085',
};
