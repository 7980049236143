import { defaultIconProps, IconProps } from './IconProps';

export const IconPlus: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path fillRule="evenodd" clipRule="evenodd" d="M13 5L13 19L11 19L11 5L13 5Z" fill={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M19 13L5 13L5 11L19 11L19 13Z" fill={color} />
  </svg>
);

IconPlus.defaultProps = defaultIconProps;
