import { defaultIconProps, IconProps } from './IconProps';

export const IconCPU: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g clipPath="url(#clip0_120_489)">
      <path
        d="M15 3.33333H5.00004C4.07957 3.33333 3.33337 4.07952 3.33337 5V15C3.33337 15.9205 4.07957 16.6667 5.00004 16.6667H15C15.9205 16.6667 16.6667 15.9205 16.6667 15V5C16.6667 4.07952 15.9205 3.33333 15 3.33333Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12.5 7.5H7.5V12.5H12.5V7.5Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.5 0.833332V3.33333" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.5 0.833332V3.33333" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.5 16.6667V19.1667" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.5 16.6667V19.1667" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.6666 7.5H19.1666" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.6666 11.6667H19.1666" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M0.833374 7.5H3.33337" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M0.833374 11.6667H3.33337" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_120_489">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

IconCPU.defaultProps = defaultIconProps;
