import { defaultIconProps, IconProps } from './IconProps';

export const IconArrowUpDown: import('react').FC<IconProps> = ({ size, color, strokeWidth, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M5.8335 12.5L10.0002 16.6666L14.1668 12.5M5.8335 7.49998L10.0002 3.33331L14.1668 7.49998"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconArrowUpDown.defaultProps = defaultIconProps;
