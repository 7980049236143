import { defaultIconProps, IconProps } from './IconProps';

export const IconEvaluations: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.57711 6.03127C8.95281 5.35286 10.4661 5 12 5C13.5339 5 15.0472 5.35286 16.4229 6.03127C17.7986 6.70969 18.9998 7.69548 19.9335 8.91239C20.8673 10.1293 21.5086 11.5447 21.8079 13.0491C22.1071 14.5535 22.0563 16.1066 21.6593 17.5882C21.5163 18.1217 20.968 18.4382 20.4345 18.2953C19.901 18.1524 19.5845 17.604 19.7274 17.0706C20.045 15.8853 20.0857 14.6428 19.8463 13.4393C19.6069 12.2358 19.0938 11.1034 18.3468 10.1299C17.5998 9.15638 16.6389 8.36775 15.5383 7.82502C14.4378 7.28228 13.2271 7 12 7C10.7729 7 9.56224 7.28229 8.46169 7.82502C7.36114 8.36775 6.40019 9.15638 5.65317 10.1299C4.90616 11.1034 4.39311 12.2358 4.15372 13.4393C3.91432 14.6428 3.955 15.8853 4.27259 17.0706C4.41554 17.604 4.09895 18.1524 3.56549 18.2953C3.03202 18.4382 2.48368 18.1217 2.34074 17.5882C1.94375 16.1066 1.8929 14.5535 2.19215 13.0491C2.49139 11.5447 3.1327 10.1293 4.06647 8.91239C5.00023 7.69548 6.20142 6.70969 7.57711 6.03127Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.93227 12.08C8.25236 12.8376 8.58374 13.6126 8.89803 14.3317C9.22106 15.0708 9.52346 15.7449 9.77552 16.2779C10.041 16.8392 10.2171 17.1706 10.2933 17.2814C10.328 17.3319 10.4193 17.413 10.6358 17.426C10.8542 17.4391 11.1472 17.3722 11.4337 17.1753C11.7203 16.9784 11.8877 16.7288 11.9538 16.5203C12.0193 16.3135 11.9763 16.1992 11.9416 16.1487C11.8654 16.0378 11.6192 15.7547 11.1904 15.3056C10.7832 14.8793 10.2623 14.3553 9.6881 13.7888C9.12947 13.2376 8.52473 12.6504 7.93227 12.08ZM5.14329 10.5941C4.52504 9.10136 6.37301 7.83142 7.54495 8.94373C8.57866 9.92485 9.93157 11.2194 11.0928 12.3651C11.6729 12.9375 12.2098 13.4772 12.6368 13.9243C13.0421 14.3488 13.4063 14.7488 13.5899 15.016C14.5135 16.3601 13.7287 18.0249 12.5664 18.8236C11.4041 19.6223 9.56858 19.7582 8.64495 18.4141C8.46137 18.147 8.21848 17.6635 7.96755 17.133C7.70321 16.5741 7.39179 15.8794 7.06541 15.1326C6.41213 13.6379 5.68863 11.9109 5.14329 10.5941Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5C12.5523 5 13 5.44772 13 6V8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8V6C11 5.44772 11.4477 5 12 5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.92891 7.92864C5.31943 7.53811 5.9526 7.53811 6.34312 7.92864L7.75734 9.34285C8.14786 9.73337 8.14786 10.3665 7.75734 10.7571C7.36681 11.1476 6.73365 11.1476 6.34312 10.7571L4.92891 9.34285C4.53838 8.95232 4.53838 8.31916 4.92891 7.92864Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0711 7.92864C18.6806 7.53811 18.0474 7.53811 17.6569 7.92864L16.2427 9.34285C15.8521 9.73337 15.8521 10.3665 16.2427 10.7571C16.6332 11.1476 17.2664 11.1476 17.6569 10.7571L19.0711 9.34285C19.4616 8.95232 19.4616 8.31916 19.0711 7.92864Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.6593 17.5879C21.5163 18.1213 20.968 18.4379 20.4345 18.295L18.5027 17.7773C17.9692 17.6344 17.6526 17.0861 17.7956 16.5526C17.9385 16.0191 18.4868 15.7025 19.0203 15.8455L20.9522 16.3631C21.4856 16.5061 21.8022 17.0544 21.6593 17.5879Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.34073 17.5879C2.48367 18.1213 3.03201 18.4379 3.56547 18.295L5.49732 17.7773C6.03079 17.6344 6.34737 17.0861 6.20443 16.5526C6.06149 16.0191 5.51315 15.7025 4.97969 15.8455L3.04783 16.3631C2.51437 16.5061 2.19779 17.0544 2.34073 17.5879Z"
      fill={color}
    />
  </svg>
);

IconEvaluations.defaultProps = defaultIconProps;
