import { defaultIconProps, IconProps } from './IconProps';

export const IconVideo: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <circle cx="8" cy="8" r="4.58333" stroke={color} strokeWidth="0.833333" />
    <path
      d="M9.875 7.63916C10.1528 7.79953 10.1528 8.20047 9.875 8.36084L7.375 9.80422C7.09722 9.96459 6.75 9.76413 6.75 9.44338L6.75 6.55662C6.75 6.23587 7.09722 6.03541 7.375 6.19578L9.875 7.63916Z"
      fill={color}
    />
  </svg>
);

IconVideo.defaultProps = defaultIconProps;
