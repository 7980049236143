import { defaultIconProps, IconProps } from './IconProps';

export const IconHandGrab: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g clipPath="url(#clip0_1846_9695)">
      <path
        d="M15 9.16683L15 5.00016C15 4.55814 14.8244 4.13421 14.5118 3.82165C14.1993 3.50909 13.7754 3.3335 13.3333 3.3335V3.3335C12.8913 3.3335 12.4674 3.50909 12.1548 3.82165C11.8423 4.13421 11.6667 4.55814 11.6667 5.00016V5.00016"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6667 8.33317L11.6667 3.33317C11.6667 2.89114 11.4911 2.46722 11.1785 2.15466C10.8659 1.8421 10.442 1.6665 10 1.6665C9.55797 1.6665 9.13404 1.8421 8.82148 2.15466C8.50892 2.46722 8.33333 2.89114 8.33333 3.33317L8.33333 4.99984"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33333 8.75016L8.33333 5.00016C8.33333 4.55814 8.15774 4.13421 7.84518 3.82165C7.53262 3.50909 7.10869 3.3335 6.66667 3.3335V3.3335C6.22464 3.3335 5.80072 3.50909 5.48816 3.82165C5.17559 4.13421 5 4.55814 5 5.00016L5 11.6668"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 6.66667C15 6.22464 15.1756 5.80072 15.4882 5.48816C15.8007 5.1756 16.2246 5 16.6667 5C17.1087 5 17.5326 5.1756 17.8452 5.48816C18.1577 5.80072 18.3333 6.22464 18.3333 6.66667L18.3333 11.6667C18.3333 13.4348 17.631 15.1305 16.3807 16.3807C15.1305 17.631 13.4348 18.3333 11.6667 18.3333L10 18.3333C7.66667 18.3333 6.25 17.6167 5.00833 16.3833L2.00833 13.3833C1.72161 13.0658 1.56799 12.6501 1.57927 12.2225C1.59055 11.7948 1.76587 11.3878 2.06893 11.0858C2.37199 10.7838 2.77958 10.6099 3.20731 10.6002C3.63503 10.5904 4.05014 10.7455 4.36667 11.0333L5.83333 12.5"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1846_9695">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

IconHandGrab.defaultProps = defaultIconProps;
