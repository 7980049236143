import { defaultIconProps, IconProps } from './IconProps';

export const IconSearchFill: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M18 11C18 14.866 14.866 18 11 18C7.13401 18 4 14.866 4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11Z" fill={color} />
    <path d="M20 20L18 18" stroke={color} strokeWidth="2" strokeLinecap="round" />
  </svg>
);

IconSearchFill.defaultProps = defaultIconProps;
