import { defaultIconProps, IconProps } from './IconProps';

interface IconBookProps extends IconProps {
  strokeWidth?: number;
}

export const IconBook: import('react').FC<IconBookProps> = ({ size, color, strokeWidth, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 28" fill="none" {...rest}>
    <path
      d="M1.66675 2H9.66675C11.0812 2 12.4378 2.5619 13.438 3.5621C14.4382 4.56229 15.0001 5.91885 15.0001 7.33333V26C15.0001 24.9391 14.5787 23.9217 13.8285 23.1716C13.0784 22.4214 12.0609 22 11.0001 22H1.66675V2Z"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.3333 2H20.3333C18.9188 2 17.5623 2.5619 16.5621 3.5621C15.5619 4.56229 15 5.91885 15 7.33333V26C15 24.9391 15.4214 23.9217 16.1716 23.1716C16.9217 22.4214 17.9391 22 19 22H28.3333V2Z"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconBook.defaultProps = {
  ...defaultIconProps,
  strokeWidth: 3,
};
