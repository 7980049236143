import { defaultIconProps, IconProps } from './IconProps';

export const IconCancel: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <circle cx="12" cy="12" r="9" stroke={color} strokeWidth="2" />
    <path d="M18 18L6 6" stroke={color} strokeWidth="2" />
  </svg>
);

IconCancel.defaultProps = defaultIconProps;
