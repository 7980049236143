import { defaultIconProps, IconProps } from './IconProps';

export const IconAlertOutlined: React.FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M4 28C4 14.7452 14.7452 4 28 4V4C41.2548 4 52 14.7452 52 28V28C52 41.2548 41.2548 52 28 52V52C14.7452 52 4 41.2548 4 28V28Z"
      fill="#FEE4E2"
    />
    <path
      d="M28 24V28M28 32H28.01M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28 48C16.9543 48 8 39.0457 8 28H0C0 43.464 12.536 56 28 56V48ZM48 28C48 39.0457 39.0457 48 28 48V56C43.464 56 56 43.464 56 28H48ZM28 8C39.0457 8 48 16.9543 48 28H56C56 12.536 43.464 0 28 0V8ZM28 0C12.536 0 0 12.536 0 28H8C8 16.9543 16.9543 8 28 8V0Z"
      fill="#FEF3F2"
    />
  </svg>
);

IconAlertOutlined.defaultProps = defaultIconProps;
