import getConfig from 'next/config';
import api, { Tracer } from '@opentelemetry/api';

export function getTracer(): Tracer {
  const config = getConfig().publicRuntimeConfig;
  const name = config.NAME as string;
  const version = config.VERSION as string;
  return api.trace.getTracer(name, version);
}

export function getActiveSpan() {
  return api.trace.getActiveSpan();
}
