import { defaultIconProps, IconProps } from './IconProps';

/* eslint-disable max-len */
export const IconBilling: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.93417 5C6.95604 5 6.97799 5 7 5L17.0658 5C17.9523 4.99995 18.7161 4.99991 19.3278 5.08215C19.9833 5.17028 20.6117 5.36902 21.1213 5.87868C21.631 6.38835 21.8297 7.0167 21.9179 7.67221C22.0001 8.28388 22.0001 9.0477 22 9.9342V15.0658C22.0001 15.9523 22.0001 16.7161 21.9179 17.3278C21.8297 17.9833 21.631 18.6117 21.1213 19.1213C20.6117 19.631 19.9833 19.8297 19.3278 19.9179C18.7161 20.0001 17.9523 20.0001 17.0658 20H6.93417C6.04769 20.0001 5.28387 20.0001 4.67221 19.9179C4.0167 19.8297 3.38835 19.631 2.87868 19.1213C2.36902 18.6117 2.17028 17.9833 2.08215 17.3278C1.99991 16.7161 1.99995 15.9523 2 15.0658L2 10C2 9.97799 2 9.95604 2 9.93417C1.99995 9.04769 1.99991 8.28387 2.08215 7.67221C2.17028 7.0167 2.36902 6.38835 2.87868 5.87868C3.38835 5.36902 4.0167 5.17028 4.67221 5.08215C5.28387 4.99991 6.04769 4.99995 6.93417 5ZM4.93871 7.06431C4.50497 7.12263 4.36902 7.21677 4.2929 7.2929C4.21677 7.36902 4.12263 7.50497 4.06431 7.93871C4.00213 8.40121 4 9.02893 4 10V15C4 15.9711 4.00213 16.5988 4.06431 17.0613C4.12263 17.495 4.21677 17.631 4.2929 17.7071C4.36902 17.7832 4.50497 17.8774 4.93871 17.9357C5.40121 17.9979 6.02893 18 7 18H17C17.9711 18 18.5988 17.9979 19.0613 17.9357C19.495 17.8774 19.631 17.7832 19.7071 17.7071C19.7832 17.631 19.8774 17.495 19.9357 17.0613C19.9979 16.5988 20 15.9711 20 15V10C20 9.02893 19.9979 8.40122 19.9357 7.93871C19.8774 7.50497 19.7832 7.36902 19.7071 7.2929C19.631 7.21677 19.495 7.12263 19.0613 7.06431C18.5988 7.00213 17.9711 7 17 7H7C6.02893 7 5.40122 7.00213 4.93871 7.06431Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 15C6 14.4477 6.44772 14 7 14H7.01C7.56228 14 8.01 14.4477 8.01 15C8.01 15.5523 7.56228 16 7.01 16H7C6.44772 16 6 15.5523 6 15Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 11C3 10.4477 3.44772 10 4 10H21C21.5523 10 22 10.4477 22 11C22 11.5523 21.5523 12 21 12H4C3.44772 12 3 11.5523 3 11Z"
      fill={color}
    />
  </svg>
);

IconBilling.defaultProps = defaultIconProps;
