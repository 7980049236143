import { defaultIconProps, IconProps } from './IconProps';

export const IconText: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M5.95996 12H4.14746L6.90918 4H9.08887L11.8467 12H10.0342L8.03027 5.82812H7.96777L5.95996 12ZM5.84668 8.85547H10.1279V10.1758H5.84668V8.85547Z"
      fill={color}
    />
  </svg>
);

IconText.defaultProps = defaultIconProps;
