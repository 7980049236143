import { defaultIconProps, IconProps } from './IconProps';

export const IconConcepts: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path fillRule="evenodd" clipRule="evenodd" d="M19 4H9V6H13V18H9V20H19V18H15V6H19V4Z" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 11C1 9.34315 2.34315 8 4 8H11V10H4C3.44772 10 3 10.4477 3 11V13C3 13.5523 3.44772 14 4 14H11V16H4C2.34315 16 1 14.6569 1 13V11Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 13C23 14.6569 21.6569 16 20 16H17V14H20C20.5523 14 21 13.5523 21 13V11C21 10.4477 20.5523 10 20 10H17V8H20C21.6569 8 23 9.34315 23 11V13Z"
      fill={color}
    />
  </svg>
);

IconConcepts.defaultProps = defaultIconProps;
