import { baseTheme } from 'styles/utils';
import { defaultIconProps, IconProps } from './IconProps';

type IconQuestionFillV2Props = IconProps & {
  questionMarkColor?: string;
};

export const IconQuestionFillV2 = ({
  size = 16,
  color = defaultIconProps.color,
  questionMarkColor = baseTheme.light.colors.white,
  ...rest
}: IconQuestionFillV2Props) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <rect width={size} height={size} rx={size / 2} fill={color} />
    <path
      d="M6.91091 9.75975V9.61418C6.91388 9.11465 6.95991 8.71646 7.049 8.4196C7.14105 8.12274 7.27468 7.88297 7.44989 7.70029C7.62509 7.5176 7.83593 7.35205 8.08241 7.20362C8.26652 7.08944 8.43133 6.97098 8.57684 6.84824C8.72235 6.7255 8.83816 6.58991 8.92428 6.44148C9.01039 6.2902 9.05345 6.12179 9.05345 5.93625C9.05345 5.7393 9.00445 5.5666 8.90646 5.41817C8.80846 5.26974 8.67632 5.15557 8.51002 5.07564C8.3467 4.99572 8.16555 4.95576 7.96659 4.95576C7.77357 4.95576 7.59094 4.99715 7.41871 5.07992C7.24647 5.15985 7.10542 5.27973 6.99555 5.43958C6.88567 5.59658 6.82628 5.7921 6.81737 6.02617H5C5.01485 5.45528 5.15739 4.9843 5.42762 4.61323C5.69785 4.2393 6.05568 3.96099 6.50111 3.77831C6.94655 3.59277 7.43801 3.5 7.9755 3.5C8.56644 3.5 9.08909 3.5942 9.54343 3.78259C9.99777 3.96813 10.3541 4.23787 10.6125 4.59182C10.8708 4.94577 11 5.3725 11 5.87203C11 6.20599 10.9421 6.50285 10.8263 6.76261C10.7134 7.01951 10.5546 7.24786 10.3497 7.44767C10.1448 7.64462 9.90275 7.82303 9.62361 7.98287C9.38901 8.11703 9.19599 8.2569 9.04454 8.40247C8.89606 8.54805 8.78471 8.71646 8.71047 8.90771C8.6392 9.09895 8.60208 9.33444 8.59911 9.61418V9.75975H6.91091ZM7.79287 12.5C7.49592 12.5 7.24202 12.4001 7.03118 12.2003C6.82331 11.9976 6.72086 11.755 6.72383 11.4724C6.72086 11.1927 6.82331 10.9529 7.03118 10.7531C7.24202 10.5533 7.49592 10.4534 7.79287 10.4534C8.07498 10.4534 8.32294 10.5533 8.53675 10.7531C8.75056 10.9529 8.85895 11.1927 8.86192 11.4724C8.85895 11.6608 8.80698 11.8335 8.70601 11.9905C8.60802 12.1446 8.47884 12.2688 8.31849 12.363C8.15813 12.4543 7.98293 12.5 7.79287 12.5Z"
      fill={questionMarkColor}
    />
  </svg>
);
