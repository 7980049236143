import { defaultIconProps, IconProps } from './IconProps';

export const IconDownload: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M12 16L11.2929 16.7071L12 17.4142L12.7071 16.7071L12 16ZM13 3C13 2.44772 12.5523 2 12 2C11.4477 2 11 2.44772 11 3L13 3ZM5.29289 10.7071L11.2929 16.7071L12.7071 15.2929L6.70711 9.29289L5.29289 10.7071ZM12.7071 16.7071L18.7071 10.7071L17.2929 9.29289L11.2929 15.2929L12.7071 16.7071ZM13 16L13 3L11 3L11 16L13 16Z"
      fill={color}
    />
    <path d="M6 21H18" stroke={color} strokeWidth="2" strokeLinecap="round" />
  </svg>
);

IconDownload.defaultProps = defaultIconProps;
