import { defaultIconProps, IconProps } from './IconProps';

export const IconFacebook: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M17.1167 2H2.88333C2.39533 2 2 2.39533 2 2.88333V17.1173C2 17.6047 2.39533 18 2.88333 18H10.5467V11.804H8.46133V9.38933H10.5467V7.60867C10.5467 5.542 11.8087 4.41667 13.6527 4.41667C14.536 4.41667 15.2947 4.48267 15.516 4.512V6.672L14.2373 6.67267C13.2347 6.67267 13.0407 7.14933 13.0407 7.848V9.39H15.432L15.1207 11.8047H13.0407V18H17.118C17.6047 18 18 17.6047 18 17.1167V2.88333C18 2.39533 17.6047 2 17.1167 2V2Z"
      fill={color}
    />
  </svg>
);

IconFacebook.defaultProps = defaultIconProps;
