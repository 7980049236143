import { defaultIconProps, IconProps } from './IconProps';

export const IconRoundExpand: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M11 7V15" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7 11H15" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

IconRoundExpand.defaultProps = defaultIconProps;
