import { baseTheme } from 'styles/utils';
import { IconProps } from './IconProps';

export const IconTrainModelV2: React.FC<IconProps> = ({ size = 20, color = baseTheme.light.colors.gray700, ...rest }) => (
  <svg {...rest} width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_516_684)">
      <path
        d="M17.6665 18.3333C17.8999 18.3333 18.0165 18.3333 18.1057 18.2879C18.1841 18.248 18.2478 18.1842 18.2878 18.1058C18.3332 18.0167 18.3332 17.9 18.3332 17.6667V9C18.3332 8.76664 18.3332 8.64997 18.2878 8.56084C18.2478 8.48244 18.1841 8.41869 18.1057 8.37875C18.0165 8.33333 17.8999 8.33333 17.6665 8.33333L15.6665 8.33333C15.4331 8.33333 15.3165 8.33333 15.2273 8.37875C15.1489 8.41869 15.0852 8.48243 15.0453 8.56084C14.9998 8.64997 14.9998 8.76664 14.9998 9V11C14.9998 11.2334 14.9998 11.35 14.9544 11.4392C14.9145 11.5176 14.8507 11.5813 14.7723 11.6213C14.6832 11.6667 14.5665 11.6667 14.3332 11.6667H12.3332C12.0998 11.6667 11.9831 11.6667 11.894 11.7121C11.8156 11.752 11.7519 11.8158 11.7119 11.8942C11.6665 11.9833 11.6665 12.1 11.6665 12.3333V14.3333C11.6665 14.5667 11.6665 14.6834 11.6211 14.7725C11.5811 14.8509 11.5174 14.9146 11.439 14.9546C11.3499 15 11.2332 15 10.9998 15H8.99984C8.76648 15 8.6498 15 8.56067 15.0454C8.48227 15.0854 8.41853 15.1491 8.37858 15.2275C8.33317 15.3166 8.33317 15.4333 8.33317 15.6667V17.6667C8.33317 17.9 8.33317 18.0167 8.37858 18.1058C8.41853 18.1842 8.48227 18.248 8.56067 18.2879C8.6498 18.3333 8.76648 18.3333 8.99984 18.3333L17.6665 18.3333Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33317 5.66666C8.33317 5.43331 8.33317 5.31663 8.37858 5.2275C8.41853 5.1491 8.48227 5.08536 8.56067 5.04541C8.6498 5 8.76648 5 8.99984 5H10.9998C11.2332 5 11.3499 5 11.439 5.04541C11.5174 5.08536 11.5811 5.1491 11.6211 5.2275C11.6665 5.31663 11.6665 5.43331 11.6665 5.66666V7.66666C11.6665 7.90002 11.6665 8.0167 11.6211 8.10583C11.5811 8.18423 11.5174 8.24797 11.439 8.28792C11.3499 8.33333 11.2332 8.33333 10.9998 8.33333H8.99984C8.76648 8.33333 8.6498 8.33333 8.56067 8.28792C8.48227 8.24797 8.41853 8.18423 8.37858 8.10583C8.33317 8.0167 8.33317 7.90002 8.33317 7.66666V5.66666Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.49984 10.6667C2.49984 10.4333 2.49984 10.3166 2.54525 10.2275C2.5852 10.1491 2.64894 10.0854 2.72734 10.0454C2.81647 10 2.93315 10 3.1665 10H5.1665C5.39986 10 5.51654 10 5.60567 10.0454C5.68407 10.0854 5.74781 10.1491 5.78776 10.2275C5.83317 10.3166 5.83317 10.4333 5.83317 10.6667V12.6667C5.83317 12.9 5.83317 13.0167 5.78776 13.1058C5.74781 13.1842 5.68407 13.248 5.60567 13.2879C5.51654 13.3333 5.39986 13.3333 5.1665 13.3333H3.1665C2.93315 13.3333 2.81647 13.3333 2.72734 13.2879C2.64894 13.248 2.5852 13.1842 2.54525 13.1058C2.49984 13.0167 2.49984 12.9 2.49984 12.6667V10.6667Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.6665 2.33333C1.6665 2.09998 1.6665 1.9833 1.71192 1.89417C1.75187 1.81577 1.81561 1.75203 1.89401 1.71208C1.98314 1.66666 2.09982 1.66666 2.33317 1.66666H4.33317C4.56653 1.66666 4.6832 1.66666 4.77233 1.71208C4.85073 1.75203 4.91448 1.81577 4.95442 1.89417C4.99984 1.9833 4.99984 2.09998 4.99984 2.33333V4.33333C4.99984 4.56669 4.99984 4.68336 4.95442 4.77249C4.91448 4.85089 4.85073 4.91464 4.77233 4.95458C4.6832 5 4.56653 5 4.33317 5H2.33317C2.09982 5 1.98314 5 1.89401 4.95458C1.81561 4.91464 1.75187 4.85089 1.71192 4.77249C1.6665 4.68336 1.6665 4.56669 1.6665 4.33333V2.33333Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_516_684">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
