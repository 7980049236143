import { defaultIconProps, IconProps } from './IconProps';

export const IconToggleLabelledInputs: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M6.24992 6.25001H6.25825M17.5749 11.5917L11.5999 17.5667C11.4451 17.7216 11.2613 17.8446 11.059 17.9284C10.8567 18.0123 10.6398 18.0555 10.4208 18.0555C10.2017 18.0555 9.98485 18.0123 9.78252 17.9284C9.58019 17.8446 9.39637 17.7216 9.24159 17.5667L2.08325 10.4167V2.08334H10.4166L17.5749 9.24168C17.8853 9.55395 18.0596 9.97637 18.0596 10.4167C18.0596 10.857 17.8853 11.2794 17.5749 11.5917Z"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line x1="3" y1="15.8191" x2="16.8191" y2="2" stroke={color} strokeWidth="1.67" strokeLinecap="round" />
  </svg>
);

IconToggleLabelledInputs.defaultProps = defaultIconProps;
