import { defaultIconProps, IconProps } from './IconProps';

export const IconGripVertical: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M10.3331 4.00033C10.7013 4.00033 10.9998 3.70185 10.9998 3.33366C10.9998 2.96547 10.7013 2.66699 10.3331 2.66699C9.9649 2.66699 9.66642 2.96547 9.66642 3.33366C9.66642 3.70185 9.9649 4.00033 10.3331 4.00033Z"
      fill={color}
    />
    <path
      d="M10.3331 8.66699C10.7013 8.66699 10.9998 8.36852 10.9998 8.00033C10.9998 7.63214 10.7013 7.33366 10.3331 7.33366C9.9649 7.33366 9.66642 7.63214 9.66642 8.00033C9.66642 8.36852 9.9649 8.66699 10.3331 8.66699Z"
      fill={color}
    />
    <path
      d="M10.3331 13.3337C10.7013 13.3337 10.9998 13.0352 10.9998 12.667C10.9998 12.2988 10.7013 12.0003 10.3331 12.0003C9.9649 12.0003 9.66642 12.2988 9.66642 12.667C9.66642 13.0352 9.9649 13.3337 10.3331 13.3337Z"
      fill={color}
    />
    <path
      d="M5.66642 4.00033C6.03461 4.00033 6.33309 3.70185 6.33309 3.33366C6.33309 2.96547 6.03461 2.66699 5.66642 2.66699C5.29823 2.66699 4.99976 2.96547 4.99976 3.33366C4.99976 3.70185 5.29823 4.00033 5.66642 4.00033Z"
      fill={color}
    />
    <path
      d="M5.66642 8.66699C6.03461 8.66699 6.33309 8.36852 6.33309 8.00033C6.33309 7.63214 6.03461 7.33366 5.66642 7.33366C5.29823 7.33366 4.99976 7.63214 4.99976 8.00033C4.99976 8.36852 5.29823 8.66699 5.66642 8.66699Z"
      fill={color}
    />
    <path
      d="M5.66642 13.3337C6.03461 13.3337 6.33309 13.0352 6.33309 12.667C6.33309 12.2988 6.03461 12.0003 5.66642 12.0003C5.29823 12.0003 4.99976 12.2988 4.99976 12.667C4.99976 13.0352 5.29823 13.3337 5.66642 13.3337Z"
      fill="#344054"
    />
    <path
      d="M10.3331 4.00033C10.7013 4.00033 10.9998 3.70185 10.9998 3.33366C10.9998 2.96547 10.7013 2.66699 10.3331 2.66699C9.9649 2.66699 9.66642 2.96547 9.66642 3.33366C9.66642 3.70185 9.9649 4.00033 10.3331 4.00033Z"
      stroke="#A0A5AE"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3331 8.66699C10.7013 8.66699 10.9998 8.36852 10.9998 8.00033C10.9998 7.63214 10.7013 7.33366 10.3331 7.33366C9.9649 7.33366 9.66642 7.63214 9.66642 8.00033C9.66642 8.36852 9.9649 8.66699 10.3331 8.66699Z"
      stroke="#A0A5AE"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3331 13.3337C10.7013 13.3337 10.9998 13.0352 10.9998 12.667C10.9998 12.2988 10.7013 12.0003 10.3331 12.0003C9.9649 12.0003 9.66642 12.2988 9.66642 12.667C9.66642 13.0352 9.9649 13.3337 10.3331 13.3337Z"
      stroke="#A0A5AE"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.66642 4.00033C6.03461 4.00033 6.33309 3.70185 6.33309 3.33366C6.33309 2.96547 6.03461 2.66699 5.66642 2.66699C5.29823 2.66699 4.99976 2.96547 4.99976 3.33366C4.99976 3.70185 5.29823 4.00033 5.66642 4.00033Z"
      stroke="#A0A5AE"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.66642 8.66699C6.03461 8.66699 6.33309 8.36852 6.33309 8.00033C6.33309 7.63214 6.03461 7.33366 5.66642 7.33366C5.29823 7.33366 4.99976 7.63214 4.99976 8.00033C4.99976 8.36852 5.29823 8.66699 5.66642 8.66699Z"
      stroke="#A0A5AE"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.66642 13.3337C6.03461 13.3337 6.33309 13.0352 6.33309 12.667C6.33309 12.2988 6.03461 12.0003 5.66642 12.0003C5.29823 12.0003 4.99976 12.2988 4.99976 12.667C4.99976 13.0352 5.29823 13.3337 5.66642 13.3337Z"
      stroke="#A0A5AE"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconGripVertical.defaultProps = defaultIconProps;
