import { defaultIconProps, IconProps } from './IconProps';

export const IconPen: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M15 10.8333L13.917 5.41818C13.8565 5.11568 13.8262 4.96443 13.7527 4.84126C13.6876 4.73237 13.5986 4.63976 13.4924 4.57049C13.3722 4.49213 13.2223 4.45594 12.9224 4.38355L1.66667 1.66666M1.66667 1.66666L4.38357 12.9224C4.45595 13.2222 4.49214 13.3722 4.5705 13.4924C4.63978 13.5986 4.73239 13.6876 4.84128 13.7527C4.96445 13.8262 5.11569 13.8565 5.41819 13.917L10.8333 15M1.66667 1.66666L7.98834 7.98832M13.4428 17.3905L17.3905 13.4428C17.7205 13.1128 17.8856 12.9478 17.9474 12.7575C18.0018 12.5901 18.0018 12.4098 17.9474 12.2425C17.8856 12.0522 17.7205 11.8872 17.3905 11.5572L16.7761 10.9428C16.4461 10.6128 16.2811 10.4478 16.0909 10.386C15.9235 10.3316 15.7432 10.3316 15.5758 10.386C15.3856 10.4478 15.2205 10.6128 14.8905 10.9428L10.9428 14.8905C10.6128 15.2205 10.4478 15.3855 10.386 15.5758C10.3316 15.7432 10.3316 15.9235 10.386 16.0908C10.4478 16.2811 10.6128 16.4461 10.9428 16.7761L11.5572 17.3905C11.8872 17.7205 12.0522 17.8855 12.2425 17.9474C12.4099 18.0017 12.5902 18.0017 12.7575 17.9474C12.9478 17.8855 13.1128 17.7205 13.4428 17.3905ZM10.8333 9.16666C10.8333 10.0871 10.0871 10.8333 9.16667 10.8333C8.2462 10.8333 7.50001 10.0871 7.50001 9.16666C7.50001 8.24618 8.2462 7.49999 9.16667 7.49999C10.0871 7.49999 10.8333 8.24618 10.8333 9.16666Z"
      stroke={color}
      strokeWidth="1.66"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconPen.defaultProps = defaultIconProps;
