import { defaultIconProps, IconProps } from './IconProps';

export const IconDataflow04: import('react').FC<IconProps> = ({ strokeWidth, size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M3 17V16.8C3 15.1198 3 14.2798 3.32698 13.638C3.6146 13.0735 4.07354 12.6146 4.63803 12.327C5.27976 12 6.11984 12 7.8 12H14.2C15.8802 12 16.7202 12 17.362 12.327C17.9265 12.6146 18.3854 13.0735 18.673 13.638C19 14.2798 19 15.1198 19 16.8V17M3 17C1.89543 17 1 17.8954 1 19C1 20.1046 1.89543 21 3 21C4.10457 21 5 20.1046 5 19C5 17.8954 4.10457 17 3 17ZM19 17C17.8954 17 17 17.8954 17 19C17 20.1046 17.8954 21 19 21C20.1046 21 21 20.1046 21 19C21 17.8954 20.1046 17 19 17ZM11 17C9.89543 17 9 17.8954 9 19C9 20.1046 9.89543 21 11 21C12.1046 21 13 20.1046 13 19C13 17.8954 12.1046 17 11 17ZM11 17V7M5 7H17C17.9319 7 18.3978 7 18.7654 6.84776C19.2554 6.64477 19.6448 6.25542 19.8478 5.76537C20 5.39782 20 4.93188 20 4C20 3.06812 20 2.60218 19.8478 2.23463C19.6448 1.74458 19.2554 1.35523 18.7654 1.15224C18.3978 1 17.9319 1 17 1H5C4.06812 1 3.60218 1 3.23463 1.15224C2.74458 1.35523 2.35523 1.74458 2.15224 2.23463C2 2.60218 2 3.06812 2 4C2 4.93188 2 5.39782 2.15224 5.76537C2.35523 6.25542 2.74458 6.64477 3.23463 6.84776C3.60218 7 4.06812 7 5 7Z"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconDataflow04.defaultProps = defaultIconProps;
