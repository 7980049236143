import { defaultIconProps, IconProps } from './IconProps';

export const IconCheck: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7682 9.64023L11.7682 15.6402L11.0672 16.4815L10.2929 15.7072L7.29291 12.7072L8.70712 11.2929L10.9328 13.5186L15.2318 8.35986L16.7682 9.64023Z"
      fill={color}
    />
  </svg>
);

IconCheck.defaultProps = defaultIconProps;
