import { defaultIconProps, IconProps } from './IconProps';

export const IconVisualSearchModal: React.FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M15.8333 2.5H4.16667C3.24619 2.5 2.5 3.24619 2.5 4.16667V15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5H15.8333C16.7538 17.5 17.5 16.7538 17.5 15.8333V4.16667C17.5 3.24619 16.7538 2.5 15.8333 2.5Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.08337 8.33337C7.77373 8.33337 8.33337 7.77373 8.33337 7.08337C8.33337 6.39302 7.77373 5.83337 7.08337 5.83337C6.39302 5.83337 5.83337 6.39302 5.83337 7.08337C5.83337 7.77373 6.39302 8.33337 7.08337 8.33337Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M17.5 12.5L13.3333 8.33337L4.16663 17.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

IconVisualSearchModal.defaultProps = defaultIconProps;
