import { defaultIconProps, IconProps } from './IconProps';

export const IconBug: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M13.3332 8.33333C13.3332 6.49238 11.8408 5 9.99984 5C8.15889 5 6.6665 6.49238 6.6665 8.33333V13.3333C6.6665 15.1743 8.15889 16.6667 9.99984 16.6667C11.8408 16.6667 13.3332 15.1743 13.3332 13.3333V8.33333Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M15.8335 5.83301L13.3335 7.49967" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.1665 5.83301L6.6665 7.49967" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.8335 15.8337L13.3335 14.167" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.1665 15.8337L6.6665 14.167" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16.6668 10.833H13.3335" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.3335 10.833H6.66683" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.3335 3.33301L9.16683 4.99967" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.6668 3.33301L10.8335 4.99967" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

IconBug.defaultProps = {
  ...defaultIconProps,
};
