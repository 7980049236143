interface IconFilterProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  size?: number;
  color?: string;
}

export const IconFilter: import('react').FC<IconFilterProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M18.3334 1.5H1.66669L8.33335 9.38333V14.8333L11.6667 16.5V9.38333L18.3334 1.5Z"
      stroke={color}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconFilter.defaultProps = {
  size: 20,
  color: '#667085',
};
