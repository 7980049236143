import React, { FC } from 'react';
import { cx } from '@linaria/core';
import { Link } from 'components/Link';
import { cssHelpers } from 'styles/utils/core';
import { bigTitle, textTitle } from 'styles/typography';
import { StyledButton } from 'components/Button/Button.styles';
import { container, pageTitle, description, btn, img } from './ConnectionError.styles';
const {
  flex
} = cssHelpers;
export const ConnectionError: FC = () => <div className={cx(flex.init, flex.direction.column, flex.justifyContent.center, flex.alignItems.center, container)}>
    <h1 className={cx(bigTitle, pageTitle)}>
      <span className={img} />
      Server Error
    </h1>
    <p className={cx(textTitle, description)}>An error has occurred during connection to the server and the requested page cannot be accessed.</p>
    <StyledButton variant="primary" className={cx(btn, 'btn md')}>
      <Link href="/explore">Back to Home Page</Link>
    </StyledButton>
  </div>;