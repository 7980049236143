import { defaultIconProps, IconProps } from './IconProps';

export const IconTableEdit: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M15.0002 8.33332L11.6669 4.99999M2.0835 17.9167L4.9038 17.6033C5.24838 17.565 5.42067 17.5459 5.5817 17.4937C5.72457 17.4475 5.86054 17.3821 5.9859 17.2995C6.12721 17.2063 6.24978 17.0837 6.49493 16.8386L17.5002 5.83332C18.4207 4.91285 18.4207 3.42046 17.5002 2.49999C16.5797 1.57951 15.0873 1.57951 14.1669 2.49999L3.1616 13.5052C2.91645 13.7504 2.79388 13.8729 2.70069 14.0142C2.61802 14.1396 2.55268 14.2756 2.50642 14.4185C2.45429 14.5795 2.43515 14.7518 2.39686 15.0964L2.0835 17.9167Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconTableEdit.defaultProps = defaultIconProps;
