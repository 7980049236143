interface IconExpandDownRoundedProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  size: number;
  color?: string;
}

export const IconExpandDownRounded: import('react').FC<IconExpandDownRoundedProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M1 1L7 7L13 1" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

IconExpandDownRounded.defaultProps = {
  size: 10,
  color: '#101828',
};
