import { UI_STATES } from 'utils/uiStates/uiStates';

type StateKeys = keyof typeof UI_STATES;
type StateValues = typeof UI_STATES[StateKeys];
export type ErrorType = {
  status?: CF.API.Status;
};

const HTTP_STATUS_SERVICE_UNAVAILABLE = 503;

export const getErrorUiStateKey = (error: ErrorType): StateValues => {
  const httpStatus = error?.status?.httpStatus;
  return httpStatus === HTTP_STATUS_SERVICE_UNAVAILABLE ? UI_STATES.connectionError : UI_STATES.error;
};
