import { defaultIconProps, IconProps } from './IconProps';

export const IconBriefcase: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M13.3346 5.83333C13.3346 5.05836 13.3346 4.67087 13.2495 4.35295C13.0183 3.49022 12.3444 2.81635 11.4817 2.58519C11.1638 2.5 10.7763 2.5 10.0013 2.5C9.22633 2.5 8.83884 2.5 8.52092 2.58519C7.65819 2.81635 6.98432 3.49022 6.75315 4.35295C6.66797 4.67087 6.66797 5.05836 6.66797 5.83333M4.33464 17.5H15.668C16.6014 17.5 17.0681 17.5 17.4246 17.3183C17.7382 17.1586 17.9932 16.9036 18.153 16.59C18.3346 16.2335 18.3346 15.7668 18.3346 14.8333V8.5C18.3346 7.56658 18.3346 7.09987 18.153 6.74335C17.9932 6.42975 17.7382 6.17478 17.4246 6.01499C17.0681 5.83333 16.6014 5.83333 15.668 5.83333H4.33464C3.40121 5.83333 2.9345 5.83333 2.57798 6.01499C2.26438 6.17478 2.00941 6.42975 1.84962 6.74335C1.66797 7.09987 1.66797 7.56658 1.66797 8.5V14.8333C1.66797 15.7668 1.66797 16.2335 1.84962 16.59C2.00941 16.9036 2.26438 17.1586 2.57798 17.3183C2.9345 17.5 3.40121 17.5 4.33464 17.5Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconBriefcase.defaultProps = defaultIconProps;
