import { QueryClient, DefaultOptions } from 'react-query';

/* istanbul ignore next */
export function makeAppQueryClient(): QueryClient {
  const defaultQueryOptions: DefaultOptions<unknown>['queries'] = {
    staleTime: 5000, // 5 seconds before fetched data is considered stale; makes SSR not refetch on client mount
    // https://react-query.tanstack.com/guides/testing#turn-off-retries
    retry: false,
    cacheTime: 300000,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  };

  return new QueryClient({
    defaultOptions: {
      queries: defaultQueryOptions,
    },
  });
}
