import { defaultIconProps, IconProps } from './IconProps';

export const IconRobot: import('react').FC<IconProps> = ({ size = 10, color = '#195AFF', ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <rect x="1.5" y="0.5" width="9" height="5.52174" rx="2.76087" stroke={color} strokeWidth={1} />
    <path
      d="M6.00023 6.15576V7.98643M6.00023 7.98643H2.94141C2.11298 7.98643 1.44141 8.658 1.44141 9.48643V10.0002M6.00023 7.98643H9.05905C9.88748 7.98643 10.5591 8.658 10.5591 9.48643V10.0002"
      stroke={color}
      strokeWidth={1}
    />
    <ellipse cx="4.125" cy="3.26106" rx="0.625" ry="0.652174" fill={color} />
    <ellipse cx="7.94922" cy="3.26106" rx="0.625" ry="0.652174" fill={color} />
  </svg>
);

IconRobot.defaultProps = defaultIconProps;
