import { defaultIconProps, IconProps } from './IconProps';

export const IconPinFill: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M12.2573 3.40949C12.9103 2.97412 13.2368 2.75643 13.5926 2.79166C13.9484 2.82689 14.2259 3.10439 14.7809 3.65938L16.6288 5.50729C17.1838 6.06228 17.4613 6.33978 17.4965 6.69555C17.5317 7.05132 17.314 7.37785 16.8787 8.0309L15.6564 9.86435C15.334 10.3479 15.1729 10.5896 15.0307 10.8403C14.7058 11.413 14.4529 12.0235 14.2777 12.6582C14.201 12.936 14.144 13.2209 14.03 13.7907L13.8709 14.5865C13.753 15.1758 13.0702 15.4517 12.5761 15.1096C9.68561 13.1085 7.17968 10.6026 5.17859 7.71209C4.83652 7.21799 5.11236 6.53516 5.70165 6.4173L6.49745 6.25814C7.06728 6.14418 7.3522 6.08719 7.62997 6.0105C8.26462 5.83526 8.87521 5.58234 9.4479 5.25749C9.69854 5.11531 9.9403 4.95413 10.4238 4.63179L12.2573 3.40949Z"
      fill={color}
      stroke={color}
      strokeWidth="1.75"
    />
    <path d="M4.45483 15.834L8.20483 12.084" stroke={color} strokeWidth="2" strokeLinecap="round" />
  </svg>
);

IconPinFill.defaultProps = defaultIconProps;
