import { defaultIconProps, IconProps } from './IconProps';

export const IconNoCollaborators: React.FC<IconProps> = ({ ...rest }) => (
  <svg width="92" height="91" viewBox="0 0 92 91" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g filter="url(#filter0_dd_89_10759)">
      <path
        d="M27.6519 18.3159C24.1553 18.3159 21.3208 21.1505 21.3208 24.6471V61.353C21.3208 64.8496 24.1553 67.6841 27.652 67.6841H64.3578C67.8545 67.6841 70.689 64.8496 70.689 61.353V24.6471C70.689 21.1505 67.8544 18.3159 64.3578 18.3159H27.6519Z"
        fill="url(#paint0_radial_89_10759)"
        stroke="#6695F3"
        strokeWidth="1.3682"
      />
    </g>
    <g filter="url(#filter1_d_89_10759)">
      <path
        d="M55.0196 37.905C57.6244 37.905 59.7359 35.6878 59.7359 32.9528C59.7359 30.2178 57.6244 28.0006 55.0196 28.0006C52.4148 28.0006 50.3032 30.2178 50.3032 32.9528C50.3032 35.6878 52.4148 37.905 55.0196 37.905Z"
        fill="#E6EDFF"
      />
    </g>
    <g filter="url(#filter2_d_89_10759)">
      <path
        d="M57.0947 39.3199H52.9443C50.3659 39.3199 49.0767 39.3199 48.0597 39.7638C46.7037 40.3556 45.6264 41.4908 45.0648 42.9196C44.7511 43.7175 44.671 48.7154 44.6506 52.3381C44.6446 53.388 44.6417 53.9129 44.8465 54.3188C45.0259 54.6745 45.3168 54.967 45.6714 55.1484C46.0761 55.3555 46.6048 55.3555 47.662 55.3555H62.3771C63.4343 55.3555 63.9629 55.3555 64.3677 55.1484C64.7223 54.967 65.0132 54.6745 65.1926 54.3188C65.3974 53.9129 65.3944 53.388 65.3885 52.3381C65.3681 48.7154 65.2879 43.7175 64.9743 42.9196C64.4126 41.4908 63.3353 40.3556 61.9794 39.7638C60.9624 39.3199 59.6732 39.3199 57.0947 39.3199Z"
        fill="#E6EDFF"
      />
    </g>
    <g filter="url(#filter3_d_89_10759)">
      <path
        d="M40.3988 41.2065C43.7851 41.2065 46.5301 38.4614 46.5301 35.0752C46.5301 31.689 43.7851 28.9439 40.3988 28.9439C37.0126 28.9439 34.2676 31.689 34.2676 35.0752C34.2676 38.4614 37.0126 41.2065 40.3988 41.2065Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter4_d_89_10759)">
      <path
        d="M42.9456 42.6214H37.8519C34.6874 42.6214 33.1052 42.6214 31.8571 43.1828C30.193 43.9313 28.8708 45.3669 28.1815 47.1739C27.7736 48.2432 27.6876 55.2771 27.6694 59.8855C27.6653 60.9364 27.6632 61.4619 27.8682 61.8671C28.0481 62.2225 28.3383 62.5138 28.6931 62.6951C29.0974 62.9018 29.626 62.9018 30.683 62.9018H50.1144C51.1715 62.9018 51.7 62.9018 52.1044 62.6951C52.4592 62.5138 52.7493 62.2225 52.9292 61.8671C53.1342 61.4619 53.1322 60.9364 53.128 59.8855V59.8855C53.1099 55.2771 53.0238 48.2432 52.6159 47.1739C51.9266 45.3669 50.6045 43.9313 48.9403 43.1828C47.6923 42.6214 46.11 42.6214 42.9456 42.6214Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_89_10759"
        x="0.872"
        y="0.690618"
        width="90.2658"
        height="90.2659"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2.82353" />
        <feGaussianBlur stdDeviation="9.88236" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.188235 0 0 0 0 0.415686 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_89_10759" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2.73639" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.243137 0 0 0 0 0.737255 0 0 0 1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_89_10759" result="effect2_dropShadow_89_10759" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_89_10759" result="shape" />
      </filter>
      <filter
        id="filter1_d_89_10759"
        x="44.6562"
        y="22.3536"
        width="20.7267"
        height="21.1985"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="2.82353" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.6 0 0 0 0 0.721569 0 0 0 0 0.968627 0 0 0 0.9 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_89_10759" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_89_10759" result="shape" />
      </filter>
      <filter
        id="filter2_d_89_10759"
        x="39.0019"
        y="33.6728"
        width="32.0353"
        height="27.3298"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="2.82353" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.6 0 0 0 0 0.721569 0 0 0 0 0.968627 0 0 0 0.9 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_89_10759" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_89_10759" result="shape" />
      </filter>
      <filter
        id="filter3_d_89_10759"
        x="28.6205"
        y="23.2969"
        width="23.5568"
        height="23.5567"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="2.82353" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.6 0 0 0 0 0.721569 0 0 0 0 0.968627 0 0 0 0.9 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_89_10759" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_89_10759" result="shape" />
      </filter>
      <filter
        id="filter4_d_89_10759"
        x="22.0214"
        y="36.9744"
        width="36.7546"
        height="31.5745"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="2.82353" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.6 0 0 0 0 0.721569 0 0 0 0 0.968627 0 0 0 0.9 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_89_10759" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_89_10759" result="shape" />
      </filter>
      <radialGradient
        id="paint0_radial_89_10759"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(37.0551 27.197) rotate(51.2258) scale(53.5261 69.2569)"
      >
        <stop offset="0.221581" stopColor="#306AFF" />
        <stop offset="0.729167" stopColor="#003EBC" />
      </radialGradient>
    </defs>
  </svg>
);

IconNoCollaborators.defaultProps = defaultIconProps;
