import React, { FC, useCallback, useState } from 'react';
import { noop } from 'utils/fp';
import { TipPopover } from './TipPopover';
import { TipMap, TipPopoverContextState, TipPopoverProps } from './types';

export const TipPopoverContext = React.createContext<TipPopoverContextState>({
  showTip: noop,
  hideTip: noop,
});

export const TipPopoverProvider: FC = ({ children }) => {
  const [tipMap, setTipMap] = useState<TipMap>({});

  const showTip = useCallback(({ id, ...restOfProps }: TipPopoverProps) => {
    setTipMap((currentTipMap) => ({
      ...currentTipMap,
      [id]: {
        ...restOfProps,
        show: true,
      },
    }));
  }, []);

  const hideTip = useCallback((tipId: string) => {
    setTipMap((currentTipMap) => ({
      ...currentTipMap,
      [tipId]: {
        ...currentTipMap[tipId],
        show: false,
      },
    }));
  }, []);

  return (
    <TipPopoverContext.Provider value={{ showTip, hideTip }}>
      {children}
      {Object.entries(tipMap).map(([key, { contextRef, ...tipProps }]) => {
        const position = tipProps.position || { x: 0, y: 0 };

        return (
          <TipPopover
            key={key}
            id={key}
            {...tipProps}
            hideTip={hideTip}
            position={{
              x: position.x + (contextRef?.current?.getBoundingClientRect().x || 0),
              y: position.y + (contextRef?.current?.getBoundingClientRect().y || 0),
            }}
          />
        );
      })}
    </TipPopoverContext.Provider>
  );
};
