export interface IconProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  size?: number;
  color?: string;
  strokeWidth?: number;
}

export const defaultIconProps: IconProps = {
  strokeWidth: 2,
  size: 10,
  color: 'currentColor',
};
