import { defaultIconProps, IconProps } from './IconProps';

export const IconDiscord: import('react').FC<IconProps> = ({ size, color = '#7289DA', ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M8.23973 8.39001C7.66973 8.39001 7.21973 8.89001 7.21973 9.50001C7.21973 10.11 7.67973 10.61 8.23973 10.61C8.80973 10.61 9.25973 10.11 9.25973 9.50001C9.26973 8.89001 8.80973 8.39001 8.23973 8.39001ZM11.8897 8.39001C11.3197 8.39001 10.8697 8.89001 10.8697 9.50001C10.8697 10.11 11.3297 10.61 11.8897 10.61C12.4597 10.61 12.9097 10.11 12.9097 9.50001C12.9097 8.89001 12.4597 8.39001 11.8897 8.39001Z"
      fill={color}
    />
    <path
      d="M16.7498 0H3.3498C2.2198 0 1.2998 0.92 1.2998 2.06V15.58C1.2998 16.72 2.2198 17.64 3.3498 17.64H14.6898L14.1598 15.79L15.4398 16.98L16.6498 18.1L18.7998 20V2.06C18.7998 0.92 17.8798 0 16.7498 0ZM12.8898 13.06C12.8898 13.06 12.5298 12.63 12.2298 12.25C13.5398 11.88 14.0398 11.06 14.0398 11.06C13.6298 11.33 13.2398 11.52 12.8898 11.65C12.3898 11.86 11.9098 12 11.4398 12.08C10.4798 12.26 9.5998 12.21 8.8498 12.07C8.2798 11.96 7.7898 11.8 7.37981 11.64C7.14981 11.55 6.8998 11.44 6.6498 11.3C6.6198 11.28 6.5898 11.27 6.5598 11.25C6.5398 11.24 6.5298 11.23 6.5198 11.22C6.3398 11.12 6.2398 11.05 6.2398 11.05C6.2398 11.05 6.7198 11.85 7.9898 12.23C7.68981 12.61 7.3198 13.06 7.3198 13.06C5.1098 12.99 4.2698 11.54 4.2698 11.54C4.2698 8.32 5.7098 5.71 5.7098 5.71C7.1498 4.63 8.5198 4.66 8.5198 4.66L8.6198 4.78C6.8198 5.3 5.9898 6.09 5.9898 6.09C5.9898 6.09 6.20981 5.97 6.5798 5.8C7.64981 5.33 8.4998 5.2 8.8498 5.17C8.9098 5.16 8.9598 5.15 9.0198 5.15C9.6298 5.07 10.3198 5.05 11.0398 5.13C11.9898 5.24 13.0098 5.52 14.0498 6.09C14.0498 6.09 13.2598 5.34 11.5598 4.82L11.6998 4.66C11.6998 4.66 13.0698 4.63 14.5098 5.71C14.5098 5.71 15.9498 8.32 15.9498 11.54C15.9498 11.54 15.0998 12.99 12.8898 13.06Z"
      fill={color}
    />
  </svg>
);

IconDiscord.defaultProps = {
  ...defaultIconProps,
};
