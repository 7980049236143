import { defaultIconProps, IconProps } from './IconProps';

export const IconMailFill: import('react').FC<IconProps> = ({ size = 56, color = '#F2F6FE', ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M0 28C0 12.536 12.536 0 28 0C43.464 0 56 12.536 56 28C56 43.464 43.464 56 28 56C12.536 56 0 43.464 0 28Z" fill={color} />
    <path
      d="M39.6667 21C39.6667 19.7167 38.6167 18.6667 37.3334 18.6667H18.6667C17.3834 18.6667 16.3334 19.7167 16.3334 21M39.6667 21V35C39.6667 36.2833 38.6167 37.3333 37.3334 37.3333H18.6667C17.3834 37.3333 16.3334 36.2833 16.3334 35V21M39.6667 21L28 29.1667L16.3334 21"
      stroke="#004EEB"
      strokeWidth="2.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconMailFill.defaultProps = defaultIconProps;
