import { defaultIconProps, IconProps } from './IconProps';

export const IconPlayBackFill: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M17.7 12.5L7.48559 6.82536C6.81906 6.45506 5.99995 6.93703 5.99995 7.69951V17.3005C5.99995 18.063 6.81907 18.5449 7.4856 18.1746L17.7 12.5Z"
      fill={color}
    />
    <path d="M17.7 19L17.7 6" stroke={color} strokeWidth="2" strokeLinecap="round" />
  </svg>
);

IconPlayBackFill.defaultProps = defaultIconProps;
