import { defaultIconProps, IconProps } from './IconProps';

export const IconFlag: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.94478 4.26078C6.95507 4.26423 6.96494 4.26753 6.97434 4.27067L16.8932 7.57696C16.9088 7.58215 16.9245 7.58737 16.9403 7.59263C17.192 7.67641 17.4684 7.76839 17.6763 7.86954C17.8147 7.93686 18.4594 8.25051 18.4594 8.99998C18.4594 9.74945 17.8153 10.0628 17.677 10.1301C17.469 10.2312 17.192 10.3236 16.9403 10.4073C16.9245 10.4126 16.9088 10.4178 16.8932 10.423L7 13.7207V19C7 19.5523 6.55229 20 6 20C5.44772 20 5 19.5523 5 19V5.69369C5 5.68378 4.99998 5.67338 4.99997 5.66252C4.99977 5.53543 4.99949 5.34728 5.02356 5.18311C5.0531 4.98166 5.14703 4.60097 5.51911 4.33278C5.8912 4.0646 6.28206 4.09588 6.48251 4.13156C6.64587 4.16063 6.82427 4.22041 6.94478 4.26078ZM7 11.6126L14.8377 8.99998L7 6.38741V11.6126Z"
      fill={color}
    />
  </svg>
);

IconFlag.defaultProps = defaultIconProps;
