import { defaultIconProps, IconProps } from './IconProps';

export const IconAutoAnnotation: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <rect x="1.84375" y="1.84375" width="17.3125" height="16.3125" rx="3.15625" stroke={color} strokeWidth="1.6875" />
    <path d="M10.5 13.1667H14.625" stroke={color} strokeWidth="1.375" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M12.5625 5.60417C12.7448 5.42183 12.9921 5.3194 13.25 5.3194C13.3777 5.3194 13.5041 5.34455 13.6221 5.39341C13.74 5.44227 13.8472 5.51389 13.9375 5.60417C14.0278 5.69445 14.0994 5.80164 14.1483 5.9196C14.1971 6.03756 14.2223 6.16399 14.2223 6.29167C14.2223 6.41935 14.1971 6.54578 14.1483 6.66374C14.0994 6.7817 14.0278 6.88889 13.9375 6.97917L8.20833 12.7083L6.375 13.1667L6.83333 11.3333L12.5625 5.60417Z"
      stroke={color}
      strokeWidth="1.375"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconAutoAnnotation.defaultProps = defaultIconProps;
