import { IconProps } from './IconProps';

export const IconArrowUp: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M10 15.8333L10 4.16667M10 4.16667L4.16667 10M10 4.16667L15.8333 10"
      stroke={color}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconArrowUp.defaultProps = {
  size: 20,
};
