import { defaultIconProps, IconProps } from './IconProps';

export const IconTwitter: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M19.4238 4.6574C18.7616 4.93878 18.0498 5.12828 17.3028 5.2137C18.0656 4.77656 18.6513 4.08388 18.9266 3.25841C18.2133 3.66325 17.4228 3.95755 16.5813 4.11618C15.9086 3.42925 14.9478 3 13.8858 3C11.5016 3 9.74958 5.129 10.2881 7.33911C7.21983 7.19196 4.49883 5.78507 2.67708 3.64674C1.70958 5.23523 2.17533 7.31327 3.81933 8.36556C3.21483 8.3469 2.64483 8.18827 2.14758 7.9234C2.10708 9.5607 3.33333 11.0925 5.10933 11.4334C4.58958 11.5684 4.02033 11.6 3.44133 11.4937C3.91083 12.8978 5.27433 13.9192 6.89133 13.9479C5.33883 15.1129 3.38283 15.6333 1.42383 15.4122C3.05808 16.415 4.99983 17 7.08483 17C13.9413 17 17.8151 11.4579 17.5811 6.48708C18.3026 5.98821 18.9288 5.36587 19.4238 4.6574Z"
      fill={color}
    />
  </svg>
);

IconTwitter.defaultProps = defaultIconProps;
