import { defaultIconProps, IconProps } from './IconProps';

export const IconArray: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 4H15V6H19V15C19 15.9711 18.9979 16.5988 18.9357 17.0613C18.8774 17.495 18.7832 17.631 18.7071 17.7071C18.631 17.7832 18.495 17.8774 18.0613 17.9357C17.5988 17.9979 16.9711 18 16 18H15V20L16.0658 20C16.9523 20.0001 17.7161 20.0001 18.3278 19.9179C18.9833 19.8297 19.6117 19.631 20.1213 19.1213C20.631 18.6117 20.8297 17.9833 20.9179 17.3278C21.0001 16.7161 21 15.9523 21 15.0658L21 4Z"
      fill={color}
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M3 7C3 5.34315 4.34315 4 6 4H9V6H6C5.44772 6 5 6.44772 5 7V18H9V20H3V7Z" fill={color} />
  </svg>
);

IconArray.defaultProps = defaultIconProps;
