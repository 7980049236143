import { defaultIconProps, IconProps } from './IconProps';

export const IconGlobeLocate: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g clipPath="url(#clip0_492_9855)">
      <path
        d="M12.5013 2.04813C11.7121 1.80025 10.8723 1.66663 10.0013 1.66663C5.39893 1.66663 1.66797 5.39759 1.66797 9.99996C1.66797 14.6023 5.39893 18.3333 10.0013 18.3333C14.6037 18.3333 18.3346 14.6023 18.3346 9.99996C18.3346 8.57052 17.9747 7.22514 17.3406 6.0495M14.168 4.79163H14.1721M8.75135 18.2402L8.75144 16.4041C8.75144 16.3046 8.78702 16.2084 8.85174 16.1329L10.9232 13.7161C11.0935 13.5175 11.0407 13.213 10.8136 13.0832L8.43341 11.7231C8.36874 11.6861 8.31516 11.6325 8.27825 11.5678L6.72668 8.84882C6.64593 8.70731 6.49011 8.62585 6.32783 8.6403L1.72145 9.05058M17.5013 4.99996C17.5013 6.84091 15.8346 8.33329 14.168 9.99996C12.5013 8.33329 10.8346 6.84091 10.8346 4.99996C10.8346 3.15901 12.327 1.66663 14.168 1.66663C16.0089 1.66663 17.5013 3.15901 17.5013 4.99996ZM14.3763 4.79163C14.3763 4.90669 14.283 4.99996 14.168 4.99996C14.0529 4.99996 13.9596 4.90669 13.9596 4.79163C13.9596 4.67657 14.0529 4.58329 14.168 4.58329C14.283 4.58329 14.3763 4.67657 14.3763 4.79163Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_492_9855">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

IconGlobeLocate.defaultProps = defaultIconProps;
