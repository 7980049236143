import { defaultIconProps, IconProps } from './IconProps';

export const IconClock: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <circle cx="8.8" cy="8.8" r="7.8" fill={color} />
    <line x1="8.8" y1="4.8" x2="8.8" y2="8.8" fill={color} />
    <line x1="12.8" y1="10.8" x2="8.8" y2="8.8" fill={color} />
  </svg>
);

IconClock.defaultProps = defaultIconProps;
