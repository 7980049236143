import { defaultIconProps, IconProps } from './IconProps';

export const IconTicket: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M8.33268 6.66671V5.83337M8.33268 10.4167V9.58337M8.33268 14.1667V13.3334M4.33268 3.33337H15.666C16.5994 3.33337 17.0661 3.33337 17.4227 3.51503C17.7363 3.67482 17.9912 3.92979 18.151 4.24339C18.3327 4.59991 18.3327 5.06662 18.3327 6.00004V7.08337C16.7219 7.08337 15.416 8.38921 15.416 10C15.416 11.6109 16.7219 12.9167 18.3327 12.9167V14C18.3327 14.9335 18.3327 15.4002 18.151 15.7567C17.9912 16.0703 17.7363 16.3253 17.4227 16.4851C17.0661 16.6667 16.5994 16.6667 15.666 16.6667H4.33268C3.39926 16.6667 2.93255 16.6667 2.57603 16.4851C2.26243 16.3253 2.00746 16.0703 1.84767 15.7567C1.66602 15.4002 1.66602 14.9335 1.66602 14V12.9167C3.27685 12.9167 4.58268 11.6109 4.58268 10C4.58268 8.38921 3.27685 7.08337 1.66602 7.08337V6.00004C1.66602 5.06662 1.66602 4.59991 1.84767 4.24339C2.00746 3.92979 2.26243 3.67482 2.57603 3.51503C2.93255 3.33337 3.39926 3.33337 4.33268 3.33337Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconTicket.defaultProps = defaultIconProps;
