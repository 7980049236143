import { IconProps } from './IconProps';

export const IconVideoRecorder: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg" strokeWidth={1} {...rest}>
    <g id="video-recorder - ">
      <g id="Icon">
        <path
          d="M14.6667 5.95429C14.6667 5.55041 14.6667 5.34847 14.5868 5.25496C14.5175 5.17383 14.4136 5.13077 14.3072 5.13914C14.1846 5.14879 14.0418 5.29158 13.7563 5.57716L11.3334 8.00004L13.7563 10.4229C14.0418 10.7085 14.1846 10.8513 14.3072 10.8609C14.4136 10.8693 14.5175 10.8263 14.5868 10.7451C14.6667 10.6516 14.6667 10.4497 14.6667 10.0458V5.95429Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.33337 6.53337C1.33337 5.41327 1.33337 4.85322 1.55136 4.42539C1.74311 4.04907 2.04907 3.74311 2.42539 3.55136C2.85322 3.33337 3.41327 3.33337 4.53337 3.33337H8.13337C9.25348 3.33337 9.81353 3.33337 10.2414 3.55136C10.6177 3.74311 10.9236 4.04907 11.1154 4.42539C11.3334 4.85322 11.3334 5.41327 11.3334 6.53337V9.46671C11.3334 10.5868 11.3334 11.1469 11.1154 11.5747C10.9236 11.951 10.6177 12.257 10.2414 12.4487C9.81353 12.6667 9.25348 12.6667 8.13337 12.6667H4.53337C3.41327 12.6667 2.85322 12.6667 2.42539 12.4487C2.04907 12.257 1.74311 11.951 1.55136 11.5747C1.33337 11.1469 1.33337 10.5868 1.33337 9.46671V6.53337Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);
