import { baseTheme } from 'styles/utils';
import { IconProps } from './IconProps';

export const IconVideoPlay = ({ color = baseTheme.light.colors.white, size = 42, ...rest }: IconProps) => (
  <svg {...rest} width={size} height={size} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_101_11446)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 40C32.0457 40 41 31.0457 41 20C41 8.9543 32.0457 0 21 0C9.9543 0 1 8.9543 1 20C1 31.0457 9.9543 40 21 40ZM17.5 27.7942L29.5 20.866C30.1667 20.4811 30.1667 19.5189 29.5 19.134L17.5 12.2058C16.8333 11.8209 16 12.302 16 13.0718L16 26.9282C16 27.698 16.8333 28.1791 17.5 27.7942Z"
        fill={color}
      />
    </g>
    <defs>
      <filter id="filter0_d_101_11446" x="0" y="0" width="42" height="42" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_101_11446" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_101_11446" result="shape" />
      </filter>
    </defs>
  </svg>
);
