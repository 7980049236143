import { defaultIconProps, IconProps } from './IconProps';

export const IconFacebookOutline: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M12 1.33333H9.99999C9.11593 1.33333 8.26809 1.68452 7.64297 2.30964C7.01785 2.93476 6.66666 3.78261 6.66666 4.66666V6.66666H4.66666V9.33333H6.66666V14.6667H9.33332V9.33333H11.3333L12 6.66666H9.33332V4.66666C9.33332 4.48985 9.40356 4.32028 9.52859 4.19526C9.65361 4.07023 9.82318 3.99999 9.99999 3.99999H12V1.33333Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconFacebookOutline.defaultProps = defaultIconProps;
