import { defaultIconProps, IconProps } from './IconProps';

export const IconPlayPrev: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M15.8333 10H4.16663" stroke={color} strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.99996 15.8333L4.16663 10L9.99996 4.16666" stroke={color} strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

IconPlayPrev.defaultProps = defaultIconProps;
