import { defaultIconProps, IconProps } from './IconProps';

export const IconThumbsDown: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M14.1667 1.66663V10.8333M18.3333 8.16663V4.33329C18.3333 3.39987 18.3333 2.93316 18.1517 2.57664C17.9919 2.26304 17.7369 2.00807 17.4233 1.84828C17.0668 1.66663 16.6001 1.66663 15.6667 1.66663H6.76501C5.54711 1.66663 4.93816 1.66663 4.44632 1.88949C4.01284 2.08591 3.64442 2.40198 3.38437 2.80056C3.08931 3.25279 2.99672 3.85466 2.81153 5.0584L2.37563 7.89173C2.13137 9.47939 2.00925 10.2732 2.24484 10.8909C2.45162 11.433 2.84054 11.8864 3.34494 12.1732C3.91961 12.5 4.72278 12.5 6.32912 12.5H7C7.46671 12.5 7.70007 12.5 7.87833 12.5908C8.03513 12.6707 8.16261 12.7982 8.24251 12.955C8.33334 13.1332 8.33334 13.3666 8.33334 13.8333V16.2784C8.33334 17.4133 9.25333 18.3333 10.3882 18.3333C10.6589 18.3333 10.9042 18.1739 11.0141 17.9265L13.8148 11.6251C13.9421 11.3385 14.0058 11.1952 14.1065 11.0901C14.1955 10.9972 14.3048 10.9262 14.4258 10.8826C14.5627 10.8333 14.7195 10.8333 15.0332 10.8333H15.6667C16.6001 10.8333 17.0668 10.8333 17.4233 10.6516C17.7369 10.4918 17.9919 10.2369 18.1517 9.92328C18.3333 9.56676 18.3333 9.10005 18.3333 8.16663Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconThumbsDown.defaultProps = defaultIconProps;
