import { defaultIconProps, IconProps } from './IconProps';

export const IconPin: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M11.9694 3.40949C12.6225 2.97412 12.949 2.75643 13.3048 2.79166C13.6605 2.82689 13.938 3.10439 14.493 3.65938L16.3409 5.50729C16.8959 6.06228 17.1734 6.33978 17.2087 6.69555C17.2439 7.05132 17.0262 7.37785 16.5908 8.0309L15.3685 9.86435C15.0462 10.3479 14.885 10.5896 14.7428 10.8403C14.418 11.413 14.1651 12.0235 13.9898 12.6582C13.9131 12.936 13.8561 13.2209 13.7422 13.7907L13.583 14.5865C13.4652 15.1758 12.7823 15.4517 12.2882 15.1096V15.1096C9.39777 13.1085 6.89184 10.6026 4.89075 7.71209V7.71209C4.54868 7.21799 4.82452 6.53516 5.41381 6.4173L6.20961 6.25814C6.77944 6.14418 7.06436 6.08719 7.34212 6.0105C7.97678 5.83526 8.58737 5.58234 9.16005 5.25749C9.4107 5.11531 9.65246 4.95413 10.136 4.63179L11.9694 3.40949Z"
      stroke={color}
      strokeWidth="1.75"
    />
    <path d="M4.16699 15.834L7.91699 12.084" stroke={color} strokeWidth="1.75" strokeLinecap="round" />
  </svg>
);

IconPin.defaultProps = defaultIconProps;
