import { defaultIconProps, IconProps } from './IconProps';

export const IconCollaborators: import('react').FC<IconProps> = ({ size = 10, color = '#195AFF', ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M14.1673 16.5V14.8333C14.1673 13.9493 13.8161 13.1014 13.191 12.4763C12.5659 11.8512 11.718 11.5 10.834 11.5H4.16732C3.28326 11.5 2.43542 11.8512 1.8103 12.4763C1.18517 13.1014 0.833984 13.9493 0.833984 14.8333V16.5"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.49935 8.16667C9.3403 8.16667 10.8327 6.67428 10.8327 4.83333C10.8327 2.99238 9.3403 1.5 7.49935 1.5C5.6584 1.5 4.16602 2.99238 4.16602 4.83333C4.16602 6.67428 5.6584 8.16667 7.49935 8.16667Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.166 16.5001V14.8334C19.1655 14.0948 18.9196 13.3774 18.4672 12.7937C18.0147 12.2099 17.3811 11.793 16.666 11.6084"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.334 1.6084C14.051 1.79198 14.6865 2.20898 15.1403 2.79366C15.5942 3.37833 15.8405 4.09742 15.8405 4.83757C15.8405 5.57771 15.5942 6.2968 15.1403 6.88147C14.6865 7.46615 14.051 7.88315 13.334 8.06673"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconCollaborators.defaultProps = defaultIconProps;
