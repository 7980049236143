import { defaultIconProps, IconProps } from './IconProps';

export const IconSmile: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M9.66651 17.6666C9.66651 17.6666 11.6665 20.3333 14.9998 20.3333C18.3332 20.3333 20.3332 17.6666 20.3332 17.6666M18.9998 11H19.0132M9.66651 11H12.3332M28.3332 15C28.3332 22.3638 22.3636 28.3333 14.9998 28.3333C7.63604 28.3333 1.6665 22.3638 1.6665 15C1.6665 7.63616 7.63604 1.66663 14.9998 1.66663C22.3636 1.66663 28.3332 7.63616 28.3332 15ZM19.6665 11C19.6665 11.3682 19.368 11.6666 18.9998 11.6666C18.6317 11.6666 18.3332 11.3682 18.3332 11C18.3332 10.6318 18.6317 10.3333 18.9998 10.3333C19.368 10.3333 19.6665 10.6318 19.6665 11Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconSmile.defaultProps = defaultIconProps;
