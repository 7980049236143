import { defaultIconProps, IconProps } from './IconProps';

export const IconImportantSmall: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" stroke="none" fill={color} {...rest}>
    <circle cx="10" cy="15" r="1.75" />
    <path d="M10,12h0a1.13,1.13,0,0,1-1.12-1L8.25,6A1.77,1.77,0,0,1,10,4h0a1.77,1.77,0,0,1,1.75,2l-.63,5A1.13,1.13,0,0,1,10,12Z" />
  </svg>
);

IconImportantSmall.defaultProps = defaultIconProps;
