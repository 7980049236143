import { defaultIconProps, IconProps } from './IconProps';

export const IconPublicPreview: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M13.3333 4.16669L15.8333 1.66669M15.8333 1.66669L18.3333 4.16669M15.8333 1.66669V6.66669M10.4167 2.50002H6.5C5.09987 2.50002 4.3998 2.50002 3.86502 2.7725C3.39462 3.01219 3.01217 3.39464 2.77248 3.86504C2.5 4.39982 2.5 5.09989 2.5 6.50002V13.5C2.5 14.9002 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9879 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5H14.1667C14.9416 17.5 15.3291 17.5 15.647 17.4148C16.5098 17.1837 17.1836 16.5098 17.4148 15.6471C17.5 15.3292 17.5 14.9417 17.5 14.1667M8.75 7.08335C8.75 8.00383 8.00381 8.75002 7.08333 8.75002C6.16286 8.75002 5.41667 8.00383 5.41667 7.08335C5.41667 6.16288 6.16286 5.41669 7.08333 5.41669C8.00381 5.41669 8.75 6.16288 8.75 7.08335ZM12.4917 9.93181L5.44262 16.3401C5.04614 16.7005 4.84789 16.8807 4.83036 17.0368C4.81516 17.1722 4.86704 17.3064 4.96932 17.3963C5.08732 17.5 5.35523 17.5 5.89107 17.5H13.7133C14.9126 17.5 15.5123 17.5 15.9833 17.2985C16.5745 17.0456 17.0456 16.5746 17.2985 15.9833C17.5 15.5123 17.5 14.9126 17.5 13.7133C17.5 13.3098 17.5 13.108 17.4559 12.9201C17.4005 12.684 17.2941 12.4628 17.1444 12.272C17.0252 12.1202 16.8677 11.9941 16.5526 11.7421L14.2215 9.87724C13.9062 9.62494 13.7485 9.4988 13.5748 9.45428C13.4218 9.41504 13.2607 9.42012 13.1104 9.46893C12.94 9.5243 12.7905 9.66014 12.4917 9.93181Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconPublicPreview.defaultProps = defaultIconProps;
