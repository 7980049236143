import { defaultIconProps, IconProps } from './IconProps';

export const IconGrid: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M1.5 1.25H6.16667C6.30474 1.25 6.41667 1.36193 6.41667 1.5V6.16667C6.41667 6.30474 6.30474 6.41667 6.16667 6.41667H1.5C1.36193 6.41667 1.25 6.30474 1.25 6.16667V1.5C1.25 1.36193 1.36193 1.25 1.5 1.25Z"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M1.5 9.58337H6.16667C6.30474 9.58337 6.41667 9.6953 6.41667 9.83337V14.5C6.41667 14.6381 6.30474 14.75 6.16667 14.75H1.5C1.36193 14.75 1.25 14.6381 1.25 14.5V9.83337C1.25 9.6953 1.36193 9.58337 1.5 9.58337Z"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M9.83325 1.25H14.4999C14.638 1.25 14.7499 1.36193 14.7499 1.5V6.16667C14.7499 6.30474 14.638 6.41667 14.4999 6.41667H9.83325C9.69518 6.41667 9.58325 6.30474 9.58325 6.16667V1.5C9.58325 1.36193 9.69518 1.25 9.83325 1.25Z"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M9.83325 9.58337H14.4999C14.638 9.58337 14.7499 9.6953 14.7499 9.83337V14.5C14.7499 14.6381 14.638 14.75 14.4999 14.75H9.83325C9.69518 14.75 9.58325 14.6381 9.58325 14.5V9.83337C9.58325 9.6953 9.69518 9.58337 9.83325 9.58337Z"
      stroke={color}
      strokeWidth="1.5"
    />
  </svg>
);

IconGrid.defaultProps = defaultIconProps;
