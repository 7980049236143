import { useId } from 'react-aria';
import { defaultIconProps, IconProps } from './IconProps';

export const IconBellV2: import('react').FC<IconProps> = ({ size, ...rest }) => {
  const filterId = useId();
  const radialId = useId();

  return (
    <svg width={size} height={size} viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g filter={`url(#filter0_${filterId})`}>
        <circle cx="56" cy="56" r="40" fill="#F2F6FE" />
      </g>
      <g filter={`url(#filter1_${filterId})`}>
        <circle cx="55.9987" cy="56" r="26.6667" fill="#E6EDFD" />
      </g>
      <g filter={`url(#filter2_${filterId})`}>
        <path
          d="M38.3419 39.0548C35.7381 39.4144 33.9188 41.8168 34.2784 44.4206L38.0536 71.7547C38.4132 74.3586 40.8156 76.1779 43.4194 75.8182L70.7535 72.0431C73.3573 71.6834 75.1766 69.2811 74.817 66.6772L71.0418 39.3431C70.6822 36.7393 68.2798 34.92 65.676 35.2796L38.3419 39.0548Z"
          fill={`url(#${radialId})`}
          stroke="#6695F3"
          strokeWidth="1.02854"
        />
      </g>
      <g filter={`url(#filter3_${filterId})`}>
        <path
          d="M60.9466 53.1152C60.7022 51.4559 59.8086 49.9617 58.4625 48.9612C57.1164 47.9608 55.428 47.536 53.7687 47.7805C52.1094 48.0249 50.6151 48.9185 49.6147 50.2646C48.6142 51.6107 48.1895 53.2991 48.4339 54.9584C48.9086 58.1807 48.4207 60.5066 47.7277 62.0996C47.1431 63.4433 46.8508 64.1152 46.8901 64.2848C46.9337 64.4726 46.9846 64.5392 47.1545 64.6302C47.308 64.7125 47.9317 64.6206 49.1791 64.4368L62.9663 62.4059C64.2137 62.2221 64.8374 62.1303 64.9606 62.0072C65.0971 61.871 65.1267 61.7926 65.1142 61.6002C65.1029 61.4265 64.6293 60.8674 63.6821 59.7494C62.5591 58.4239 61.4213 56.3374 60.9466 53.1152Z"
          fill="white"
        />
      </g>
      <g filter={`url(#filter4_${filterId})`}>
        <path
          d="M59.3579 64.0031C59.6124 65.7307 58.4182 67.3376 56.6905 67.5921C54.9629 67.8465 53.3561 66.6523 53.1016 64.9247L59.3579 64.0031Z"
          fill="white"
        />
      </g>
      <g filter={`url(#filter5_${filterId})`}>
        <circle cx="53.2166" cy="45.66" r="1.55641" fill="white" />
      </g>
      <defs>
        <filter
          id={`filter0_${filterId}`}
          x="6.66667"
          y="6.66667"
          width="98.6667"
          height="98.6667"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="4.66667" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.6 0 0 0 0 0.721569 0 0 0 0 0.968627 0 0 0 0.39 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_617_22502" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_617_22502" result="shape" />
        </filter>
        <filter
          id={`filter1_${filterId}`}
          x="-0.00130272"
          y="4.00543e-05"
          width="111.999"
          height="112"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="14.6667" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0980392 0 0 0 0 0.352941 0 0 0 0 1 0 0 0 0.3 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_617_22502" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_617_22502" result="shape" />
        </filter>
        <filter
          id={`filter2_${filterId}`}
          x="18.8607"
          y="21.9845"
          width="71.3724"
          height="71.3741"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="2.12258" />
          <feGaussianBlur stdDeviation="7.42904" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.188235 0 0 0 0 0.415686 0 0 0 0 1 0 0 0 0.3 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_617_22502" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="2.05708" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.243137 0 0 0 0 0.737255 0 0 0 1 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_617_22502" result="effect2_dropShadow_617_22502" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_617_22502" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="3.05476" dy="4.30958" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect3_innerShadow_617_22502" />
        </filter>
        <filter
          id={`filter3_${filterId}`}
          x="42.4921"
          y="43.3183"
          width="27.0196"
          height="25.7368"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="2.19729" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.6 0 0 0 0 0.721569 0 0 0 0 0.968627 0 0 0 0.9 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_617_22502" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_617_22502" result="shape" />
        </filter>
        <filter
          id={`filter4_${filterId}`}
          x="48.707"
          y="59.6085"
          width="15.0782"
          height="12.4123"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="2.19729" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.6 0 0 0 0 0.721569 0 0 0 0 0.968627 0 0 0 0.9 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_617_22502" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_617_22502" result="shape" />
        </filter>
        <filter
          id={`filter5_${filterId}`}
          x="47.2656"
          y="39.7091"
          width="11.9024"
          height="11.9019"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="2.19729" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.6 0 0 0 0 0.721569 0 0 0 0 0.968627 0 0 0 0.9 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_617_22502" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_617_22502" result="shape" />
        </filter>
        <radialGradient
          id={radialId}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(46.2576 44.7012) rotate(43.3623) scale(40.2381 52.0637)"
        >
          <stop offset="0.221581" stopColor="#306AFF" />
          <stop offset="0.729167" stopColor="#003EBC" />
        </radialGradient>
      </defs>
    </svg>
  );
};

IconBellV2.defaultProps = defaultIconProps;
