import { defaultIconProps, IconProps } from './IconProps';

export const IconExpandDown: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7071 9.7072L17.2929 8.29299L12 13.5859L6.70709 8.29299L5.29288 9.7072L12 16.4143L18.7071 9.7072Z"
      fill={color}
    />
  </svg>
);

IconExpandDown.defaultProps = defaultIconProps;
