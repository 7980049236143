import { defaultIconProps, IconProps } from './IconProps';

export const IconFolderOpenv1: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M4.99996 14.1667L6.66663 10H18.3333L15.8333 16.6667C15.6701 16.9558 15.4242 17.1895 15.1273 17.338C14.8304 17.4865 14.4958 17.5429 14.1666 17.5H3.33329C2.89127 17.5 2.46734 17.3244 2.15478 17.0118C1.84222 16.6993 1.66663 16.2754 1.66663 15.8333V4.16667C1.66663 3.72464 1.84222 3.30072 2.15478 2.98816C2.46734 2.67559 2.89127 2.5 3.33329 2.5H7.49996L9.16663 5H15C15.442 5 15.8659 5.17559 16.1785 5.48816C16.491 5.80072 16.6666 6.22464 16.6666 6.66667V10"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconFolderOpenv1.defaultProps = defaultIconProps;
