import { defaultIconProps, IconProps } from './IconProps';

export const IconAccount: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.6311 16.3732C8.1938 15.4738 10.0805 15 12 15C13.9195 15 15.8062 15.4738 17.3689 16.3732C18.931 17.2722 20.1188 18.5702 20.6691 20.1106C20.8549 20.6307 20.5839 21.203 20.0638 21.3888C19.5437 21.5746 18.9715 21.3036 18.7857 20.7835C18.4244 19.7723 17.6035 18.8157 16.3713 18.1066C15.1397 17.3978 13.6027 17 12 17C10.3973 17 8.8603 17.3978 7.62869 18.1066C6.3965 18.8157 5.57555 19.7723 5.21429 20.7835C5.02848 21.3036 4.45623 21.5746 3.93614 21.3888C3.41605 21.203 3.14507 20.6307 3.33088 20.1106C3.88121 18.5702 5.06898 17.2721 6.6311 16.3732Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5C10.3431 5 9 6.34315 9 8C9 9.65685 10.3431 11 12 11C13.6569 11 15 9.65685 15 8C15 6.34315 13.6569 5 12 5ZM7 8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8C17 10.7614 14.7614 13 12 13C9.23858 13 7 10.7614 7 8Z"
      fill={color}
    />
  </svg>
);

IconAccount.defaultProps = defaultIconProps;
