import { defaultIconProps, IconProps } from './IconProps';

export const IconCalendar: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 21 20" fill="none" {...rest}>
    <rect x="2.92383" y="4.16602" width="15" height="12.5" rx="2" stroke={color} strokeWidth="1.75" />
    <path
      d="M2.92383 7.49935C2.92383 6.25319 2.92383 5.63012 3.19178 5.16602C3.36731 4.86198 3.61979 4.6095 3.92383 4.43397C4.38793 4.16602 5.01101 4.16602 6.25716 4.16602H14.5905C15.8366 4.16602 16.4597 4.16602 16.9238 4.43397C17.2279 4.6095 17.4803 4.86198 17.6559 5.16602C17.9238 5.63012 17.9238 6.25319 17.9238 7.49935V7.49935H2.92383V7.49935Z"
      fill={color}
    />
    <path d="M6.25684 1.66602L6.25684 4.16602" stroke={color} strokeWidth="2" strokeLinecap="round" />
    <path d="M14.5908 1.66602L14.5908 4.16602" stroke={color} strokeWidth="2" strokeLinecap="round" />
  </svg>
);

IconCalendar.defaultProps = defaultIconProps;
