import { defaultIconProps, IconProps } from './IconProps';

export const IconNoStarred: React.FC<IconProps> = ({ size, ...rest }) => (
  <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <circle cx="84" cy="84" r="80" fill="#F7FAFE" />
    <g filter="url(#filter0_d_4_743)">
      <circle cx="84" cy="84" r="60" fill="#F2F6FE" />
    </g>
    <g filter="url(#filter1_d_4_743)">
      <circle cx="84" cy="84" r="40" fill="#E6EDFD" />
    </g>
    <ellipse cx="83.5052" cy="125" rx="14.5" ry="4" fill="#CCDCFB" />
    <g filter="url(#filter2_ddi_4_743)">
      <path
        d="M60.8886 61.5126C57.5426 61.9748 55.2048 65.0618 55.6669 68.4078L60.518 103.532C60.9801 106.878 64.0672 109.216 67.4132 108.754L102.538 103.903C105.884 103.441 108.221 100.354 107.759 97.0077L102.908 61.8832C102.446 58.5372 99.359 56.1994 96.0131 56.6615L60.8886 61.5126Z"
        fill="url(#paint0_radial_4_743)"
        stroke="#6695F3"
        strokeWidth="1.32168"
      />
    </g>
    <g filter="url(#filter3_d_4_743)">
      <path
        d="M79.3167 71.7654C79.7106 70.5901 81.2791 70.3727 81.9779 71.3966L85.2528 76.1953C85.5295 76.6008 85.9871 76.8452 86.4779 76.8497L92.2875 76.9034C93.527 76.9149 94.2184 78.3395 93.4606 79.3204L89.9087 83.918C89.6086 84.3064 89.5176 84.8171 89.665 85.2853L91.4091 90.8271C91.7813 92.0095 90.6401 93.1073 89.473 92.6897L84.0028 90.7324C83.5407 90.567 83.0268 90.6383 82.6271 90.9231L77.8955 94.2944C76.886 95.0137 75.4892 94.2676 75.5257 93.0286L75.6969 87.2214C75.7114 86.7307 75.4848 86.264 75.0904 85.9719L70.422 82.5137C69.4259 81.7758 69.7039 80.2169 70.8935 79.8687L76.4694 78.237C76.9405 78.0991 77.3144 77.7394 77.4704 77.274L79.3167 71.7654Z"
        fill="white"
      />
    </g>
    <defs>
      <filter id="filter0_d_4_743" x="10" y="10" width="148" height="148" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="7" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.6 0 0 0 0 0.721569 0 0 0 0 0.968627 0 0 0 0.39 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_743" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_743" result="shape" />
      </filter>
      <filter id="filter1_d_4_743" x="0" y="0" width="168" height="168" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="22" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0980392 0 0 0 0 0.352941 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_743" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_743" result="shape" />
      </filter>
      <filter
        id="filter2_ddi_4_743"
        x="35.855"
        y="39.5771"
        width="91.7162"
        height="91.7163"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2.72753" />
        <feGaussianBlur stdDeviation="9.54637" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.188235 0 0 0 0 0.415686 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_743" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2.64336" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.243137 0 0 0 0 0.737255 0 0 0 1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_4_743" result="effect2_dropShadow_4_743" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_743" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="3.92539" dy="5.53784" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect3_innerShadow_4_743" />
      </filter>
      <filter
        id="filter3_d_4_743"
        x="64.1671"
        y="65.0947"
        width="35.2562"
        height="35.1281"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="2.82353" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.6 0 0 0 0 0.721569 0 0 0 0 0.968627 0 0 0 0.9 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_743" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_743" result="shape" />
      </filter>
      <radialGradient
        id="paint0_radial_4_743"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(71.0603 68.7684) rotate(43.3623) scale(51.7062 66.9022)"
      >
        <stop offset="0.221581" stopColor="#306AFF" />
        <stop offset="0.729167" stopColor="#003EBC" />
      </radialGradient>
    </defs>
  </svg>
);

IconNoStarred.defaultProps = defaultIconProps;

<svg xmlns="http://www.w3.org/2000/svg" width="168" height="168" viewBox="0 0 168 168" fill="none" />;
