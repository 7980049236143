import { defaultIconProps } from './IconProps';

interface IconCollapseProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  size: number;
  color?: string;
}

export const IconCollapse: import('react').FC<IconCollapseProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.29291 17.2928L4.70712 18.707L8.00001 15.4141L8.00001 21.9999C8.00001 22.5522 8.44773 22.9999 9.00001 22.9999C9.5523 22.9999 10 22.5522 10 21.9999L10 15.4141L13.2929 18.707L14.7071 17.2928L9.00001 11.5857L3.29291 17.2928Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.29291 6.7072L10.7071 5.29299L14 8.58588L14 2.00009C14 1.44781 14.4477 1.00009 15 1.00009C15.5523 1.00009 16 1.44781 16 2.00009L16 8.58588L19.2929 5.29299L20.7071 6.7072L15 12.4143L9.29291 6.7072Z"
      fill={color}
    />
  </svg>
);

IconCollapse.defaultProps = defaultIconProps;
