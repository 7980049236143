import { defaultIconProps, IconProps } from './IconProps';

interface IconUndoProps extends IconProps {
  size: number;
  strokeWidth: number;
  color?: string;
}

export const IconUndo: import('react').FC<IconUndoProps> = ({ size, color, strokeWidth, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
    <path
      stroke={color}
      strokeWidth={strokeWidth}
      d="m5.26367,2.0293l-3.33203,3.4707l3.33203,3.4707l0.72071,-0.6914l-2.1875,-2.2793l4.70117,0c1.93874,0 3.5,1.56126 3.5,3.5l0,4.5l1,0l0,-4.5c0,-2.47926 -2.02075,-4.5 -4.5,-4.5l-4.70117,0l2.1875,-2.2793l-0.72071,-0.6914z"
    />
  </svg>
);

IconUndo.defaultProps = {
  ...defaultIconProps,
  strokeWidth: 3,
};
