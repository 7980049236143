import { useId } from 'react-aria';
import { defaultIconProps, IconProps } from './IconProps';

export const IconInfoFill: import('react').FC<IconProps> = ({ size, color, strokeWidth, ...rest }) => {
  const randomId = useId();

  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath={`url(#icon-info-fill-${randomId})`}>
        <path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill={color} />
        <path d="M8 11V8.5" stroke="white" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
        <circle cx="8" cy="6" r="1" fill="white" />
      </g>
      <defs>
        <clipPath id={`icon-info-fill-${randomId}`}>
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

IconInfoFill.defaultProps = defaultIconProps;
