import { defaultIconProps, IconProps } from './IconProps';

export const IconRefresh: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
  >
    <path d="M19.69,14.82A8.25,8.25,0,1,1,18.51,7" />
    <path d="M14.18,8.52l6.06,1.35a.5.5,0,0,0,.61-.46l.36-6.19a.5.5,0,0,0-.8-.43L14,7.63A.5.5,0,0,0,14.18,8.52Z" stroke="none" fill={color} />
  </svg>
);

IconRefresh.defaultProps = defaultIconProps;
