import { defaultIconProps, IconProps } from './IconProps';

export const IconScan: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 8.86252C21 8.90771 21 8.95356 21 9.0001L21 10.0001L19 10.0001L19 9.0001C19 8.00574 18.9914 7.70474 18.9318 7.48246C18.7469 6.79227 18.2078 6.25318 17.5176 6.06824C17.2954 6.00869 16.9943 6.0001 16 6.0001L16 4.0001C16.0465 4.0001 16.0924 4.00007 16.1376 4.00005C16.933 3.99962 17.5236 3.9993 18.0353 4.13639C19.4156 4.50626 20.4938 5.58445 20.8637 6.96482C21.0008 7.47647 21.0005 8.06713 21 8.86252Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9318 16.5176C18.9914 16.2954 19 15.9944 19 15L19 14L21 14L21 15C21 15.0465 21 15.0924 21 15.1376C21.0005 15.933 21.0008 16.5236 20.8637 17.0353C20.4938 18.4156 19.4156 19.4938 18.0353 19.8637C17.5236 20.0008 16.933 20.0005 16.1376 20C16.0924 20 16.0465 20 16 20L16 18C16.9943 18 17.2954 17.9914 17.5176 17.9319C18.2078 17.7469 18.7469 17.2078 18.9318 16.5176Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 18L10 18L10 20L8.95396 20C8.0585 20 7.31952 20 6.71983 19.9457C6.09615 19.8892 5.52564 19.7676 5 19.4641C4.39192 19.113 3.88697 18.6081 3.5359 18C3.23242 17.4744 3.11085 16.9039 3.05432 16.2802C2.99997 15.6805 2.99998 14.9415 3 14.046L3 14L5 14C5 14.953 5.00096 15.6009 5.04616 16.0996C5.09011 16.5846 5.1695 16.8295 5.26795 17C5.44349 17.304 5.69596 17.5565 6 17.7321C6.17051 17.8305 6.41539 17.9099 6.90036 17.9538C7.39908 17.999 8.04698 18 9 18Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 4L10 4L10 6L9 6C8.04698 6 7.39908 6.00096 6.90036 6.04616C6.41539 6.09011 6.17051 6.16951 6 6.26795C5.69596 6.44349 5.44349 6.69596 5.26795 7C5.1695 7.17051 5.09011 7.41539 5.04616 7.90036C5.00096 8.39908 5 9.04698 5 10L3 10L3 9.95396C2.99998 9.0585 2.99997 8.31952 3.05432 7.71983C3.11085 7.09615 3.23242 6.52564 3.5359 6C3.88697 5.39192 4.39192 4.88697 5 4.5359C5.52564 4.23242 6.09615 4.11085 6.71983 4.05432C7.31952 3.99997 8.05849 3.99998 8.95396 4C8.96926 4 8.98461 4 9 4Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2C10.5523 2 11 2.44772 11 3L11 21C11 21.5523 10.5523 22 10 22C9.44771 22 9 21.5523 9 21L9 3C9 2.44772 9.44772 2 10 2Z"
      fill={color}
    />
  </svg>
);

IconScan.defaultProps = defaultIconProps;
