import { defaultIconProps, IconProps } from './IconProps';

export const IconCursor: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M17.088 8.97964C17.6021 8.77972 17.8591 8.67975 17.9313 8.53842C17.9938 8.41597 17.9919 8.27055 17.9262 8.14979C17.8503 8.01039 17.5908 7.91721 17.0716 7.73084L3.83021 2.97751C3.40547 2.82503 3.19309 2.7488 3.05425 2.79688C2.93354 2.83868 2.83868 2.93354 2.79688 3.05425C2.7488 3.19309 2.82503 3.40547 2.97751 3.83021L7.7308 17.0716C7.91717 17.5908 8.01035 17.8504 8.14975 17.9262C8.27051 17.992 8.41592 17.9939 8.53837 17.9313C8.67971 17.8592 8.77968 17.6021 8.9796 17.088L11.1436 11.5234C11.1828 11.4227 11.2024 11.3723 11.2326 11.3299C11.2594 11.2923 11.2923 11.2595 11.3299 11.2327C11.3723 11.2024 11.4226 11.1828 11.5233 11.1437L17.088 8.97964Z"
      stroke={color}
      strokeWidth="1.66"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconCursor.defaultProps = defaultIconProps;
