import { defaultIconProps, IconProps } from './IconProps';

export const IconEditSquare: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.455 5.41575C21.6473 5.70687 21.6152 6.10248 21.3589 6.35876L12.1666 15.5511C12.0723 15.6454 11.9547 15.7128 11.8258 15.7465L7.99734 16.7465C7.87247 16.7791 7.74376 16.7784 7.62283 16.7476C7.49427 16.7149 7.37449 16.6482 7.27748 16.5511C7.0892 16.3629 7.01486 16.0889 7.08215 15.8313L8.08215 12.0028C8.11162 11.89 8.16691 11.7786 8.2434 11.6912L17.4699 2.46967C17.5506 2.38891 17.6479 2.32846 17.7538 2.29163C17.8323 2.26432 17.9155 2.25 18.0002 2.25C18.1991 2.25 18.3899 2.32902 18.5305 2.46967L21.3589 5.2981C21.3956 5.33471 21.4276 5.37416 21.455 5.41575ZM19.768 5.82843L18.0002 4.06066L9.48202 12.5788L8.85703 14.9716L11.2498 14.3466L19.768 5.82843Z"
      fill={color}
    />
    <path
      d="M19.6415 17.1603C19.9149 14.8227 20.0019 12.4688 19.9025 10.1208C19.8978 10.0084 19.94 9.89898 20.0196 9.81942L21.0029 8.83609C21.1238 8.71519 21.3304 8.79194 21.3417 8.96254C21.5266 11.7522 21.4565 14.5545 21.1314 17.3346C20.8948 19.3571 19.2705 20.9421 17.2584 21.167C13.7919 21.5544 10.2085 21.5544 6.74195 21.167C4.7299 20.9421 3.10556 19.3571 2.86901 17.3346C2.45447 13.7903 2.45447 10.2097 2.86901 6.66543C3.10556 4.6429 4.7299 3.05789 6.74195 2.83301C9.3717 2.5391 12.0686 2.46815 14.7308 2.62016C14.9024 2.62996 14.9806 2.83757 14.8591 2.95909L13.8666 3.95165C13.7879 4.03034 13.6799 4.07261 13.5687 4.06885C11.3423 3.99376 9.10074 4.07872 6.90856 4.32373C5.57846 4.47239 4.51297 5.522 4.35885 6.83968C3.95786 10.2682 3.95786 13.7318 4.35885 17.1603C4.51297 18.478 5.57845 19.5276 6.90856 19.6763C10.2644 20.0513 13.736 20.0513 17.0918 19.6763C18.4219 19.5276 19.4874 18.478 19.6415 17.1603Z"
      fill={color}
    />
  </svg>
);

IconEditSquare.defaultProps = defaultIconProps;
