import { css, cx } from '@linaria/core';
import { baseTheme, baseUnits, breakpointConfig } from 'styles/utils';
export const smallTitle = "tyles__typography_ts-smallTitle-sxjiwsi";
export const bigTitle = "tyles__typography_ts-bigTitle-b1muvu8a";
export const titles = "tyles__typography_ts-titles-trobypy";
export const titlesAdaptive = "tyles__typography_ts-titlesAdaptive-t1avs9t3";
export const sectionTitle = "tyles__typography_ts-sectionTitle-s1old27y";
export const actionModalTitle = "tyles__typography_ts-actionModalTitle-aas7n4z";
export const actionModalDescription = "tyles__typography_ts-actionModalDescription-a1nurjjs";
export const tinyLinkButton = "tyles__typography_ts-tinyLinkButton-t1b5ormu";
export const bigH1 = "tyles__typography_ts-bigH1-bhyi79h";

// deprecated
export const regularText = "tyles__typography_ts-regularText-r4kmfzb";

// Standard styles:
// TODO: consider deleting the styles previously defined
export const italic = "tyles__typography_ts-italic-i5opr1h";
export const fontRegular = "tyles__typography_ts-fontRegular-f1dtku18";
export const fontMedium = "tyles__typography_ts-fontMedium-fme6mjm";
export const fontBold = "tyles__typography_ts-fontBold-f1yty5cj";
export const link = "tyles__typography_ts-link-l8plbaq";
export const textSmall = "tyles__typography_ts-textSmall-t1cd47hr";
export const textSmallLink = cx(textSmall, link);
export const textSmallBold = cx(textSmall, fontBold);
export const textSmallMedium = cx(textSmall, fontMedium);
export const textRegularLight = "tyles__typography_ts-textRegularLight-tw5jo8";
export const textRegular = cx(textRegularLight, fontBold);
export const textRegularLink = cx(textRegular, link);
export const textRegularLinkLight = cx(textRegularLight, link);
export const textTitle = cx("tyles__typography_ts-textTitle-t196yqao", fontBold);
export const textBig = cx("tyles__typography_ts-textBig-t1y5bxo9", fontBold);
export const textCaption = "tyles__typography_ts-textCaption-t11sqpt";
export const inputInfo = "tyles__typography_ts-inputInfo-ilvs2p2";

require("./typography.linaria.module.css!=!../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./typography.ts");