/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useCallback } from 'react';
import Analytics, { AnalyticsInstance, AnalyticsPlugin } from 'analytics';
import { Router, useRouter } from 'next/router';
import { datadogRum } from '@datadog/browser-rum';
import getConfig from 'next/config';
import { AnalyticsProvider, useAnalytics } from 'use-analytics';
import googleTagManager from '@analytics/google-tag-manager';
import { ga4Plugin } from 'utils/analytics/ga4plugin';
import {
  init as amplitudeInit,
  track as amplitudeTrack,
  setUserId as amplitudeSetUserId,
  identify as amplitudeIdentify,
  Identify,
  reset as amplitudeReset,
} from '@amplitude/analytics-browser';

const { publicRuntimeConfig } = getConfig();

const isOfflineMode = publicRuntimeConfig.OFFLINE_MODE as boolean;

if (!isOfflineMode) {
  amplitudeInit(publicRuntimeConfig.NEXT_PUBLIC_AMPLITUDE_API_KEY, undefined, {
    defaultTracking: { pageViews: { trackHistoryChanges: 'all' } },
  });

  datadogRum.init({
    applicationId: publicRuntimeConfig.NEXT_PUBLIC_DATA_DOG_APP_ID,
    clientToken: publicRuntimeConfig.NEXT_DATA_DOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'clarifaiweb',
    env: publicRuntimeConfig.CF_ENV,
    version: publicRuntimeConfig.VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackFrustrations: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
}

export const analytics = Analytics({
  app: 'clarifai-web',
  // https://getanalytics.io/debugging/
  debug: publicRuntimeConfig.CF_ENV !== 'production',
  plugins: !isOfflineMode
    ? ([
        !!publicRuntimeConfig.NEXT_PUBLIC_GOOGLE_ANALYTICS &&
          ga4Plugin({
            trackingId: publicRuntimeConfig.NEXT_PUBLIC_GOOGLE_ANALYTICS,
          }),
        !!publicRuntimeConfig.NEXT_PUBLIC_GOOGLE_TAG_MANAGER &&
          googleTagManager({
            containerId: publicRuntimeConfig.NEXT_PUBLIC_GOOGLE_TAG_MANAGER,
          }),
      ].filter(Boolean) as AnalyticsPlugin[])
    : [],
});

export interface AnalyticsCollectionProviderProps {
  children: React.ReactNode;
  trackEvent?: { eventName: string; payload: any };
}

export type trackEventContext = 'onboadring' | 'newappwizard' | 'regular';

export type trackEventPayload = {
  context?: trackEventContext;
};

export const useDataCollection = (
  analyticsInstace?: AnalyticsInstance,
): {
  track: (eventName: string, payload?: any, options?: any, callback?: (...params: any[]) => any) => void;
  identify: (userId: string, traits?: any, options?: any, callback?: (...params: any[]) => any) => void;
  reset: (callback?: (...params: any[]) => any) => void;
} => {
  const router = useRouter();
  const { asPath } = router;
  const { identify: analyticsIdentify, reset: analyticsReset, track: analyticsTrack } = analyticsInstace || useAnalytics();

  const track = useCallback(
    (eventName: string, payload?: any & trackEventPayload, options?: any, callback?: (...params: any[]) => any) => {
      if (isOfflineMode) {
        return;
      }

      const extraPayload = {
        referrer: document.referrer,
        page_location: document.location.href.split('?')[0],
        page_domain: document.location.origin,
        page_url: document.location.origin + document.location.pathname,
        page_path: asPath,
      };
      if (document.referrer) {
        extraPayload.referrer = document.referrer;
      }
      analyticsTrack(eventName, payload, options, callback);
      amplitudeTrack(eventName, {
        ...payload,
        ...extraPayload,
      });
      datadogRum.addAction(eventName, payload);
    },
    [analyticsTrack],
  );

  const identify = useCallback(
    (userId: string, traits?: any) => {
      if (isOfflineMode) {
        return;
      }

      analyticsIdentify(userId);
      amplitudeSetUserId(userId);
      amplitudeIdentify(new Identify().set('id', userId).set('email', traits.email));
      datadogRum.setUser({ id: userId, email: traits.email });
    },
    [analyticsIdentify],
  );

  const reset = useCallback(
    (callback?: (...params: any[]) => any) => {
      if (isOfflineMode) {
        return;
      }

      analyticsReset(callback);
      datadogRum.clearUser();
      amplitudeReset();
      datadogRum.stopSessionReplayRecording();
    },
    [analyticsReset],
  );

  return {
    track,
    identify,
    reset,
  };
};

function trackAmplitudeCustomPageView() {
  const extraPayload = {
    referrer: document.referrer,
    page_location: document.location.href.split('?')[0],
    page_domain: document.location.origin,
    page_url: document.location.origin + document.location.pathname,
    page_path: document.location.pathname,
  };
  if (document.referrer) {
    extraPayload.referrer = document.referrer;
  }
  amplitudeTrack('Page Viewed', {
    ...extraPayload,
  });
}

export const AnalyticsCollectionProvider: React.FC<AnalyticsCollectionProviderProps> = ({ trackEvent, children }) => {
  const { track } = useDataCollection(analytics);

  useEffect(() => {
    if (trackEvent && !isOfflineMode) {
      const payload = trackEvent.payload || {};
      track(trackEvent.eventName, payload);
    }
    analytics.page();
    trackAmplitudeCustomPageView();

    function handleRouteChange() {
      // We need to wrap it in a rAF to ensure the correct data is sent to Segment
      // https://github.com/zeit/next.js/issues/6025
      requestAnimationFrame(() => {
        analytics.page();
        trackAmplitudeCustomPageView();
      });
    }

    Router.events.on('routeChangeComplete', handleRouteChange);
    return () => Router.events.off('routeChangeComplete', handleRouteChange);
  }, [trackEvent]);

  return <AnalyticsProvider instance={analytics}>{children}</AnalyticsProvider>;
};
