import { defaultIconProps, IconProps } from './IconProps';

export const IconLinkOpen: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 2H11V4H5.41421L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L4 5.41421V11H2V2Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0996 5.04616C16.6009 5.00096 15.953 5 15 5C14.4477 5 14 4.55229 14 4C14 3.44772 14.4477 3 15 3L15.046 3C15.9415 2.99998 16.6805 2.99997 17.2802 3.05432C17.9039 3.11085 18.4744 3.23242 19 3.5359C19.6081 3.88697 20.113 4.39192 20.4641 5C20.7676 5.52564 20.8892 6.09615 20.9457 6.71983C21 7.31953 21 8.05851 21 8.95399V14.0705C21 15.4247 21.0001 16.5413 20.8813 17.4251C20.7565 18.3529 20.4845 19.1723 19.8284 19.8284C19.1723 20.4845 18.3529 20.7565 17.4251 20.8813C16.5413 21.0001 15.4247 21 14.0705 21H8.95399C8.05851 21 7.31953 21 6.71983 20.9457C6.09615 20.8892 5.52564 20.7676 5 20.4641C4.39192 20.113 3.88697 19.6081 3.5359 19C3.23242 18.4744 3.11085 17.9039 3.05432 17.2802C2.99997 16.6805 2.99998 15.9415 3 15.046L3 15C3 14.4477 3.44772 14 4 14C4.55229 14 5 14.4477 5 15C5 15.953 5.00096 16.6009 5.04616 17.0996C5.09011 17.5846 5.16951 17.8295 5.26795 18C5.44349 18.304 5.69596 18.5565 6 18.7321C6.17051 18.8305 6.41539 18.9099 6.90036 18.9538C7.39908 18.999 8.04698 19 9 19H14C15.4425 19 16.4237 18.9979 17.1586 18.8991C17.8646 18.8042 18.1916 18.6368 18.4142 18.4142C18.6368 18.1916 18.8042 17.8646 18.8991 17.1586C18.9979 16.4237 19 15.4425 19 14V9C19 8.04698 18.999 7.39908 18.9538 6.90036C18.9099 6.41539 18.8305 6.17051 18.7321 6C18.5565 5.69596 18.304 5.44349 18 5.26795C17.8295 5.16951 17.5846 5.09011 17.0996 5.04616Z"
      fill={color}
    />
  </svg>
);

IconLinkOpen.defaultProps = defaultIconProps;
