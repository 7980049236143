import { defaultIconProps, IconProps } from './IconProps';

export const IconSecurity: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1818 2.33058L19.0961 4.86531C20.325 5.39196 21.057 6.66822 20.8912 7.99485L20.2782 12.8987C20.0266 14.9115 19.0198 16.7536 17.4615 18.0522L13.9206 21.003C12.808 21.9301 11.192 21.9301 10.0794 21.003L6.53852 18.0522C4.9802 16.7536 3.97337 14.9115 3.72177 12.8987L3.10878 7.99485C2.94295 6.66822 3.67501 5.39196 4.90386 4.86531C4.90386 4.86531 4.90386 4.86531 4.90386 4.86531L10.8182 2.33058C11.5729 2.00716 12.4271 2.00716 13.1818 2.33058ZM5.6917 6.7036C5.28208 6.87915 5.03806 7.30457 5.09334 7.74678L5.70632 12.6507C5.89503 14.1603 6.65015 15.5418 7.81889 16.5158L11.3598 19.4666C11.7307 19.7756 12.2693 19.7756 12.6402 19.4666L16.1811 16.5158C17.3498 15.5418 18.105 14.1603 18.2937 12.6507L18.9067 7.74678C18.9619 7.30457 18.7179 6.87915 18.3083 6.7036L12.3939 4.16887C12.1424 4.06106 11.8576 4.06106 11.6061 4.16887L5.6917 6.7036Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5547 8.16793C17.0142 8.47429 17.1384 9.09516 16.8321 9.55468L13.1704 15.0472C12.6453 15.8348 11.531 15.9452 10.8616 15.2758L8.2929 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929C8.68342 10.9024 9.31658 10.9024 9.70711 11.2929L11.8446 13.4303L15.1679 8.44528C15.4743 7.98576 16.0952 7.86158 16.5547 8.16793Z"
      fill={color}
    />
  </svg>
);

IconSecurity.defaultProps = defaultIconProps;
