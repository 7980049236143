import { defaultIconProps, IconProps } from './IconProps';

export const IconModelImport: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M10 2.5V12.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.66666 9.16667L9.99999 12.5L13.3333 9.16667" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M6.66666 4.16667H3.33332C2.8913 4.16667 2.46737 4.34226 2.15481 4.65482C1.84225 4.96738 1.66666 5.39131 1.66666 5.83333V14.1667C1.66666 14.6087 1.84225 15.0326 2.15481 15.3452C2.46737 15.6577 2.8913 15.8333 3.33332 15.8333H16.6667C17.1087 15.8333 17.5326 15.6577 17.8452 15.3452C18.1577 15.0326 18.3333 14.6087 18.3333 14.1667V5.83333C18.3333 5.39131 18.1577 4.96738 17.8452 4.65482C17.5326 4.34226 17.1087 4.16667 16.6667 4.16667H13.3333"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconModelImport.defaultProps = defaultIconProps;
