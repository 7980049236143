import { defaultIconProps, IconProps } from './IconProps';

export const IconAuthentication: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 11C7.34315 11 6 12.3431 6 14C6 15.6569 7.34315 17 9 17C10.6569 17 12 15.6569 12 14C12 12.3431 10.6569 11 9 11ZM4 14C4 11.2386 6.23858 9 9 9C11.7614 9 14 11.2386 14 14C14 16.7614 11.7614 19 9 19C6.23858 19 4 16.7614 4 14Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7071 5.29289C18.0976 5.68342 18.0976 6.31658 17.7071 6.70711L16.9142 7.5L18.7071 9.29289C19.0976 9.68342 19.0976 10.3166 18.7071 10.7071C18.3166 11.0976 17.6834 11.0976 17.2929 10.7071L15.5 8.91421L12.7071 11.7071C12.3166 12.0976 11.6834 12.0976 11.2929 11.7071C10.9024 11.3166 10.9024 10.6834 11.2929 10.2929L16.2929 5.29289C16.6834 4.90237 17.3166 4.90237 17.7071 5.29289Z"
      fill={color}
    />
  </svg>
);

IconAuthentication.defaultProps = defaultIconProps;
