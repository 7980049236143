import { defaultIconProps, IconProps } from './IconProps';

export const IconPause: import('react').FC<IconProps> = ({ color, ...rest }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M8.33333 3.33333H5V16.6667H8.33333V3.33333Z" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15 3.33333H11.6667V16.6667H15V3.33333Z" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

IconPause.defaultProps = defaultIconProps;
