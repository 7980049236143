import { defaultIconProps, IconProps } from './IconProps';

export const IconWarning: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M9.99977 6.66667V10M9.99977 13.3333H10.0081M8.57477 2.38334L1.51644 14.1667C1.37091 14.4187 1.29391 14.7044 1.2931 14.9954C1.29228 15.2865 1.36768 15.5726 1.5118 15.8254C1.65591 16.0783 1.86371 16.289 2.11453 16.4366C2.36535 16.5841 2.65044 16.6635 2.94144 16.6667H17.0581C17.3491 16.6635 17.6342 16.5841 17.885 16.4366C18.1358 16.289 18.3436 16.0783 18.4878 15.8254C18.6319 15.5726 18.7073 15.2865 18.7064 14.9954C18.7056 14.7044 18.6286 14.4187 18.4831 14.1667L11.4248 2.38334C11.2762 2.13843 11.067 1.93594 10.8174 1.79541C10.5678 1.65488 10.2862 1.58105 9.99977 1.58105C9.71333 1.58105 9.43172 1.65488 9.18211 1.79541C8.93251 1.93594 8.72333 2.13843 8.57477 2.38334Z"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconWarning.defaultProps = defaultIconProps;
