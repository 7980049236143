import { defaultIconProps, IconProps } from './IconProps';

export const IconCheckCircle: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M0 28C0 12.536 12.536 0 28 0C43.464 0 56 12.536 56 28C56 43.464 43.464 56 28 56C12.536 56 0 43.464 0 28Z" fill={color} />
    <path
      d="M39.6667 26.9267V28C39.6653 30.5158 38.8506 32.9638 37.3443 34.9788C35.8379 36.9938 33.7205 38.4679 31.308 39.1812C28.8954 39.8945 26.3168 39.8089 23.9569 38.937C21.597 38.0652 19.5821 36.4538 18.2128 34.3433C16.8435 32.2327 16.1931 29.7361 16.3587 27.2257C16.5242 24.7153 17.4968 22.3257 19.1314 20.4133C20.766 18.5008 22.975 17.168 25.4289 16.6135C27.8829 16.0591 30.4504 16.3127 32.7484 17.3367M39.6667 18.6667L28 30.345L24.5 26.845"
      stroke="#039855"
      strokeWidth="2.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconCheckCircle.defaultProps = defaultIconProps;
