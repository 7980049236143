import { omit } from 'rambda';
import { defaultIconProps, IconProps } from './IconProps';

export const IconInputInfo: import('react').FC<IconProps> = ({ size, color, strokeWidth = '1.67', ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M7.99967 14.6667C11.6816 14.6667 14.6663 11.6819 14.6663 8.00001C14.6663 4.31811 11.6816 1.33334 7.99967 1.33334C4.31778 1.33334 1.33301 4.31811 1.33301 8.00001C1.33301 11.6819 4.31778 14.6667 7.99967 14.6667Z"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8 10.6667V8" stroke={color} strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 5.33334H8.00667" stroke={color} strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

IconInputInfo.defaultProps = omit(['strokeWidth'], defaultIconProps);
