import React from 'react';
import { useId } from 'react-aria';
import { IconProps, defaultIconProps } from './IconProps';

export const IconPieChart: React.FC<IconProps> = ({ size, color, ...rest }) => {
  const pieChartId = useId();

  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath={`url(#clip_pie_chart_${pieChartId})`}>
        <path
          d="M17.6752 13.2416C17.145 14.4954 16.3158 15.6002 15.2601 16.4594C14.2043 17.3186 12.9541 17.9062 11.6189 18.1706C10.2836 18.4351 8.90386 18.3684 7.6003 17.9765C6.29673 17.5845 5.10903 16.8792 4.14102 15.9222C3.17302 14.9652 2.45419 13.7856 2.04737 12.4866C1.64055 11.1876 1.55814 9.80872 1.80734 8.4705C2.05653 7.13229 2.62975 5.8755 3.47688 4.81C4.324 3.7445 5.41924 2.90274 6.66684 2.35831M17.6992 6.81095C18.0328 7.61633 18.2374 8.46778 18.3069 9.33401C18.324 9.54801 18.3326 9.65501 18.2901 9.7514C18.2546 9.83191 18.1843 9.9081 18.1069 9.94992C18.0142 9.99998 17.8984 9.99998 17.6668 9.99998H10.6668C10.4335 9.99998 10.3168 9.99998 10.2277 9.95456C10.1493 9.91462 10.0855 9.85087 10.0456 9.77247C10.0002 9.68334 10.0002 9.56667 10.0002 9.33331V2.33331C10.0002 2.10175 10.0002 1.98598 10.0502 1.8933C10.0921 1.81588 10.1682 1.74555 10.2488 1.71005C10.3451 1.66756 10.4521 1.67614 10.6661 1.6933C11.5324 1.76274 12.3838 1.96738 13.1892 2.30098C14.2002 2.71977 15.1189 3.3336 15.8927 4.10742C16.6666 4.88124 17.2804 5.7999 17.6992 6.81095Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id={`clip_pie_chart_${pieChartId}`}>
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

IconPieChart.defaultProps = defaultIconProps;
