import { defaultIconProps, IconProps } from './IconProps';

export const IconLeftStopLight: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M11 12L10.6464 11.6464L10.2929 12L10.6464 12.3536L11 12ZM16.6464 5.64645L10.6464 11.6464L11.3536 12.3536L17.3536 6.35355L16.6464 5.64645ZM10.6464 12.3536L16.6464 18.3536L17.3536 17.6464L11.3536 11.6464L10.6464 12.3536Z"
      fill={color}
    />
    <path d="M6 7V17" stroke={color} />
  </svg>
);

IconLeftStopLight.defaultProps = defaultIconProps;
