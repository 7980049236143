import React from 'react';
import { useId } from 'react-aria';
import { defaultIconProps, IconProps } from './IconProps';

export const IconSpeedometer: React.FC<IconProps> = ({ size = 20, color = '#195AFF', ...rest }) => {
  const iconSpeedometerId = useId();

  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath={`url(#clip0_${iconSpeedometerId})`}>
        <path
          d="M9.99984 1.66699V3.75033M9.99984 1.66699C5.39746 1.66699 1.6665 5.39795 1.6665 10.0003M9.99984 1.66699C14.6022 1.66699 18.3332 5.39795 18.3332 10.0003M9.99984 16.2503V18.3337M9.99984 18.3337C14.6022 18.3337 18.3332 14.6027 18.3332 10.0003M9.99984 18.3337C5.39746 18.3337 1.6665 14.6027 1.6665 10.0003M3.74984 10.0003H1.6665M18.3332 10.0003H16.2498M15.8985 15.899L14.4204 14.4209M4.1012 15.899L5.5808 14.4194M4.1012 4.16699L5.54824 5.61403M15.8985 4.16699L11.2498 8.75033M11.6665 10.0003C11.6665 10.9208 10.9203 11.667 9.99984 11.667C9.07936 11.667 8.33317 10.9208 8.33317 10.0003C8.33317 9.07985 9.07936 8.33366 9.99984 8.33366C10.9203 8.33366 11.6665 9.07985 11.6665 10.0003Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id={`clip0_${iconSpeedometerId}`}>
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

IconSpeedometer.defaultProps = defaultIconProps;
