import { defaultIconProps, IconProps } from './IconProps';

export const IconWidget: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.95396 6H17.046C17.9415 5.99998 18.6805 5.99997 19.2802 6.05432C19.9039 6.11085 20.4744 6.23242 21 6.5359C21.6081 6.88697 22.113 7.39192 22.4641 8C22.7676 8.52564 22.8892 9.09615 22.9457 9.71983C23 10.3195 23 11.0585 23 11.9539V12.0461C23 12.9415 23 13.6805 22.9457 14.2802C22.8892 14.9039 22.7676 15.4744 22.4641 16C22.113 16.6081 21.6081 17.113 21 17.4641C20.4744 17.7676 19.9039 17.8892 19.2802 17.9457C18.6805 18 17.9415 18 17.0461 18H6.95394C6.05848 18 5.31952 18 4.71983 17.9457C4.09615 17.8892 3.52564 17.7676 3 17.4641C2.39192 17.113 1.88697 16.6081 1.5359 16C1.23242 15.4744 1.11085 14.9039 1.05432 14.2802C0.999972 13.6805 0.999985 12.9415 1 12.046V11.954C0.999985 11.0585 0.999972 10.3195 1.05432 9.71983C1.11085 9.09615 1.23242 8.52564 1.5359 8C1.88697 7.39192 2.39192 6.88697 3 6.5359C3.52564 6.23242 4.09615 6.11085 4.71983 6.05432C5.31952 5.99997 6.05849 5.99998 6.95396 6ZM4.90036 8.04616C4.41539 8.09011 4.17051 8.16951 4 8.26795C3.69596 8.44349 3.44349 8.69596 3.26795 9C3.16951 9.17051 3.09011 9.41539 3.04616 9.90036C3.00096 10.3991 3 11.047 3 12C3 12.953 3.00096 13.6009 3.04616 14.0996C3.09011 14.5846 3.16951 14.8295 3.26795 15C3.44349 15.304 3.69596 15.5565 4 15.7321C4.17051 15.8305 4.41539 15.9099 4.90036 15.9538C5.39908 15.999 6.04698 16 7 16H17C17.953 16 18.6009 15.999 19.0996 15.9538C19.5846 15.9099 19.8295 15.8305 20 15.7321C20.304 15.5565 20.5565 15.304 20.7321 15C20.8305 14.8295 20.9099 14.5846 20.9538 14.0996C20.999 13.6009 21 12.953 21 12C21 11.047 20.999 10.3991 20.9538 9.90036C20.9099 9.41539 20.8305 9.17051 20.7321 9C20.5565 8.69596 20.304 8.44349 20 8.26795C19.8295 8.16951 19.5846 8.09011 19.0996 8.04616C18.6009 8.00096 17.953 8 17 8H7C6.04698 8 5.39908 8.00096 4.90036 8.04616Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4C4 2.34315 5.34315 1 7 1H17C18.6569 1 20 2.34315 20 4H18C18 3.44772 17.5523 3 17 3H7C6.44772 3 6 3.44772 6 4H4Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 20C6 20.5523 6.44772 21 7 21H17C17.5523 21 18 20.5523 18 20H20C20 21.6569 18.6569 23 17 23H7C5.34315 23 4 21.6569 4 20H6Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 10C5.44772 10 5 10.4477 5 11C5 11.5523 5.44772 12 6 12H10C10.5523 12 11 11.5523 11 11C11 10.4477 10.5523 10 10 10H6Z"
      fill={color}
    />
  </svg>
);

IconWidget.defaultProps = defaultIconProps;
