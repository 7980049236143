import { defaultIconProps, IconProps } from './IconProps';

/* eslint-disable max-len */
export const IconBatchPredict: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g clipPath="url(#clip0_417_699)">
      <path
        d="M10.0001 1.66669L11.6767 6.02592C11.9117 6.63695 12.0292 6.94246 12.212 7.19944C12.3739 7.4272 12.5729 7.6262 12.8007 7.78815C13.0576 7.97088 13.3632 8.08838 13.9742 8.32339L18.3334 10L13.9742 11.6767C13.3632 11.9117 13.0576 12.0292 12.8007 12.2119C12.5729 12.3738 12.3739 12.5728 12.212 12.8006C12.0292 13.0576 11.9117 13.3631 11.6767 13.9741L10.0001 18.3334L8.32345 13.9741C8.08844 13.3631 7.97094 13.0576 7.78821 12.8006C7.62626 12.5728 7.42727 12.3738 7.19951 12.2119C6.94252 12.0292 6.63701 11.9117 6.02599 11.6767L1.66675 10L6.02598 8.32339C6.63701 8.08838 6.94252 7.97088 7.1995 7.78815C7.42726 7.6262 7.62626 7.4272 7.78821 7.19944C7.97094 6.94246 8.08844 6.63695 8.32345 6.02592L10.0001 1.66669Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_417_699">
        <rect width={size} height={size} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

IconBatchPredict.defaultProps = defaultIconProps;
