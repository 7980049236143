import { Observable, Subject } from 'rxjs';
import { noop } from 'utils/fp';

const PREFIX = 'clfai_';
export const localStoragePrefix = PREFIX;
type Options = { noPrefix?: boolean };

type CustomAppStorage = appstorage.AppStorageStatic &
  Record<string, unknown> & {
    _getPrefixedKey: (key: string, options?: Options) => string;
    prefix: string;
  };
const createAppStorage = ({
  prefix,
  storage,
}: {
  prefix: string;
  storage?: WindowLocalStorage['localStorage'];
}): [
  appstorage.AppStorageStatic,
  Observable<{
    method: string;
    key?: string;
    value?: unknown;
  }>,
] => {
  const localStorage = storage;

  const AppStorage = {} as CustomAppStorage;
  AppStorage.prefix = prefix;
  const localStorageSubject = new Subject<{
    method: string;
    key?: string;
    value?: unknown;
  }>();
  const appStorageObservable = localStorageSubject.asObservable();

  AppStorage._getPrefixedKey = (key: string, options?: Options) => {
    options = options || {};

    if (options.noPrefix) {
      return key;
    }
    return AppStorage.prefix + key;
  };

  AppStorage.set = (key: string, value: unknown, options: Options) => {
    const queryKey = AppStorage._getPrefixedKey(key, options);

    try {
      localStorage?.setItem(queryKey, JSON.stringify({ data: value }));
      localStorageSubject.next({
        method: 'set',
        key,
        value,
      });
    } catch {
      if (console) console.warn(`AppStorage didn't successfully save the '{${key}: ${value}}' pair, because the localStorage is full.`);
    }
  };

  AppStorage.get = (key, missing, options) => {
    const queryKey = AppStorage._getPrefixedKey(key, options);
    let value;

    try {
      const item = localStorage?.getItem(queryKey);
      value = item ? JSON.parse(item) : null;
      localStorageSubject.next({
        method: 'get',
        key,
        value,
      });
    } catch {
      value = localStorage && localStorage[queryKey] ? { data: localStorage.getItem(queryKey) } : null;
    }

    if (!value) {
      return missing;
    }
    if (typeof value === 'object' && typeof value.data !== 'undefined') {
      return value.data;
    }
    return value;
  };

  AppStorage.keys = () => {
    const keys = [];
    const allKeys = Object.keys(localStorage || {});

    if (AppStorage.prefix.length === 0) {
      return allKeys;
    }

    for (const key of allKeys) {
      if (key.includes(AppStorage.prefix)) {
        keys.push(key.replace(AppStorage.prefix, ''));
      }
    }
    return keys;
  };

  AppStorage.getAll = (includeKeys = true) => {
    const keys = AppStorage.keys();

    if (includeKeys) {
      return keys.reduce<Record<string, unknown>[]>((accum, key) => {
        const tempObj: Record<string, unknown> = {};
        tempObj[key] = AppStorage.get(key);
        accum.push(tempObj);
        return accum;
      }, []);
    }

    return keys.map((key) => AppStorage.get(key));
  };

  AppStorage.rm = (key) => {
    const queryKey = AppStorage._getPrefixedKey(key);

    localStorage?.removeItem(queryKey);
    localStorageSubject.next({
      method: 'rm',
      key,
    });
  };

  AppStorage.flush = () => {
    if (AppStorage.prefix.length > 0) {
      for (const key of AppStorage.keys()) {
        localStorage?.removeItem(AppStorage._getPrefixedKey(key));
      }
    } else {
      localStorage?.clear();
    }
    localStorageSubject.next({
      method: 'flush',
    });
  };

  return [AppStorage, appStorageObservable];
};

const [storage, observable] = createAppStorage({
  storage:
    typeof window === 'undefined'
      ? ({
          setItem: noop,
          getItem: noop,
          removeItem: noop,
          clear: noop,
          length: 0,
        } as unknown as Storage)
      : window.localStorage,
  prefix: PREFIX,
});

export const appStorage = storage;
export const appStorageObservable = observable;
