/* istanbul ignore file */
/* eslint-disable max-len */
import { IconProps } from './IconProps';

export const IconMailShadow: import('react').FC<IconProps> = ({ size = 56, color = '#E6EDFD', ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <rect x="4" y="4" width="48" height="48" rx="24" fill={color} />
    <path
      d="M20 20H36C37.1 20 38 20.9 38 22V34C38 35.1 37.1 36 36 36H20C18.9 36 18 35.1 18 34V22C18 20.9 18.9 20 20 20Z"
      stroke="#003EBC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M38 22L28 29L18 22" stroke="#003EBC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F2F6FE" strokeWidth="8" />
  </svg>
);
