export const DEFAULT_GO_API_HOST = 'api-dev.clarifai.com';
export const DEFAULT_PUBLIC_ERROR = 'Something went wrong';

export const CLARIFAI_SESSION_TOKEN_NAME = 'x-clarifai-session-token';
export const CLARIFAI_REQ_ID_PREFIX_HEADER_NAME = 'x-clarifai-request-id-prefix';

export const COMMUNITY_PUBLIC_USER_PAT = process.env.PUBLIC_USER_PAT;
export const GET_USER_SELF_PATH = '/v2/users/me';
export const CLARIFAI_API_HOST = envVarWithProtocolAndDefault(process.env.NEXT_PUBLIC_GO_API_HOST, DEFAULT_GO_API_HOST);

export enum ApiHost {
  Dev = 'api-dev',
  Staging = 'api-staging',
  Prod = 'api',
}

export const OldPortalHosts: Record<ApiHost, string> = {
  [ApiHost.Dev]: '//developer-dev.clarifai.com',
  [ApiHost.Staging]: '//developer-staging.clarifai.com',
  [ApiHost.Prod]: '//portal.clarifai.com',
};

export const API_HOST = new URL(`${CLARIFAI_API_HOST}`).hostname.split('.')[0] as ApiHost;

export const FILTER_ENTITY_TYPE_MODEL = 'model';

export const SUPPORTED_FILTER_ENTITY_TYPES = [FILTER_ENTITY_TYPE_MODEL];

// reads a variable from process.env, and prefixes `https` if no protocol is specified on the URL.
// assumes the env variable is intended to be a host URL.
export function envVarWithProtocolAndDefault(envValue: string | undefined, defaultValue = ''): string {
  let resolved = envValue || defaultValue;
  if (!resolved.startsWith('http')) {
    resolved = `https://${resolved}`;
  }
  return resolved;
}
