import { defaultIconProps, IconProps } from './IconProps';

export const IconPlayNext: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M4.16666 10H15.8333" stroke={color} strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 4.16666L15.8333 10L10 15.8333" stroke={color} strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

IconPlayNext.defaultProps = defaultIconProps;
