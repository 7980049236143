import { defaultIconProps, IconProps } from './IconProps';

export const IconExperiments: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 9C8.55228 9 9 9.44772 9 10L9 16C9 16.5523 8.55229 17 8 17C7.44772 17 7 16.5523 7 16L7 10C7 9.44772 7.44772 9 8 9Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 11C12.5523 11 13 11.4477 13 12V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V12C11 11.4477 11.4477 11 12 11Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 7C16.5523 7 17 7.44772 17 8V16C17 16.5523 16.5523 17 16 17C15.4477 17 15 16.5523 15 16V8C15 7.44772 15.4477 7 16 7Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.93417 3C6.95604 3 6.97799 3 7 3L17.0658 3C17.9523 2.99995 18.7161 2.99991 19.3278 3.08215C19.9833 3.17028 20.6117 3.36902 21.1213 3.87868C21.631 4.38835 21.8297 5.0167 21.9179 5.67221C22.0001 6.28388 22.0001 7.0477 22 7.9342V16.0658C22.0001 16.9523 22.0001 17.7161 21.9179 18.3278C21.8297 18.9833 21.631 19.6117 21.1213 20.1213C20.6117 20.631 19.9833 20.8297 19.3278 20.9179C18.7161 21.0001 17.9523 21.0001 17.0658 21H6.9342C6.0477 21.0001 5.28388 21.0001 4.67221 20.9179C4.0167 20.8297 3.38835 20.631 2.87868 20.1213C2.36902 19.6117 2.17028 18.9833 2.08215 18.3278C1.99991 17.7161 1.99995 16.9523 2 16.0658L2 8C2 7.97799 2 7.95604 2 7.93417C1.99995 7.04769 1.99991 6.28387 2.08215 5.67221C2.17028 5.0167 2.36902 4.38835 2.87868 3.87868C3.38835 3.36902 4.0167 3.17028 4.67221 3.08215C5.28387 2.99991 6.04769 2.99995 6.93417 3ZM4.93871 5.06431C4.50497 5.12263 4.36902 5.21677 4.2929 5.2929C4.21677 5.36902 4.12263 5.50497 4.06431 5.93871C4.00213 6.40122 4 7.02893 4 8V16C4 16.9711 4.00213 17.5988 4.06431 18.0613C4.12263 18.495 4.21677 18.631 4.2929 18.7071C4.36902 18.7832 4.50497 18.8774 4.93871 18.9357C5.40121 18.9979 6.02893 19 7 19H17C17.9711 19 18.5988 18.9979 19.0613 18.9357C19.495 18.8774 19.631 18.7832 19.7071 18.7071C19.7832 18.631 19.8774 18.495 19.9357 18.0613C19.9979 17.5988 20 16.9711 20 16V8C20 7.02893 19.9979 6.40122 19.9357 5.93871C19.8774 5.50497 19.7832 5.36902 19.7071 5.2929C19.631 5.21677 19.495 5.12263 19.0613 5.06431C18.5988 5.00213 17.9711 5 17 5H7C6.02893 5 5.40122 5.00213 4.93871 5.06431Z"
      fill={color}
    />
  </svg>
);

IconExperiments.defaultProps = defaultIconProps;
