import { IconProps, defaultIconProps } from './IconProps';

export const IconAlertTriangle: React.FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M7.99983 5.99999V8.66665M7.99983 11.3333H8.0065M7.07671 2.59447L1.59345 12.0655C1.28932 12.5909 1.13725 12.8535 1.15972 13.0691C1.17933 13.2571 1.27784 13.428 1.43075 13.5392C1.60605 13.6667 1.90956 13.6667 2.51658 13.6667H13.4831C14.0901 13.6667 14.3936 13.6667 14.5689 13.5392C14.7218 13.428 14.8203 13.2571 14.8399 13.0691C14.8624 12.8535 14.7103 12.5909 14.4062 12.0655L8.92295 2.59447C8.61991 2.07102 8.46838 1.8093 8.2707 1.7214C8.09826 1.64473 7.9014 1.64473 7.72897 1.7214C7.53128 1.8093 7.37976 2.07102 7.07671 2.59447Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconAlertTriangle.defaultProps = defaultIconProps;
