import { defaultIconProps, IconProps } from './IconProps';

export const IconWorkflow: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 8C17.6569 8 19 9.34315 19 11C19 12.6569 17.6569 14 16 14C14.3431 14 13 12.6569 13 11C13 9.34315 14.3431 8 16 8ZM17 11C17 10.4477 16.5523 10 16 10C15.4477 10 15 10.4477 15 11C15 11.5523 15.4477 12 16 12C16.5523 12 17 11.5523 17 11Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 16C17.6569 16 19 17.3431 19 19C19 20.6569 17.6569 22 16 22C14.3431 22 13 20.6569 13 19C13 17.3431 14.3431 16 16 16ZM17 19C17 18.4477 16.5523 18 16 18C15.4477 18 15 18.4477 15 19C15 19.5523 15.4477 20 16 20C16.5523 20 17 19.5523 17 19Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 8C3.34315 8 2 6.65685 2 5C2 3.34315 3.34315 2 5 2C6.65685 2 8 3.34315 8 5C8 6.65685 6.65685 8 5 8ZM4 5C4 5.55228 4.44772 6 5 6C5.55228 6 6 5.55228 6 5C6 4.44772 5.55228 4 5 4C4.44772 4 4 4.44772 4 5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 8H6V15C6 15.9711 6.00212 16.5988 6.0643 17.0613C6.12262 17.495 6.21677 17.631 6.29289 17.7071C6.36901 17.7832 6.50496 17.8774 6.9387 17.9357C7.40121 17.9979 8.02892 18 9 18H14V20L8.93416 20C8.04768 20.0001 7.28387 20.0001 6.67221 19.9179C6.01669 19.8297 5.38834 19.631 4.87868 19.1213C4.36901 18.6117 4.17027 17.9833 4.08214 17.3278C3.9999 16.7161 3.99994 15.9523 3.99999 15.0658L4 8Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.99999 7.06583C4 7.04396 4 7.02202 4 7H6C6 7.97108 6.00212 8.59879 6.0643 9.0613C6.12262 9.49504 6.21677 9.63098 6.29289 9.70711C6.36901 9.78323 6.50496 9.87738 6.9387 9.93569C7.40121 9.99788 8.02892 10 9 10H14V12H9C8.97798 12 8.95603 12 8.93416 12C8.04768 12.0001 7.28387 12.0001 6.67221 11.9179C6.01669 11.8297 5.38834 11.631 4.87868 11.1213C4.36901 10.6117 4.17027 9.98331 4.08214 9.32779C3.9999 8.71613 3.99994 7.95232 3.99999 7.06583Z"
      fill={color}
    />
  </svg>
);

IconWorkflow.defaultProps = defaultIconProps;
