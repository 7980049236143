import { css } from '@linaria/core';

// Todo: figure out linaria bug to remove baseunit duplicate declaration

const baseUnit = '2.8rem';
const halfBaseUnit = `calc( ${baseUnit} / 2 )`;
const quarterBaseUnit = `calc( ${baseUnit} / 4 )`;
const doubleBaseUnit = `calc( ${baseUnit} * 2 )`;
const basePadding = halfBaseUnit;
const baseUnits = {
  unit: baseUnit,
  halfUnit: halfBaseUnit,
  quarterUnit: quarterBaseUnit,
  doubleUnit: doubleBaseUnit,
  padding: basePadding
};
const justifyStart = "tyles__utils__core__cssHelpers_ts-justifyStart-jknto95";
const justifyCenter = "tyles__utils__core__cssHelpers_ts-justifyCenter-j1kc3cgv";
const justifyEnd = "tyles__utils__core__cssHelpers_ts-justifyEnd-jojyz24";
const justifyInitial = "tyles__utils__core__cssHelpers_ts-justifyInitial-j11457ci";
const justifyBetween = "tyles__utils__core__cssHelpers_ts-justifyBetween-jv9x5jk";
const justifyAround = "tyles__utils__core__cssHelpers_ts-justifyAround-jgr6jzc";
const justifyEvenly = "tyles__utils__core__cssHelpers_ts-justifyEvenly-jrl4y91";
const directionRow = "tyles__utils__core__cssHelpers_ts-directionRow-d17egy4u";
const directionRowReverse = "tyles__utils__core__cssHelpers_ts-directionRowReverse-d2aqirw";
const directionColumn = "tyles__utils__core__cssHelpers_ts-directionColumn-d1q5ldab";
const directionColumnReverse = "tyles__utils__core__cssHelpers_ts-directionColumnReverse-d1oa4cvm";
const alignStart = "tyles__utils__core__cssHelpers_ts-alignStart-a16ipd0z";
const alignCenter = "tyles__utils__core__cssHelpers_ts-alignCenter-a19swlei";
const alignEnd = "tyles__utils__core__cssHelpers_ts-alignEnd-a77vp9a";
const alignInitial = "tyles__utils__core__cssHelpers_ts-alignInitial-a1kyp5bm";
const alignStretch = "tyles__utils__core__cssHelpers_ts-alignStretch-a1qcuo23";
const NoWrap = "tyles__utils__core__cssHelpers_ts-NoWrap-n1cgz51h";
const Wrap = "tyles__utils__core__cssHelpers_ts-Wrap-w2jsqox";
const WrapReverse = "tyles__utils__core__cssHelpers_ts-WrapReverse-wgh7k06";
const WrapInitial = "tyles__utils__core__cssHelpers_ts-WrapInitial-w1jmywkk";
const flexAuto = "tyles__utils__core__cssHelpers_ts-flexAuto-fnk50pt";
const flexOne = "tyles__utils__core__cssHelpers_ts-flexOne-f137mno2";
const flexZero = "tyles__utils__core__cssHelpers_ts-flexZero-f1ghtgaa";
export const borderRounded = "tyles__utils__core__cssHelpers_ts-borderRounded-b1eqxupi";
export const flex = {
  init: "tyles__utils__core__cssHelpers_ts-init-iida6s1",
  justifyContent: {
    start: justifyStart,
    center: justifyCenter,
    end: justifyEnd,
    initial: justifyInitial,
    between: justifyBetween,
    around: justifyAround,
    evenly: justifyEvenly
  },
  alignItems: {
    start: alignStart,
    center: alignCenter,
    end: alignEnd,
    initial: alignInitial,
    stretch: alignStretch
  },
  direction: {
    row: directionRow,
    rowReverse: directionRowReverse,
    column: directionColumn,
    columnReverse: directionColumnReverse
  },
  flexWrap: {
    wrap: Wrap,
    noWrap: NoWrap,
    reverse: WrapReverse,
    initial: WrapInitial
  },
  flex: {
    auto: flexAuto,
    one: flexOne,
    zero: flexZero
  },
  gap: {
    0: "tyles__utils__core__cssHelpers_ts-0-_qyn39h",
    quarter: "tyles__utils__core__cssHelpers_ts-quarter-qwm2efj",
    half: "tyles__utils__core__cssHelpers_ts-half-h1873glh",
    1: "tyles__utils__core__cssHelpers_ts-1-_w5o7p0",
    2: "tyles__utils__core__cssHelpers_ts-2-_1l002m"
  }
};
export const cssHelpers = {
  cursor: {
    pointer: "tyles__utils__core__cssHelpers_ts-pointer-piudqpc"
  },
  overflow: {
    y: {
      hidden: "tyles__utils__core__cssHelpers_ts-hidden-halzzg0"
    }
  },
  flex,
  zIndex: {
    max: "tyles__utils__core__cssHelpers_ts-max-mnjis8h",
    zero: "tyles__utils__core__cssHelpers_ts-zero-znz5lbg"
  },
  margin: {
    // y stands for both top & bottom, yt for top, and yb for bottom
    y: {
      0: "tyles__utils__core__cssHelpers_ts-0-_1292w92",
      1: "tyles__utils__core__cssHelpers_ts-1-_1biqre8",
      2: "tyles__utils__core__cssHelpers_ts-2-_rlhhsy"
    },
    yt: {
      0: "tyles__utils__core__cssHelpers_ts-0-_11gx7sz",
      half: "tyles__utils__core__cssHelpers_ts-half-h1sgf7j0",
      1: "tyles__utils__core__cssHelpers_ts-1-_vd8mmg",
      2: "tyles__utils__core__cssHelpers_ts-2-_178djdb"
    },
    yb: {
      0: "tyles__utils__core__cssHelpers_ts-0-_5qc27y",
      1: "tyles__utils__core__cssHelpers_ts-1-_eveysk",
      2: "tyles__utils__core__cssHelpers_ts-2-_jtlcs1"
    },
    // x stands for both left & right, xl for left, and xr for right
    x: {
      0: "tyles__utils__core__cssHelpers_ts-0-_1lw1tbs",
      half: "tyles__utils__core__cssHelpers_ts-half-h138jf0c",
      1: "tyles__utils__core__cssHelpers_ts-1-_15d2tvf",
      2: "tyles__utils__core__cssHelpers_ts-2-_12i51kz"
    },
    xl: {
      0: "tyles__utils__core__cssHelpers_ts-0-_8a1ur",
      1: "tyles__utils__core__cssHelpers_ts-1-_gpepr",
      2: "tyles__utils__core__cssHelpers_ts-2-_yq47k3",
      half: "tyles__utils__core__cssHelpers_ts-half-h1alsiwt"
    },
    xr: {
      0: "tyles__utils__core__cssHelpers_ts-0-_1ngozvi",
      // TODO: add these to all other margin/padding helpers and change `1` `2` etc to `one` `two`
      quarter: "tyles__utils__core__cssHelpers_ts-quarter-qmnlvci",
      half: "tyles__utils__core__cssHelpers_ts-half-h1kizec1",
      1: "tyles__utils__core__cssHelpers_ts-1-_njccco",
      2: "tyles__utils__core__cssHelpers_ts-2-_1wwa20d"
    }
  },
  padding: {
    // y stands for both top & bottom, yt for top, and yb for bottom
    y: {
      0: "tyles__utils__core__cssHelpers_ts-0-_1mr2cjg",
      1: "tyles__utils__core__cssHelpers_ts-1-_1pn67r5",
      2: "tyles__utils__core__cssHelpers_ts-2-_so3w8w"
    },
    yt: {
      0: "tyles__utils__core__cssHelpers_ts-0-_1gey7qw",
      1: "tyles__utils__core__cssHelpers_ts-1-_pfb0n2",
      2: "tyles__utils__core__cssHelpers_ts-2-_1kyrpcn"
    },
    yb: {
      0: "tyles__utils__core__cssHelpers_ts-0-_1gxdxvq",
      1: "tyles__utils__core__cssHelpers_ts-1-_1ag6t5j",
      2: "tyles__utils__core__cssHelpers_ts-2-_1vt85zd"
    },
    // x stands for both left & right, xl for left, and xr for right
    x: {
      0: "tyles__utils__core__cssHelpers_ts-0-_d7n1aj",
      1: "tyles__utils__core__cssHelpers_ts-1-_1dyihyl",
      2: "tyles__utils__core__cssHelpers_ts-2-_jzef94"
    },
    xl: {
      0: "tyles__utils__core__cssHelpers_ts-0-_9qptv",
      1: "tyles__utils__core__cssHelpers_ts-1-_1ipr7mm",
      2: "tyles__utils__core__cssHelpers_ts-2-_19hfvsu"
    },
    xr: {
      0: "tyles__utils__core__cssHelpers_ts-0-_7mreba",
      1: "tyles__utils__core__cssHelpers_ts-1-_9sd221",
      2: "tyles__utils__core__cssHelpers_ts-2-_1rtxvkx"
    }
  },
  width: {
    content: "tyles__utils__core__cssHelpers_ts-content-c1b9de6q",
    full: "tyles__utils__core__cssHelpers_ts-full-f2vggo0"
  },
  height: {
    content: "tyles__utils__core__cssHelpers_ts-content-ccq9rwr"
  }
};

require("./cssHelpers.linaria.module.css!=!../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./cssHelpers.ts");