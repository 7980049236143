import { defaultIconProps, IconProps } from './IconProps';

export const IconTrainModel: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2929 14.293L14.7071 15.7072L11.4142 19.0001L14.7071 22.293L13.2929 23.7072L8.58582 19.0001L13.2929 14.293Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 5.07181C14.4749 4.19127 12.7018 3.83859 10.9558 4.06845C9.20978 4.29832 7.58841 5.09789 6.34314 6.34316C5.09788 7.58842 4.2983 9.20979 4.06844 10.9558C3.83857 12.7018 4.19126 14.4749 5.07179 16C5.34794 16.4783 5.95953 16.6422 6.43782 16.366C6.91611 16.0899 7.07999 15.4783 6.80384 15C6.14344 13.8562 5.87893 12.5264 6.05133 11.2169C6.22373 9.90735 6.82341 8.69132 7.75736 7.75737C8.69131 6.82342 9.90733 6.22374 11.2168 6.05134C12.5263 5.87894 13.8561 6.14346 15 6.80386C16.1438 7.46426 17.0378 8.48365 17.5433 9.70391C18.0487 10.9242 18.1374 12.2771 17.7956 13.5529C17.4537 14.8287 16.7004 15.9561 15.6526 16.7601C14.6047 17.5642 13.3208 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20C13.7611 20 15.4729 19.4189 16.8701 18.3468C18.2672 17.2748 19.2716 15.7716 19.7274 14.0706C20.1832 12.3695 20.065 10.5656 19.391 8.93854C18.7171 7.31152 17.5251 5.95235 16 5.07181Z"
      fill={color}
    />
  </svg>
);

IconTrainModel.defaultProps = defaultIconProps;
