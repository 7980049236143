import { defaultIconProps, IconProps } from './IconProps';

export const IconModuleManager: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <mask id="path-1-inside-1_1_9983" fill="white">
      <rect x="7.46152" y="3" width="5.38462" height="4.30769" rx="1.07692" />
    </mask>
    <rect x="7.46152" y="3" width="5.38462" height="4.30769" rx="1.07692" stroke={color} strokeWidth="3" mask="url(#path-1-inside-1_1_9983)" />
    <path
      d="M10.1538 6.76923V10M10.1538 13.2308V10M10.1538 10H4.76922C4.17445 10 3.69229 10.4822 3.69229 11.0769V13.2308M10.1538 10H15.5384C16.1332 10 16.6154 10.4822 16.6154 11.0769V13.2308"
      stroke="#195AFF"
      strokeWidth="1.5"
    />
    <mask id="path-3-inside-2_1_9983" fill="white">
      <rect x="7.46152" y="12.6923" width="5.38462" height="4.30769" rx="1.07692" />
    </mask>
    <rect x="7.46152" y="12.6923" width="5.38462" height="4.30769" rx="1.07692" stroke={color} strokeWidth="3" mask="url(#path-3-inside-2_1_9983)" />
    <mask id="path-4-inside-3_1_9983" fill="white">
      <rect x="13.9231" y="12.6923" width="5.38462" height="4.30769" rx="1.07692" />
    </mask>
    <rect x="13.9231" y="12.6923" width="5.38462" height="4.30769" rx="1.07692" stroke={color} strokeWidth="3" mask="url(#path-4-inside-3_1_9983)" />
    <mask id="path-5-inside-4_1_9983" fill="white">
      <rect x="1" y="12.6923" width="5.38462" height="4.30769" rx="1.07692" />
    </mask>
    <rect x="1" y="12.6923" width="5.38462" height="4.30769" rx="1.07692" stroke={color} strokeWidth="3" mask="url(#path-5-inside-4_1_9983)" />
  </svg>
);

IconModuleManager.defaultProps = defaultIconProps;
