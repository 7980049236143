import { defaultIconProps, IconProps } from './IconProps';

export const IconTextZoom: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M0.486848 8.7674V7.02935H8.67563V8.7674H5.62309V17H3.53938V8.7674H0.486848ZM19.4882 17H13.6334V15.0887H15.714V1.97585H11.9157V3.79036H10.0044V0.0645645H23.3834V3.79036H21.4721V1.97585H17.7221V15.0887H19.4882V17Z"
      fill={color}
    />
  </svg>
);

IconTextZoom.defaultProps = defaultIconProps;
