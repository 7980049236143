import { defaultIconProps, IconProps } from './IconProps';

interface IconVerifiedProps extends IconProps {
  strokeWidth?: number;
}

export const IconVerified: import('react').FC<IconVerifiedProps> = ({ size, color, strokeWidth, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest}>
    <path
      d="M10 2.5C9.49251 2.49933 8.99315 2.62742 8.54864 2.87226C8.10413 3.11711 7.72899 3.47072 7.45833 3.9C7.21284 3.84077 6.96084 3.81277 6.70833 3.81667C5.94846 3.84489 5.22765 4.16062 4.69167 4.7C4.33988 5.05868 4.0838 5.49997 3.94694 5.98337C3.81007 6.46677 3.7968 6.9768 3.90833 7.46667C3.4787 7.73558 3.12419 8.10897 2.87792 8.55197C2.63165 8.99497 2.50163 9.49315 2.5 10C2.50022 10.5083 2.62957 11.0081 2.87591 11.4527C3.12225 11.8973 3.4775 12.272 3.90833 12.5417C3.79785 13.0322 3.81245 13.5427 3.95078 14.0261C4.08912 14.5096 4.34675 14.9505 4.7 15.3083C5.05955 15.6671 5.50431 15.9287 5.99258 16.0686C6.48086 16.2086 6.99667 16.2222 7.49167 16.1083C7.76028 16.5313 8.13057 16.8803 8.56872 17.1234C9.00688 17.3665 9.49894 17.496 10 17.5C10.5053 17.5002 11.0024 17.3727 11.4453 17.1294C11.8882 16.8861 12.2624 16.5349 12.5333 16.1083C13.0267 16.2189 13.54 16.2029 14.0255 16.062C14.511 15.9211 14.9531 15.6597 15.3106 15.3022C15.6681 14.9447 15.9294 14.5027 16.0703 14.0172C16.2113 13.5316 16.2272 13.0183 16.1167 12.525C16.5405 12.2539 16.8893 11.8805 17.131 11.4393C17.3728 10.9981 17.4996 10.5031 17.5 10C17.5001 9.4947 17.3727 8.99756 17.1294 8.55468C16.8861 8.11181 16.5349 7.73756 16.1083 7.46667V7.46667C16.2189 6.97333 16.2029 6.46005 16.062 5.97451C15.9211 5.48898 15.6597 5.04693 15.3022 4.68943C14.9447 4.33194 14.5027 4.07059 14.0172 3.92966C13.5316 3.78873 13.0183 3.7728 12.525 3.88334C12.2539 3.45953 11.8805 3.11071 11.4393 2.86897C10.9981 2.62724 10.5031 2.50036 10 2.5V2.5Z"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12.5 8.33331L9.16669 11.6666" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.5 10L9.16667 11.6667" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

IconVerified.defaultProps = {
  ...defaultIconProps,
  strokeWidth: 1.5,
};
