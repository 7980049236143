import { FC } from 'react';
import { IconProps } from './IconProps';

export const IconUserPlus: FC<IconProps> = ({ size = 16, color = 'currentColor', ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M7.99967 10.3333H4.99968C4.0693 10.3333 3.60411 10.3333 3.22558 10.4482C2.37331 10.7067 1.70637 11.3736 1.44783 12.2259C1.33301 12.6044 1.33301 13.0696 1.33301 14M12.6663 14V10M10.6663 12H14.6663M9.66634 5C9.66634 6.65685 8.3232 8 6.66634 8C5.00949 8 3.66634 6.65685 3.66634 5C3.66634 3.34315 5.00949 2 6.66634 2C8.3232 2 9.66634 3.34315 9.66634 5Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
