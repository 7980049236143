import { cx } from '@linaria/core';
import { loader, loaderWrapper } from './Button.styles';

export const ButtonLoader = (): JSX.Element => (
  <div className={cx(loaderWrapper, 'button-loader')}>
    <div className={loader} data-testid="button-loading">
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);
