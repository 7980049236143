interface IconExpandUpRoundedProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  size: number;
  color?: string;
}

export const IconExpandUpRounded: import('react').FC<IconExpandUpRoundedProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M11 6.5L6 1.5L1 6.5" stroke={color} strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

IconExpandUpRounded.defaultProps = {
  size: 10,
  color: '#101828',
};
