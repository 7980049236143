import { ReactNode } from 'react';
import { Coordinates } from 'utils/konva/types';

export enum TipOrientation {
  TopLeft = 'top-left',
  TopCenter = 'top-center',
  TopRight = 'top-right',
  CenterLeft = 'center-left',
  Center = 'center',
  CenterRight = 'center-right',
  BottomLeft = 'bottom-left',
  BottomCenter = 'bottom-center',
  BottomRight = 'bottom-right',
  LeftTop = 'left-top',
  LeftCenter = 'left-center',
  LeftBottom = 'left-bottom',
  RightTop = 'right-top',
  RightCenter = 'right-center',
  RightBottom = 'right-bottom',
}

export type TipPopoverProps = {
  id: string;
  title?: ReactNode;
  message?: ReactNode;
  show?: boolean;
  className?: string;
  tipClassName?: string;
  contextRef?: React.MutableRefObject<HTMLElement | null>;
  position?: Coordinates;
  orientation?: TipOrientation;
  delayToClose?: number;
  content?: ReactNode;
};

export type TipMap = Record<string, Omit<TipPopoverProps, 'id'>>;

export type TipPopoverContextState = {
  showTip: (tip: Omit<TipPopoverProps, 'show'>) => void;
  hideTip: (tipId: string) => void;
};
