import { IconProps } from './IconProps';

export const IconVolumeMin: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg" strokeWidth={1} {...rest}>
    <g xmlns="http://www.w3.org/2000/svg" id="volume-min - ">
      <path
        id="Icon"
        d="M12.1634 5.33345C12.6907 6.08934 12.9999 7.00862 12.9999 8.00011C12.9999 8.99161 12.6907 9.91089 12.1634 10.6668M8.08954 3.57724L5.97909 5.68769C5.86379 5.803 5.80613 5.86065 5.73886 5.90187C5.67921 5.93843 5.61418 5.96536 5.54615 5.98169C5.46943 6.00011 5.3879 6.00011 5.22484 6.00011H4.06667C3.6933 6.00011 3.50661 6.00011 3.36401 6.07278C3.23856 6.13669 3.13658 6.23868 3.07266 6.36412C3 6.50673 3 6.69341 3 7.06678V8.93345C3 9.30682 3 9.4935 3.07266 9.63611C3.13658 9.76155 3.23856 9.86354 3.36401 9.92745C3.50661 10.0001 3.6933 10.0001 4.06667 10.0001H5.22484C5.3879 10.0001 5.46943 10.0001 5.54615 10.0185C5.61418 10.0349 5.67921 10.0618 5.73886 10.0984C5.80613 10.1396 5.86379 10.1972 5.97909 10.3125L8.08954 12.423C8.37513 12.7086 8.51792 12.8514 8.64051 12.861C8.74689 12.8694 8.85084 12.8263 8.92014 12.7452C9 12.6517 9 12.4497 9 12.0459V3.95436C9 3.55048 9 3.34855 8.92014 3.25504C8.85084 3.1739 8.74689 3.13084 8.64051 3.13921C8.51792 3.14886 8.37513 3.29165 8.08954 3.57724Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
