import { defaultIconProps, IconProps } from './IconProps';

export const IconArrowLeftRight: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
  >
    <path d="M17 11L21 7L17 3" />
    <path d="M21 7H9" />
    <path d="M7 21L3 17L7 13" />
    <path d="M15 17H3" />
  </svg>
);

IconArrowLeftRight.defaultProps = defaultIconProps;
