import React from 'react';
import { defaultIconProps, IconProps } from './IconProps';

export const IconSparkles: React.FC<IconProps> = ({ size = 20, color = '#195AFF', ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M3.74984 18.3333V14.1667M3.74984 5.83333V1.66667M1.6665 3.75H5.83317M1.6665 16.25H5.83317M10.8332 2.5L9.38802 6.25739C9.15301 6.86841 9.03551 7.17392 8.85278 7.4309C8.69083 7.65866 8.49184 7.85766 8.26408 8.01961C8.00709 8.20234 7.70158 8.31984 7.09055 8.55485L3.33317 10L7.09056 11.4451C7.70158 11.6802 8.00709 11.7977 8.26408 11.9804C8.49184 12.1423 8.69083 12.3413 8.85278 12.5691C9.03551 12.8261 9.15301 13.1316 9.38802 13.7426L10.8332 17.5L12.2783 13.7426C12.5133 13.1316 12.6308 12.8261 12.8136 12.5691C12.9755 12.3413 13.1745 12.1423 13.4023 11.9804C13.6592 11.7977 13.9648 11.6802 14.5758 11.4451L18.3332 10L14.5758 8.55485C13.9648 8.31984 13.6592 8.20234 13.4023 8.01961C13.1745 7.85766 12.9755 7.65867 12.8136 7.43091C12.6308 7.17392 12.5133 6.86841 12.2783 6.25738L10.8332 2.5Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconSparkles.defaultProps = defaultIconProps;
