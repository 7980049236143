import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef } from 'react';
import { HUBSPOT_CUSTOM_EVENTS_NAME, sendEvent } from 'utils/hubspotApi/hubspotApi';
import { appStorage } from 'utils/appStorage';

const PAGE_COUNT_FOR_TRACK = 2;

export const usePageTrack = (): void => {
  const router = useRouter();
  const pageCountRef = useRef(0);

  const handleRouteChangeComplete = useCallback(() => {
    const authData = appStorage.get('authData') as CF.LocalUser;
    if (authData) {
      pageCountRef.current += 1;
    }

    if (pageCountRef.current === PAGE_COUNT_FOR_TRACK && authData) {
      sendEvent({ email: authData.email, eventName: HUBSPOT_CUSTOM_EVENTS_NAME.lastVisit, properties: {} });
    }
  }, []);

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, []);
};
