import { defaultIconProps, IconProps } from './IconProps';

export const IconFileText: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M14.6667 10.4167V5.66666C14.6667 4.26653 14.6667 3.56646 14.3942 3.03168C14.1545 2.56128 13.7721 2.17882 13.3017 1.93914C12.7669 1.66666 12.0668 1.66666 10.6667 1.66666H5.33334C3.93321 1.66666 3.23315 1.66666 2.69837 1.93914C2.22796 2.17882 1.84551 2.56128 1.60583 3.03168C1.33334 3.56646 1.33334 4.26653 1.33334 5.66666V14.3333C1.33334 15.7335 1.33334 16.4335 1.60583 16.9683C1.84551 17.4387 2.22796 17.8212 2.69837 18.0608C3.23315 18.3333 3.93317 18.3333 5.33323 18.3333H8.41668M9.66668 9.16666H4.66668M6.33334 12.5H4.66668M11.3333 5.83332H4.66668M10.5 15.8333L13 18.3333M13 18.3333L15.5 15.8333M13 18.3333V13.3333"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const IconFileDownload: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M16.6666 10.417V5.66699C16.6666 4.26686 16.6666 3.5668 16.3941 3.03202C16.1544 2.56161 15.772 2.17916 15.3016 1.93948C14.7668 1.66699 14.0667 1.66699 12.6666 1.66699H7.33325C5.93312 1.66699 5.23306 1.66699 4.69828 1.93948C4.22787 2.17916 3.84542 2.56161 3.60574 3.03202C3.33325 3.5668 3.33325 4.26686 3.33325 5.66699V14.3337C3.33325 15.7338 3.33325 16.4339 3.60574 16.9686C3.84542 17.439 4.22787 17.8215 4.69828 18.0612C5.23306 18.3337 5.93308 18.3337 7.33313 18.3337H10.4166M11.6666 9.16699H6.66659M8.33325 12.5003H6.66659M13.3333 5.83366H6.66659M12.4999 15.8337L14.9999 18.3337M14.9999 18.3337L17.4999 15.8337M14.9999 18.3337V13.3337"
      stroke={color || 'currentColor'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconFileText.defaultProps = defaultIconProps;
