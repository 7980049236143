import { defaultIconProps, IconProps } from './IconProps';

export const IconArrowLeft: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.29289 5.29297L10.7071 6.70718L6.41421 11.0001H19C19.5523 11.0001 20 11.4478 20 12.0001C20 12.5524 19.5523 13.0001 19 13.0001H6.41421L10.7071 17.293L9.29289 18.7072L2.58578 12.0001L9.29289 5.29297Z"
      fill={color}
    />
  </svg>
);

IconArrowLeft.defaultProps = defaultIconProps;
