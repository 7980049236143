import { defaultIconProps, IconProps } from './IconProps';

interface IconRedoProps extends IconProps {
  size: number;
  strokeWidth: number;
  color?: string;
}

export const IconRedo: import('react').FC<IconRedoProps> = ({ size, color, strokeWidth, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
    <path
      stroke={color}
      strokeWidth={strokeWidth}
      d="m9.91325,1.82522l3.24288,3.4707l-3.24288,3.4707l-0.70143,-0.6914l2.12897,-2.2793l-4.57539,0c-1.88687,0 -3.40636,1.56126 -3.40636,3.5l0,4.5l-0.97324,0l0,-4.5c0,-2.47926 1.96668,-4.5 4.3796,-4.5l4.57539,0l-2.12897,-2.2793l0.70143,-0.6914z"
    />
  </svg>
);

IconRedo.defaultProps = {
  ...defaultIconProps,
  strokeWidth: 3,
};
