import { ApiStatus } from './types';

const responseCodes: Record<string, ApiStatus> = {
  /* success */
  SUCCESS: { code: 10000, statusCode: 200, description: 'Success' },

  /* mixed success */
  MIXED_SUCCESS: { code: 10010, statusCode: 207, description: 'Mixed Success' },

  /* general errors */
  E_UNKNOWN: { code: 99009, statusCode: 400, description: 'Unknown error' },
  E_VALIDATION: { code: 11102, statusCode: 422, description: 'Request validation failed' },

  /* Clarifai API errors */
  E_CLARIFAI_API: { code: 98005, statusCode: 500, description: 'Failed to fetch resource' },
};

export default responseCodes;
