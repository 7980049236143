import { defaultIconProps, IconProps } from './IconProps';

export const IconFilterAlt: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 7C11 6.44772 11.4477 6 12 6L20 6C20.5523 6 21 6.44771 21 7C21 7.55228 20.5523 8 20 8L12 8C11.4477 8 11 7.55228 11 7Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 7C3 6.44772 3.44772 6 4 6L8 6C8.55228 6 9 6.44771 9 7C9 7.55228 8.55228 8 8 8L4 8C3.44772 8 3 7.55228 3 7Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 17C16 16.4477 16.4477 16 17 16L20 16C20.5523 16 21 16.4477 21 17C21 17.5523 20.5523 18 20 18L17 18C16.4477 18 16 17.5523 16 17Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 17C3 16.4477 3.44772 16 4 16L12 16C12.5523 16 13 16.4477 13 17C13 17.5523 12.5523 18 12 18L4 18C3.44772 18 3 17.5523 3 17Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 7C11 6.44772 10.5523 6 10 6C9.44772 6 9 6.44772 9 7C9 7.55228 9.44772 8 10 8C10.5523 8 11 7.55228 11 7ZM10 4C11.6569 4 13 5.34315 13 7C13 8.65685 11.6569 10 10 10C8.34315 10 7 8.65685 7 7C7 5.34315 8.34315 4 10 4Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 17C16 16.4477 15.5523 16 15 16C14.4477 16 14 16.4477 14 17C14 17.5523 14.4477 18 15 18C15.5523 18 16 17.5523 16 17ZM15 14C16.6569 14 18 15.3431 18 17C18 18.6569 16.6569 20 15 20C13.3431 20 12 18.6569 12 17C12 15.3431 13.3431 14 15 14Z"
      fill={color}
    />
  </svg>
);

IconFilterAlt.defaultProps = defaultIconProps;
