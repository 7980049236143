import { defaultIconProps, IconProps } from './IconProps';

export const IconPin2: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M6.98065 13.0133L2.2666 17.7274M9.74539 5.53458L8.44465 6.83532C8.33854 6.94142 8.28549 6.99447 8.22505 7.03663C8.1714 7.07405 8.11353 7.10502 8.05264 7.1289C7.98404 7.15581 7.91047 7.17052 7.76333 7.19995L4.70961 7.8107C3.91602 7.96941 3.51923 8.04877 3.33359 8.25798C3.17187 8.44024 3.09802 8.68415 3.13148 8.92551C3.16989 9.20255 3.45602 9.48868 4.02829 10.0609L9.93307 15.9657C10.5053 16.538 10.7915 16.8241 11.0685 16.8625C11.3099 16.896 11.5538 16.8221 11.736 16.6604C11.9452 16.4748 12.0246 16.078 12.1833 15.2844L12.7941 12.2307C12.8235 12.0835 12.8382 12.01 12.8651 11.9414C12.889 11.8805 12.92 11.8226 12.9574 11.769C12.9995 11.7085 13.0526 11.6555 13.1587 11.5494L14.4594 10.2486C14.5273 10.1808 14.5612 10.1469 14.5985 10.1173C14.6316 10.0909 14.6667 10.0672 14.7034 10.0462C14.7447 10.0226 14.7888 10.0037 14.877 9.96591L16.9556 9.07507C17.5621 8.81517 17.8653 8.68523 18.003 8.47524C18.1234 8.29161 18.1665 8.06786 18.1229 7.85263C18.073 7.60651 17.8398 7.37325 17.3732 6.90673L13.0873 2.62078C12.6208 2.15426 12.3875 1.921 12.1414 1.87111C11.9262 1.82748 11.7024 1.87057 11.5188 1.99101C11.3088 2.12874 11.1788 2.43195 10.9189 3.03837L10.0281 5.117C9.99031 5.20518 9.97142 5.24927 9.9478 5.29061C9.92682 5.32734 9.90307 5.36242 9.87676 5.39554C9.84714 5.43282 9.81322 5.46674 9.74539 5.53458Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconPin2.defaultProps = defaultIconProps;
