import { defaultIconProps, IconProps } from './IconProps';

export const IconInputs: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.29289 9.29292L7.70711 10.7071L11 7.41424L11 14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14L13 7.41424L16.2929 10.7071L17.7071 9.29292L12 3.58582L6.29289 9.29292Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 16V17C6 17.5523 6.44772 18 7 18L17 18C17.5523 18 18 17.5523 18 17V16H20V17C20 18.6569 18.6569 20 17 20L7 20C5.34315 20 4 18.6569 4 17V16H6Z"
      fill={color}
    />
  </svg>
);

IconInputs.defaultProps = defaultIconProps;
