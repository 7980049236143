import { defaultIconProps, IconProps } from './IconProps';

export const IconJSON: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 17C19 18.6569 17.6569 20 16 20H14V18H16C16.5523 18 17 17.5523 17 17V14.5616C17 13.4946 17.5628 12.5337 18.4379 12C17.5628 11.4663 17 10.5054 17 9.43845V6H14V4H19V9.43845C19 9.89732 19.3123 10.2973 19.7575 10.4086L21.2724 10.7873C22.5348 11.1029 22.5348 12.8971 21.2724 13.2127L19.7575 13.5914C19.3123 13.7027 19 14.1027 19 14.5616V17Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 7C5 5.34315 6.34315 4 8 4H10V6H8C7.44772 6 7 6.44772 7 7V9.43845C7 10.5054 6.43716 11.4663 5.56212 12C6.43716 12.5337 7 13.4946 7 14.5616V18H10V20H5V14.5616C5 14.1027 4.6877 13.7027 4.24254 13.5914L2.72761 13.2127C1.46517 12.8971 1.46516 11.1029 2.72761 10.7873L4.24254 10.4086C4.6877 10.2973 5 9.89731 5 9.43845V7Z"
      fill={color}
    />
  </svg>
);

IconJSON.defaultProps = defaultIconProps;
