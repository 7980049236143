import { defaultIconProps, IconProps } from './IconProps';

export const IconWorld: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3955 7.36479C16.3957 6.96438 17.2277 6.40161 17.8381 5.74207L19.3059 7.10053C18.4689 8.00497 17.378 8.72544 16.1388 9.22153C14.8989 9.71791 13.5307 9.9819 12.1451 9.99913C10.7594 10.0164 9.38158 9.78652 8.12278 9.32212C6.86472 8.858 5.7462 8.16691 4.87314 7.28732L6.29262 5.87838C6.93534 6.52591 7.79532 7.06955 8.81502 7.44574C9.83399 7.82166 10.9678 8.01362 12.1202 7.99928C13.2726 7.98495 14.3961 7.76492 15.3955 7.36479Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3955 16.6352C16.3957 17.0356 17.2277 17.5984 17.8381 18.2579L19.3059 16.8995C18.4689 15.995 17.378 15.2746 16.1388 14.7785C14.8989 14.2821 13.5307 14.0181 12.1451 14.0009C10.7594 13.9836 9.38158 14.2135 8.12278 14.6779C6.86472 15.142 5.7462 15.8331 4.87314 16.7127L6.29262 18.1216C6.93534 17.4741 7.79532 16.9304 8.81502 16.5543C9.83399 16.1783 10.9678 15.9864 12.1202 16.0007C13.2726 16.015 14.3961 16.2351 15.3955 16.6352Z"
      fill={color}
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M11 20V4H13V20H11Z" fill={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M4 11H20V13H4V11Z" fill={color} />
  </svg>
);

IconWorld.defaultProps = defaultIconProps;
