import { defaultIconProps, IconProps } from './IconProps';

export const IconTwitterOutline: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M15.3333 2.00001C14.6949 2.45032 13.9881 2.79474 13.24 3.02001C12.8385 2.55835 12.3049 2.23113 11.7113 2.08262C11.1178 1.93411 10.493 1.97147 9.92138 2.18964C9.34977 2.40781 8.85895 2.79627 8.51531 3.30248C8.17167 3.80869 7.99179 4.40823 7.99999 5.02001V5.68667C6.82841 5.71705 5.66751 5.45721 4.62066 4.9303C3.57382 4.40339 2.67353 3.62576 1.99999 2.66667C1.99999 2.66667 -0.666677 8.66667 5.33332 11.3333C3.96034 12.2653 2.32476 12.7326 0.666656 12.6667C6.66666 16 14 12.6667 14 5C14 4.81334 13.98 4.62667 13.9467 4.44667C14.6271 3.77567 15.1072 2.92848 15.3333 2.00001V2.00001Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconTwitterOutline.defaultProps = defaultIconProps;
