import { defaultIconProps, IconProps } from './IconProps';

export const IconEmailOutline: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M2.66668 2.66666H13.3333C14.0667 2.66666 14.6667 3.26666 14.6667 3.99999V12C14.6667 12.7333 14.0667 13.3333 13.3333 13.3333H2.66668C1.93334 13.3333 1.33334 12.7333 1.33334 12V3.99999C1.33334 3.26666 1.93334 2.66666 2.66668 2.66666Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14.6667 4L8.00001 8.66667L1.33334 4" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

IconEmailOutline.defaultProps = defaultIconProps;
