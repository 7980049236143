import { defaultIconProps, IconProps } from './IconProps';

export const IconAudio: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <line x1="4.22359" y1="5.19814" x2="4.22359" y2="10.8018" stroke={color} strokeWidth="1.21818" strokeLinecap="round" />
    <line x1="6.41634" y1="6.90365" x2="6.41634" y2="9.09637" stroke={color} strokeWidth="1.21818" strokeLinecap="round" />
    <line x1="8.60909" y1="4.34548" x2="8.60909" y2="11.6546" stroke={color} strokeWidth="1.21818" strokeLinecap="round" />
    <line x1="10.8018" y1="6.05092" x2="10.8018" y2="9.94911" stroke={color} strokeWidth="1.21818" strokeLinecap="round" />
    <line x1="12.9945" y1="7.09304" x2="12.9945" y2="8.90678" stroke={color} strokeWidth="1.21818" strokeLinecap="round" />
  </svg>
);

IconAudio.defaultProps = defaultIconProps;
