import { defaultIconProps, IconProps } from './IconProps';

export const IconKeyFill: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M0 28C0 12.536 12.536 0 28 0C43.464 0 56 12.536 56 28C56 43.464 43.464 56 28 56C12.536 56 0 43.464 0 28Z" fill={color} />
    <path
      d="M32.0833 22.75L36.1666 18.6667M38.5 16.3334L36.1666 18.6667L38.5 16.3334ZM27.2883 27.545C27.8907 28.1394 28.3696 28.8471 28.6973 29.6273C29.0251 30.4075 29.1953 31.2448 29.1982 32.0911C29.201 32.9374 29.0364 33.7758 28.7139 34.5582C28.3913 35.3406 27.9172 36.0515 27.3188 36.6499C26.7204 37.2483 26.0095 37.7224 25.2271 38.0449C24.4448 38.3675 23.6063 38.5321 22.76 38.5292C21.9138 38.5264 21.0764 38.3562 20.2962 38.0284C19.516 37.7006 18.8083 37.2218 18.214 36.6194C17.0451 35.4092 16.3983 33.7883 16.413 32.1059C16.4276 30.4235 17.1024 28.8141 18.2921 27.6244C19.4818 26.4347 21.0912 25.7598 22.7736 25.7452C24.4561 25.7306 26.0769 26.3774 27.2871 27.5462L27.2883 27.545ZM27.2883 27.545L32.0833 22.75L27.2883 27.545ZM32.0833 22.75L35.5833 26.25L39.6666 22.1667L36.1666 18.6667L32.0833 22.75Z"
      stroke="#004EEB"
      strokeWidth="2.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconKeyFill.defaultProps = defaultIconProps;
