import { defaultIconProps, IconProps } from './IconProps';

export const IconDelete: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 16C9.44772 16 9 15.5523 9 15L9 12C9 11.4477 9.44771 11 10 11C10.5523 11 11 11.4477 11 12L11 15C11 15.5523 10.5523 16 10 16Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 16C13.4477 16 13 15.5523 13 15L13 12C13 11.4477 13.4477 11 14 11C14.5523 11 15 11.4477 15 12L15 15C15 15.5523 14.5523 16 14 16Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.02624 8.01671C3.78926 8.00054 3.47962 8 3 8V6C3.0107 6 3.02136 6 3.03198 6C3.05346 6 3.07478 6 3.09597 6H20.904C20.9252 6 20.9465 6 20.968 6L21 6V8C20.5204 8 20.2107 8.00054 19.9738 8.01671C19.7458 8.03227 19.6589 8.05888 19.6173 8.07612C19.3723 8.17762 19.1776 8.37229 19.0761 8.61732C19.0589 8.65893 19.0323 8.74576 19.0167 8.97376C19.0005 9.21074 19 9.52038 19 10L19 16.0658C19.0001 16.9523 19.0001 17.7161 18.9179 18.3278C18.8297 18.9833 18.631 19.6117 18.1213 20.1213C17.6117 20.631 16.9833 20.8297 16.3278 20.9179C15.7161 21.0001 14.9523 21.0001 14.0658 21H9.93417C9.04768 21.0001 8.28387 21.0001 7.67221 20.9179C7.01669 20.8297 6.38834 20.631 5.87868 20.1213C5.36902 19.6117 5.17027 18.9833 5.08214 18.3278C4.9999 17.7161 4.99995 16.9523 5 16.0658L5 10C5 9.52038 4.99946 9.21074 4.98329 8.97376C4.96773 8.74576 4.94112 8.65893 4.92388 8.61732C4.82239 8.37229 4.62771 8.17762 4.38268 8.07612C4.34107 8.05888 4.25424 8.03227 4.02624 8.01671ZM17.1728 8H6.8272C6.92099 8.27761 6.95955 8.55764 6.97865 8.83762C7.00002 9.15088 7.00001 9.52936 7 9.96801L7 16C7 16.9711 7.00212 17.5988 7.06431 18.0613C7.12262 18.495 7.21677 18.631 7.29289 18.7071C7.36902 18.7832 7.50496 18.8774 7.9387 18.9357C8.40121 18.9979 9.02892 19 10 19H14C14.9711 19 15.5988 18.9979 16.0613 18.9357C16.495 18.8774 16.631 18.7832 16.7071 18.7071C16.7832 18.631 16.8774 18.495 16.9357 18.0613C16.9979 17.5988 17 16.9711 17 16V9.96801C17 9.52936 17 9.15088 17.0213 8.83762C17.0405 8.55764 17.079 8.27761 17.1728 8Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4059 2.12123C12.982 2.0399 12.4892 2 12 2C11.5108 2 11.018 2.0399 10.5941 2.12123C10.3821 2.16189 10.1746 2.21529 9.98663 2.28562C9.81588 2.34951 9.58299 2.45558 9.38595 2.63942C8.98214 3.01618 8.96021 3.64897 9.33698 4.05278C9.6925 4.43384 10.276 4.47484 10.68 4.16168C10.6823 4.16078 10.6848 4.15982 10.6875 4.15881C10.7408 4.13884 10.8335 4.11176 10.9709 4.08542C11.2455 4.03274 11.6087 4 12 4C12.3913 4 12.7545 4.03274 13.0291 4.08542C13.1665 4.11176 13.2592 4.13884 13.3125 4.1588C13.3152 4.15982 13.3177 4.16078 13.32 4.16168C13.724 4.47484 14.3075 4.43384 14.663 4.05278C15.0398 3.64897 15.0179 3.01618 14.614 2.63942C14.417 2.45558 14.1841 2.34951 14.0134 2.28562C13.8254 2.21529 13.6179 2.16189 13.4059 2.12123Z"
      fill={color}
    />
  </svg>
);

IconDelete.defaultProps = defaultIconProps;
