import { defaultIconProps, IconProps } from './IconProps';

export const IconEdit04: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M17.5 15L16.6666 15.9117C16.2245 16.3951 15.6251 16.6666 15.0001 16.6666C14.3751 16.6666 13.7757 16.3951 13.3337 15.9117C12.891 15.4292 12.2916 15.1584 11.6668 15.1584C11.042 15.1584 10.4426 15.4292 9.99998 15.9117M2.5 16.6666H3.89545C4.3031 16.6666 4.50693 16.6666 4.69874 16.6206C4.8688 16.5798 5.03138 16.5124 5.1805 16.421C5.34869 16.318 5.49282 16.1738 5.78107 15.8856L16.25 5.41663C16.9404 4.72628 16.9404 3.60699 16.25 2.91663C15.5597 2.22628 14.4404 2.22628 13.75 2.91663L3.28105 13.3856C2.9928 13.6738 2.84867 13.818 2.7456 13.9862C2.65422 14.1353 2.58688 14.2979 2.54605 14.4679C2.5 14.6597 2.5 14.8636 2.5 15.2712V16.6666Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconEdit04.defaultProps = defaultIconProps;
